import React from 'react';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';

function TabMenu({ translate, ...props }) {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	function handleClick(event) {
		setAnchorEl(event.currentTarget);
	}

	function handleClose() {
		setAnchorEl(null);
	}

	return (
		<div className="generic-tabmenu-main">
			<IconButton
				id="ticket-plus-menu-button"
				aria-label="more"
				aria-controls="plus-menu"
				aria-haspopup="true"
				onClick={handleClick}
				className="generic-tabmenu-icon"
			>
				<AddIcon />
			</IconButton>
			<Menu id="plus-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
				{props.options.map((option, i) => {
					if (option.allowed_scopes && option.allowed_scopes.length > 0) {
						if (
							typeof props.tickets.ticket.scopes !== 'undefined' &&
							props.tickets.ticket.scopes.some((scope) => option.allowed_scopes.includes(scope))
						) {
							return (
								<MenuItem
									id={option.element_id}
									key={i}
									onClick={() => {
										props.menuAction(option.index);
										handleClose();
									}}
								>
									{translate('tab_menu_' + option.index)}
								</MenuItem>
							);
						} else {
							return '';
						}
					} else {
						return (
							<MenuItem
								{ ...option.element_id && { id: option.element_id }}
								key={i}
								onClick={() => {
									props.menuAction(option.index);
									handleClose();
								}}
							>
								{translate('tab_menu_' + option.index)}
							</MenuItem>
						);
					}
				})}
			</Menu>
		</div>
	);
}

const mapStateToProps = (state) => ({
	tickets: state.tickets,
	translate: getTranslate(state.localize),
});

export default connect(mapStateToProps)(TabMenu);
