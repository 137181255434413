export const LOGIN = 'LOGIN';
export const BANKID_PROGRESS = 'BANKID_PROGRESS';
export const LOGIN_BANKID = 'LOGIN_BANKID';
export const NETID_PROGRESS = 'NETID_PROGRESS';
export const LOGIN_NETID = 'LOGIN_NETID';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const ADBLOCK_WARNING = 'ADBLOCK_WARNING';
export const SELECT_CLIENT = 'SELECT_CLIENT';
export const BANKID_QRCODE = 'BANKID_QRCODE';
export const RESET_TOKEN = 'RESET_TOKEN';
export const CLEAR_REDUCER = 'CLEAR_REDUCER';
export const LOGIN_SELF_REGISTRATION = 'LOGIN_SELF_REGISTRATION';
export const LOGIN_CONFIRMED = 'LOGIN_CONFIRMED';