import React, { Component } from 'react';
import { Typography, Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

class PanelList extends Component {
    state = {
        expanded: false
    };

    handleChange = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false
        });
    };

    render() {
        return (
            <Accordion
                defaultExpanded={this.props.expandedList}
                onChange={this.handleChange(this.props.title)}
                className="generic-panellist-main"
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                    <Typography className="generic-panellist-title">{this.props.title}</Typography>
                    <Typography className="generic-panellist-subtitle">{this.props.subtitle}</Typography>
                    {this.props.warning ? <div className="generic-panellist-warning">{this.props.warning}</div> : null}
                </AccordionSummary>
                <AccordionDetails>{this.props.content}</AccordionDetails>
            </Accordion>
        );
    }
}

export default PanelList;
