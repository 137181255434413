import React, { Component } from 'react';
import { connect } from 'react-redux';


import Observer from "react-intersection-observer";

class ImageReader extends Component {

	render() {
		return (
			<Observer triggerOnce={true}>
				{({ inView, ref }) => (
					<div
						ref={ref}
						className="generic-image-display-wrapper"
						style={{
							"--height": this.props.imgHeight,
							"--width": this.props.imgWidth
						}}
					>
						<img
							src={inView ? this.props.config.apihost + this.props.path : ""}
							alt=""
							width={this.props.imgWidth}
							height={this.props.imgHeight}
							className="generic-image-display-img"
						/>
					</div>
				)}
			</Observer>

		);
	}
}

const mapStateToProps = (state) => ({
	generic: state.generic,
	config: state.config,
	token: state.login.token
});

export default connect(mapStateToProps)(ImageReader);
