import React, { Component } from 'react';
import { connect } from 'react-redux';

class ProgressiveImage extends Component {
    state = {
        currentImage: this.props.preview,
        loading: true,
    }

    componentDidMount() {
        this.fetchImage(this.props.image)
    }

    // TODO: check the need for this and change to the new react life cycle with getDerivedStateFromProps method.
    // componentWillReceiveProps(nextProps) {
    //     console.log(nextProps)
    //     if (nextProps.image !== this.props.image) {
    //         this.setState({ currentImage: nextProps.preview, loading: true }, () => {
    //             this.fetchImage(nextProps.image)
    //         })
    //     }
    // }

    componentWillUnmount() {
        if (this.loadingImage) {
            this.loadingImage.onload = null
        }
    }

    fetchImage = src => {
        // const image = new Image()
        // image.onload = () => this.setState({ currentImage: this.loadingImage.src, loading: false })
        // image.src = src
        // this.loadingImage = image

		const options = {
			headers: {
				Authorization: 'Bearer ' + this.props.token
			}
		};
		fetch(src, options)
            .then((res) => res.blob())
			.then((blob) => {
				var urlCreator = window.URL || window.webkitURL;
				var imageUrl = urlCreator.createObjectURL(blob);
				var img = document.querySelector('#image-reader-' + this.props.uuid);
				if(img) {
                    // img.src = imageUrl;
                    
                    img.onload = () => this.setState({ currentImage: this.loadingImage.src, loading: false })
                    img.src = imageUrl
                    this.loadingImage = img
				}
			});
    }

    style = loading => {
        return {
            transition: '0.5s filter linear',
            filter: `${loading ? 'blur(50px)' : ''}`,
        }
    }

    render() {
        const { currentImage, loading } = this.state
        const { alt } = this.props
        return (
            <div
                className="generic-image-display-wrapper"
                style={{
                    "--height": this.props.imgHeight,
                    "--width": this.props.imgWidth
                }}
            >
                <img
                    id={'image-reader-' + this.props.uuid}
                    // src={currentImage}
                    style={this.style(loading)}
                    alt={alt}
                    width={this.props.imgWidth}
                    height={this.props.imgHeight}
                    className="generic-image-display-img"
                />
                {/* <img
                    className="generic-image-display-img"
                    alt=""
                    width={this.props.imgWidth ? this.props.imgWidth + `%` : ''}
                    height={this.props.imgHeight ? this.props.imgHeight + `%` : ''}
                /> */}
            </div>

            
        )
    }
}

const mapStateToProps = (state) => ({
    generic: state.generic,
    config: state.config,
    token: state.login.token
});

export default connect(mapStateToProps)(ProgressiveImage);