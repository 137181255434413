// React core
import React, { Suspense } from 'react';
import { connect } from 'react-redux';

// react-router
import { Route, Switch, Redirect } from 'react-router-dom';

// Our "pages" main components
import NotFound from './components/404';
import Login from './components/Login/Login';
import CreateNewAccount from './components/Login/CreateNewAccount';
import Welcome from './components/Welcome/Welcome';
import { FullsizeLoading } from './components/Generic';
import * as MyPages from "./components/Users/MyPages";
import { useStyles } from './assets/Theme';
import MeetingLogin from './components/Meetings/MeetingLogin';
import {
	DefaultLayout, 
    MyCases, 
    SelectCondition, 
    TicketLayout, 
    WaitingRoom,
    AllCases,
    History,
    Dashboard,
    Invite,
    WardSelectionForm,
    WardPatientForm,
    PatientForm,
    FollowupQuestionnaires,
    FailedTriage,
    ThankYou,
    SummaryPayment,
    SummaryCondition,
    Questionnaires,
    Activity,
    ActivityForm,
    EventMeeting,
    Activities,
    Library,
    LibraryManagement,
    LibraryFileForm,
    QlikSenseApp,
    EventForm,
    Meeting,
} from './RouteComponents';

function Routes(props) {
	useStyles(props);
	
	return (
		<Suspense fallback={<FullsizeLoading />}>
			<Switch>
				<Route exact path="/login/:client" render={(props) => <Login {...props} platform={'frontoffice'} />} />
				<Route exact path="/login" render={(props) => <Login {...props} platform={'frontoffice'} />} />
				{props.settings.feature.self_registration ? (
					<Route exact path="/register" render={() => <CreateNewAccount />} />
				) : null}
				<Route exact path="/backoffice" render={(props) => <Login {...props} platform={'backoffice'} />} />
				{!props.is_temporary_user && typeof props.settings.feature !== 'undefined' && props.settings.feature.dashboard ? (
					<DefaultLayout exact path="/dashboard" fullsizeContainer={true} disableMargins={false} component={Dashboard} />
				) : null}
				{!props.is_temporary_user && <Route exact path="/mycases" render={() => <DefaultLayout exact path="/mycases" component={MyCases} />} />}
				{!props.is_temporary_user && <Route exact path="/waitingroom" render={() => <DefaultLayout exact path="/waitingroom" component={WaitingRoom} />} />}
				{!props.is_temporary_user && <Route exact path="/allcases" render={() => <DefaultLayout exact path="/allcases" component={AllCases} />} />}
				{!props.is_temporary_user && <Route exact path="/case" render={() => <DefaultLayout exact path="/case" component={TicketLayout} />} />}
				{!props.is_temporary_user && <Route exact path="/history" render={() => <DefaultLayout exact path="/history" component={History} />} />}
				{!props.is_temporary_user && <Route exact path="/conditions" render={() => <DefaultLayout exact path="/conditions" component={SelectCondition} />} />}
				{!props.is_temporary_user && <Route exact path="/conditions/:id" render={() => <DefaultLayout exact path="/conditions/:id" component={SummaryCondition} />} />}
				{!props.is_temporary_user && <Route exact path="/case/new/:ticket_id/questionnaires/:questionnaire_id" render={() => <DefaultLayout exact path="/case/new/:ticket_id/questionnaires/:questionnaire_id" component={Questionnaires} />} />}
				{!props.is_temporary_user && <Route exact path="/followup/tickets/:ticket_id/questionnaire/:questionnaire_id" render={() => <DefaultLayout exact path="/followup/tickets/:ticket_id/questionnaire/:questionnaire_id" component={FollowupQuestionnaires} />} />}
				{!props.is_temporary_user && <Route exact path="/case/new/:ticket_id/payment" render={() => <DefaultLayout exact path="/case/new/:ticket_id/payment" component={SummaryPayment} />} />}
				{!props.is_temporary_user && <Route exact path="/case/new/:ticket_id/summary" render={() => <DefaultLayout exact path="/case/new/:ticket_id/summary" component={ThankYou} />} />}
				{!props.is_temporary_user && <Route exact path="/case/new/triage/failed" render={() => <DefaultLayout exact path="/case/new/triage/failed" component={FailedTriage} />} />}
				{!props.is_temporary_user && <Route exact path="/case/new/patient/data" render={() => <DefaultLayout exact path="/case/new/patient/data" component={PatientForm} />} />}
				{!props.is_temporary_user && <Route exact path="/case/new/ward/ssn" render={() => <DefaultLayout exact path="/case/new/ward/ssn" component={WardSelectionForm} />} />}
				{!props.is_temporary_user && <Route exact path="/case/new/ward/data" render={() => <DefaultLayout exact path="/case/new/ward/data" component={WardPatientForm} />} />}
				{!props.is_temporary_user && <Route exact path="/mypages" render={() => <DefaultLayout exact path="/mypages" component={MyPages.MyPages} />} />}
				{!props.is_temporary_user && <Route exact path="/myprofile" render={() => <DefaultLayout exact path="/myprofile" component={MyPages.MyProfile} />} />}
				{!props.is_temporary_user && <Route exact path="/guidelines" render={() => <DefaultLayout exact path="/guidelines" component={MyPages.Guidelines} />} />}
				{!props.is_temporary_user && <Route exact path="/password" render={() => <DefaultLayout exact path="/password" component={MyPages.Password} />} />}
				{!props.is_temporary_user && <Route exact path="/mytexts" render={() => <DefaultLayout exact path="/mytexts" component={MyPages.MyTexts} />} />}
				{!props.is_temporary_user && <Route exact path="/appointments" render={() => <DefaultLayout exact path="/appointments" component={MyPages.Appointments} />} />}
				{!props.is_temporary_user && <Route exact path="/filesforpatients" render={() => <DefaultLayout exact path="/filesforpatients" component={MyPages.FilesForPatients} />} />}
				{!props.is_temporary_user && <Route exact path="/privacypolicy" render={() => <DefaultLayout exact path="/privacypolicy" component={MyPages.PrivacyPolicy} />} />}
				{!props.is_temporary_user && props.scopes.includes('calendar') && <Route exact path="/mycalendar" render={() => <DefaultLayout fullsizeContainer={true} exact path="/mycalendar" component={MyPages.MyCalendar} />} />}
				{!props.is_temporary_user && props.scopes.includes('practitioner') && <Route exact path="/mydashboard" render={() => <DefaultLayout exact path="/mydashboard" fullsizeContainer={true} disableMargins={false} component={MyPages.MyDashboard} />} />}
				{!props.is_temporary_user && <Route exact path="/invite" render={() => <DefaultLayout exact path="/invite" component={Invite} />} />}
				{!props.is_temporary_user && props.settings.feature.activities && <Route exact path="/activities" render={() => <DefaultLayout exact path="/activities" component={Activities} />} />}
				{!props.is_temporary_user && props.settings.feature.qliksense && <Route exact path="/qliksense" render={() => <DefaultLayout fullsizeContainer={true} disableMargins={true} disableGutters={true} exact path="/qliksense" component={QlikSenseApp} />} />}
				{!props.is_temporary_user && props.settings.feature.activities && props.scopes.includes('practitioner') && <Route exact path="/activity/new" render={() => <DefaultLayout exact path="/activity/new" component={ActivityForm} />} />}
				{!props.is_temporary_user && props.settings.feature.activities && props.scopes.includes('practitioner') && <Route exact path="/activity/:id/edit" render={() => <DefaultLayout exact path="/activity/:id/edit" component={ActivityForm} />} />}
				{!props.is_temporary_user && props.settings.feature.activities && <Route exact path="/activity/:id/event/:event_id" render={() => <DefaultLayout backgroundColorClass={'default-layout-background-color-grey-900'} fullsizeContainer={true} disableMargins={true} disableGutters={true} exact path="/activity/:id/event/:event_id" component={EventMeeting} />} />}
				{!props.is_temporary_user && props.settings.feature.activities && <Route exact path="/activity/:id" render={() => <DefaultLayout exact path="/activity" component={Activity} />} />}
				{!props.is_temporary_user && props.settings.feature.libraries && props.scopes.includes('library') && <Route exact path="/library/edit/file" render={() => <DefaultLayout exact path="/library/edit/file" component={LibraryFileForm} />} />}
				{!props.is_temporary_user && props.settings.feature.libraries && props.scopes.includes('library') && props.scopes.includes('libraries.edit') && <Route exact path="/library/edit" render={() => <DefaultLayout exact path="/library/edit" component={LibraryManagement} />} />}
				{!props.is_temporary_user && props.settings.feature.libraries && props.scopes.includes('library') && <Route exact path="/library" render={() => <DefaultLayout exact path="/library" component={Library} />} />}
				{!props.is_temporary_user && props.scopes.includes('calendar') && <Route exact path="/event/:event_id" render={() => <DefaultLayout exact path="/event/:event_id" component={EventForm} />} />}
				{!props.is_temporary_user && props.scopes.includes('calendar') && <Route exact path="/event" render={() => <DefaultLayout exact path="/event" component={EventForm} />} />}
				{props.login && props.settings.feature.meetings && <Route exact path="/meetings/:token" render={() => <DefaultLayout exact path="/meetings/:token" backgroundColorClass={'default-layout-background-color-grey-900'} fullsizeContainer={true} disableMargins={true} disableGutters={true} component={Meeting} />} />}
				{props.login && props.settings.feature.meetings && <Route exact path="/meetings/" render={() => <DefaultLayout exact path="/meetings/" backgroundColorClass={'default-layout-background-color-grey-900'} fullsizeContainer={true} disableMargins={true} disableGutters={true} component={Meeting} />} />}
				{!props.login && props.settings.feature.meetings && <Route exact path="/meetings/:token" render={() => <MeetingLogin />} />}
				{!props.login && props.settings.feature.meetings && <Route exact path="/meetings/" render={() => <MeetingLogin />} />}
				<Route exact path="/" component={Welcome} />
				<Route exact path="/404" component={NotFound} />
				<Redirect to="/404" />
			</Switch>
		</Suspense>
	);
}

const mapStateToProps = (state) => ({
	settings: state.settings,
	scopes: state.users.whoami.scopes ? state.users.whoami.scopes : [],
	login: state.login.login,
	is_temporary_user: state.users.whoami.status == 5 || state.users.whoami.status == 6
});

export default connect(mapStateToProps, null)(Routes);
