import React from 'react';
import { TextField, FormHelperText } from '@material-ui/core';

function RenderTextField({ input, meta: { invalid, touched, error }, ...custom }) {
	return (
		<div>
			<TextField
				{...input}
				id={custom.inputdata.id}
				name={custom.inputdata.name}
				label={custom.inputdata.label}
				placeholder={custom.inputdata.placeholder}
				fullWidth
				variant="outlined"
				disabled={custom.inputdata.readonly}
				error={custom.inputdata.validate ? touched && invalid : false}
				helperText={custom.inputdata.validate ? touched && error : false}
				onChange={(event) => {
					input.onChange(event);
					if (typeof custom.inputdata.onChange !== 'undefined') {
						custom.inputdata.onChange(event.target.value);
					}
				}}
				type={custom.inputdata.type}
				size={custom.inputdata.size ? custom.inputdata.size : null}
				{...custom}
				inputProps={{
					step: custom.inputdata.props ? custom.inputdata.props.step : null,
					min: custom.inputdata.props ? custom.inputdata.props.min : null,
					className: custom.inputdata.disableNumberArrows ? 'number-input' : null,
				}}
			/>
			<FormHelperText id="my-helper-text"> {custom.inputdata.helperText}</FormHelperText>
		</div>
		// required={(custom.inputdata.validate && custom.inputdata.validate.required) ? true : false}
	);
}

export default RenderTextField;
