import { fade } from '@material-ui/core/styles/colorManipulator';
import { isMobileSize } from '../../helpers/functions/functions';

const tickets = (theme) => {
	return {
		'.tickets-layout-header-bar': {
			display: 'flex',
			justifyContent: 'flex-end',
			alignItems: 'center',
		},
		'.tickets-layout-header-patient-name': {
			display: 'flex',
			alignItems: 'center',
		},
		'.tickets-layout-header-bar-info-case': {
			float: 'right',
			[theme.breakpoints.down('md')]: {
				float: 'unset',
			},
		},
		'.tickets-layout-container-button': {
			textAlign: 'center',
		},
		'.tickets-new-payment-button-bar': {
			display: 'flex',
			justifyContent: 'center',
		},
		'.tickets-new-recursivelist-main': {
			width: '100%',
			maxWidth: 360,
			backgroundColor: theme.palette.background.paper,
			paddingLeft: '0px !important',
		},

		'.tickets-new-recursivelist-item': {
			marginBottom: theme.spacing(1),
		},
		'.tickets-new-recursivelist-listitem': {
			'&:hover': {
				color: theme.palette.primary.main,
				border: '1px solid ' + theme.palette.primary.main,
				borderRadius: '4px',
			},
			[theme.breakpoints.down('md')]: {
				boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
				'&:hover': {
					color: theme.palette.primary.main,
					border: 'unset',
					borderRadius: 'unset',
					backgroundColor: 'rgba(0, 0, 0, 0.04)',
				},
			},
		},
		'.tickets-new-recursivelist-listitem.Mui-selected': {
			color: theme.palette.primary.main,
			border: '1px solid ' + theme.palette.primary.main,
			borderRadius: '4px',
			'&:hover': {
				color: theme.palette.primary.main,
				border: '1px solid ' + theme.palette.primary.main,
				borderRadius: '4px',
			},
			[theme.breakpoints.down('md')]: {
				boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
				color: theme.palette.primary.main,
				border: 'unset',
				borderRadius: 'unset',
				backgroundColor: 'rgba(0, 0, 0, 0.04)',
				'&:hover': {
					color: theme.palette.primary.main,
					border: 'unset',
					borderRadius: 'unset',
					backgroundColor: 'rgba(0, 0, 0, 0.04)',
				},
			},
		},

		'.tickets-new-selectcondition-main': {
			marginTop: theme.spacing(2),
		},
		'.tickets-new-selectcondition-button-bar': {
			display: 'flex',
			justifyContent: 'center',
		},
		'.tickets-new-selectcondition-main-container': {
			[theme.breakpoints.up('md')]: {
				paddingRight: '90px',
			},
		},
		'.tickets-new-selectcondition-container': {
			textAlign: 'center',
		},
		'.tickets-new-selectcondition-label': {
			marginRight: theme.spacing(2),
		},
		'.tickets-new-selectcondition-text': {
			marginTop: theme.spacing(2),
		},
		'.tickets-new-selectcondition-category-description': {
			position: 'relative',
			'&:after': {
				content: '""',
				position: 'absolute',
				zIndex: '1',
				bottom: '0',
				left: '0',
				pointerEvents: 'none',
				backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255, 0), rgba(255,255,255, 1) 90%)',
				width: '100%',
				height: '1em',
			},
		},
		'.tickets-new-selectcondition-category-description-button': {
			float: 'right',
			marginBottom: theme.spacing(2),
			textDecoration: 'underline',
		},
		'.tickets-new-selectcondition-cardactionarea': {
			display: 'flex',
		},
		'.tickets-new-selectcondition-card': {
			margin: theme.spacing(2),
			textAlign: 'center',
			color: theme.palette.text.secondary,
			display: 'flex',
			padding: '5px',
		},
		'.tickets-new-selectcondition-cardimage': {
			width: '25%',
			padding: '2px 0 0 2px',
		},
		'.tickets-new-selectcondition-cardimage-no-image': {
			backgroundColor: theme.palette.grey[100],
			width: '25%',
			height: '17vh',
			[theme.breakpoints.down('md')]: {
				height: '100%',
			},
		},
		'.tickets-new-selectcondition-cardimage > img': {
			borderRadius: '4px',
		},
		'.tickets-new-category-image': {
			height: '25vh',
			width: '25vh',
			margin: '16px',
			display: 'flex',
		},
		'.tickets-new-category-image > img': {
			height: 'inherit',
			// width: 'inherit'
		},

		'.tickets-new-category-no-image': {
			backgroundColor: theme.palette.grey[100],
			width: '100%',
			height: '100%',
		},
		'.tickets-new-selectcondition-carddetails': {
			display: 'flex',
			flexDirection: 'column',
			width: '75%',
		},
		'.tickets-new-selectcondition-cardcontent': {
			flex: '1 0 auto',
		},
		'.tickets-new-selectcondition-cardcontent-description': {
			textAlign: 'justify',
		},
		'.tickets-new-selectcondition-cardcontent-description .tui-editor-contents > *': {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			display: '-webkit-box',
			WebkitLineClamp: isMobileSize() ? '2' : '4',
			WebkitBoxOrient: 'vertical',
		},
		'.MuiCardContent-root:last-child': {
			paddingBottom: theme.spacing(2),
		},
		'.tickets-new-selectcondition-message': {
			backgroundColor: 'rgba(0, 0, 0, 0.04)',
			padding: theme.spacing(3),
			borderRadius: theme.spacing(1),
			[theme.breakpoints.up('md')]: {
				marginLeft: theme.spacing(6),
			},
		},
		'.tickets-overview-content-list': {
			width: '100%',
		},

		'.tickets-overview-header-button-bar': {
			[theme.breakpoints.down('sm')]: {
				paddingLeft: theme.spacing(1),
			},
		},
		'.tickets-overview-header-button': {
			margin: theme.spacing(1, 0),
		},
		'.tickets-overview-header-list-content': {
			padding: theme.spacing(2, 2),
			backgroundColor: theme.palette.grey[100],
		},
		'.tickets-overview-questionnaires-main': {
			padding: theme.spacing(2, 2),
		},
		'.tickets-overview-questionnaires-button-close': {
			float: 'right',
			right: '0px',
			top: theme.spacing(1),
		},
		'.tickets-followup-questionnaires-button-close': {
			float: 'right',
		},
		'.tickets-overview-questionnaires-switch-mode': {
			paddingTop: theme.spacing(2),
		},
		'.tickets-overview-questionnaires-groups': {
			paddingBottom: theme.spacing(2),
		},
		'.tickets-plusmenu-certificates-main': {
			marginTop: theme.spacing(2),
			flexDirection: 'column',
			alignItems: 'center',
		},
		'.tickets-plusmenu-certificates-button': {
			margin: theme.spacing(2, 0),
			justifyContent: 'space-between',
		},
		'.tickets-plusmenu-certificates-button-back': {
			margin: theme.spacing(2, 0),
		},
		'.tickets-plusmenu-certificates-button-close': {
			float: 'right',
		},
		'.tickets-plusmenu-diagnoses-content': {
			marginTop: theme.spacing(2),
			flexDirection: 'column',
			alignItems: 'center',
		},
		'.tickets-plusmenu-transferlist-main': {
			margin: 'auto',
		},
		'.tickets-plusmenu-transferlist-list': {
			height: 230,
			overflow: 'auto',
		},
		'.tickets-plusmenu-transferlist-button-bar': {
			display: 'flex',
			justifyContent: 'center',
		},
		'.tickets-plusmenu-transferlist-button': {
			margin: theme.spacing(0.5, 0),
		},
		'.tickets-plusmenu-transferlist-button-all-down': {
			transform: 'rotate(90deg)',
		},
		'.tickets-plusmenu-transferlist-button-all-up': {
			transform: 'rotate(-90deg)',
		},

		'.tickets-chattab-container': {
			// backgroundColor: theme.palette.grey[100],
			padding: theme.spacing(1),
			height: '100%'
		},
		'.tickets-videotab-container': {
			height: '100vh',
		},
		'.tickets-chipstatus-refused': {
			backgroundColor: 'white',
		},
		'.tickets-chipstatus-failed': {
			backgroundColor: 'white',
		},
		'.tickets-chipstatus-created': {
			backgroundColor: 'white',
		},
		'.tickets-chipstatus-processed': {
			backgroundColor: 'white',
		},
		'.tickets-chipstatus-pending': {
			backgroundColor: '#FE9526',
		},
		'.tickets-chipstatus-invite': {
			color: 'white',
			backgroundColor: '#f44336',
		},
		'.tickets-chipstatus-followup': {
			color: 'white',
			backgroundColor: 'blue',
			opacity: '50%',
		},
		'.tickets-chipstatus-payed': {
			backgroundColor: 'white',
		},
		'.tickets-chipstatus-rejected': {
			color: 'white',
			backgroundColor: '#8C0182',
		},
		'.tickets-chipstatus-waiting': {
			backgroundColor: '#F4D58D',
		},
		'.tickets-chipstatus-active': {
			color: 'white',
			backgroundColor: '#00A99D',
		},
		'.tickets-chipstatus-closed': {
			color: 'white',
			backgroundColor: '#6E7A8C',
		},
		'.tickets-overview-questionnaire-warning': {
			padding: '0px',
			backgroundColor: 'unset',
		},
		'.ticket-followup-alert': {
			marginRight: theme.spacing(2),
		},
		'.ticket-guardian-alert': {
			backgroundColor: fade(theme.palette.secondary.main, 0.55),
			color: 'white',
			[theme.breakpoints.up('md')]: {
				maxWidth: '25%',
			},
		},
		'.ticket-guardian-alert .MuiAlert-icon': {
			color: theme.palette.secondary.main,
		},
		'.tickets-layout-header-divider': {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
		'.ticket-overview-layout-top-margin': {
			marginTop: theme.spacing(2),
		},
		'.ticket-overview-assign-case-text-field': {
			width: '70%',
		},
		'.ticket-history-expanded-row': {
			backgroundColor: '#e6e6e6',
		},
		'.summary-button': {
			margin: theme.spacing(2, 0, 2),
		},
		'.ticket-careteam-collapse-panel': {
			backgroundColor: '#e6e6e6',
		},
		'.ticket-careteam-secondary-text': {
			color: 'rgba(0, 0, 0, 0.54)',
		},
		'.ticket-careteam-remove-button-container': {
			right: '50px',
		},
		'.ticket-summary-button-show': {
			fontSize: 'smaller',
		},
		'.tickets-overview-share-self-care-button-back': {
			marginTop: theme.spacing(2),
			marginBottom: theme.spacing(2),
		},
		'.tickets-table-style-row': {
			'&:nth-of-type(odd)': {
				backgroundColor: theme.palette.action.hover,
			},
		},
		'.tickets-summary-table-style': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.common.white,
		},
		'.tickets-questionnaries-list-accordion-summary': {
			display: 'block',
			flexGrow: '1',
		},
		'.tickets-questionnaries-icons-pdf-style': {
			marginLeft: theme.spacing(1),
		},
	};
};

export default tickets;
