import axios from 'axios';
import { getTranslate } from 'react-localize-redux';
import { WHOAMI, MENU_ACTIVE_ACTION } from './types';
import { store } from '../../../store.js';
import eventsService from '../../../helpers/Events';
import { getActiveLanguage } from 'react-localize-redux';
import { logoutAction } from '../../Login/actions/loginActions';
import { convertToFormData, addDelayAction } from '../../Generic/actions/genericActions';
import { fetchTranslationsAction } from '../../../actions/translationsActions';
import { dateToString } from '../../../helpers/functions/functions';
import { setPromptDialogIsDirty } from '../../../components/Generic/actions/promptActions';
import { push } from 'connected-react-router';
import { RESET_TOKEN } from '../../Login/actions/types';
import { reset } from 'redux-form';

export const whoamiAction =
	(login = false) =>
	(dispatch) => {
		const state = store.getState();

		axios
			.get(state.config.apihost + `/auth/whoami`, {
				headers: { Authorization: 'Bearer ' + state.login.token },
			})
			.then((res) => {
				dispatch({
					type: WHOAMI,
					payload: res.data,
				});

				if (login || res.data.language !== getActiveLanguage(state.localize).code) {
					dispatch(fetchTranslationsAction(res.data.language));
				}

				if (login) {
					dispatch(menuActiveAction('', true, res.data.ticket_count));
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

export const patchCurrentUserAction =
	(form, loading = false) =>
	(dispatch) => {
		const state = store.getState();
		const translate = getTranslate(state.localize);

		if (typeof state.form[form].values.profile_photo !== 'undefined' && state.form[form].values.profile_photo !== null) {
			state.form[form].values.profile_photo = state.form[form].values.profile_photo.length > 0 ? state.form[form].values.profile_photo : null;
		}

		if (typeof state.form[form].values.signature_img !== 'undefined' && state.form[form].values.signature_img !== null) {
			state.form[form].values.signature_img = state.form[form].values.signature_img.length > 0 ? state.form[form].values.signature_img : null;
		}

		if (typeof state.form[form].values.birthdate !== 'undefined') {
			state.form[form].values.birthdate = dateToString(
				new Date(
					state.form[form].values.birthdate.toLocaleString('en-US', {
						year: 'numeric',
						month: 'numeric',
						day: 'numeric',
						hour: 'numeric',
						minute: 'numeric',
						hour12: true,
					})
				)
			);
		}

		state.form[form].values.consented = state.form[form].values.consented ? '1' : '0';

		let data = convertToFormData(state.form[form].values);

		axios
			.patch(state.config.apihost + `/auth/users/` + state.users.whoami.id, data, {
				headers: { Authorization: 'Bearer ' + state.login.token },
			})
			.then((res) => {
				dispatch(whoamiAction());

				if (state.prompt.is_dirty && res.data.status !== '10') {
					dispatch(setPromptDialogIsDirty({ is_dirty: false }));
				}

				let redirect = '';
				let activeMenu = '';

				if (state.settings.feature.dashboard && state.settings.params == null) {
					redirect = '/dashboard';
					activeMenu = 'dashboard';
				} else if (res.data.scopes.includes('lists.all_cases')) {
					redirect = '/allcases';
					activeMenu = 'allcases';
				} else if (res.data.scopes.includes('practitioner')) {
					redirect = '/mycases';
					activeMenu = 'mycases';
				} else if (res.data.scopes.includes('customer')) {
					if (state.settings.feature.dashboard && state.settings.params == null) {
						redirect = '/dashboard';
						activeMenu = 'dashboard';
					} else if (
						(state.settings.params !== null && typeof state.settings.params.conditions !== 'undefined') ||
						res.data.ticket_count === 0
					) {
						redirect = '/conditions';
						activeMenu = 'conditions';
					} else {
						redirect = '/mycases';
						activeMenu = 'mycases';
					}
				} else {
					redirect = '/mycases';
					activeMenu = 'mycases';
				}

				dispatch(
					addDelayAction([menuActiveAction(activeMenu), push(redirect)], () =>
						eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_patch_current_user') })
					)
				);
			})
			.catch((err) => {
				console.log(err);
				console.log('Error fetching data.');
				dispatch(addDelayAction(null, () => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
			});
	};

export const changeActiveAction = (value) => (dispatch) => {
	const state = store.getState();
	let data = convertToFormData({
		onduty: value ? 1 : 0,
	});

	axios
		.patch(state.config.apihost + `/auth/users/` + state.users.whoami.id, data, {
			headers: { Authorization: 'Bearer ' + state.login.token },
		})
		.then((res) => {
			dispatch({
				type: WHOAMI,
				payload: res.data,
			});

			dispatch(whoamiAction());
		})
		.catch((err) => {
			console.log(err);
			console.log('Error fetching data.');
		});
};

export const cleanWhoamiAction = () => (dispatch) => {
	dispatch({
		type: WHOAMI,
		payload: '',
	});
};

export const menuActiveAction =
	(menu, login = false, ticket_count = null) =>
	(dispatch) => {
		const state = store.getState();
		let menuActive = menu;

		if (login) {
			if (state.users.whoami.status === '10') {
				menuActive = 'mypages';
			} else {
				if (state.login.self_registration == true) {
					menuActive = 'conditions';
				} else if (state.settings.feature.dashboard && state.settings.params == null) {
					menuActive = 'dashboard';
				} else if (state.users.whoami.scopes.includes('lists.all_cases')) {
					menuActive = 'allcases';
				} else if (state.users.whoami.scopes.includes('practitioner')) {
					menuActive = 'mycases';
				} else if (state.users.whoami.scopes.includes('customer')) {
					if (state.settings.feature.dashboard && state.settings.params === null) {
						menuActive = 'dashboard';
					} else if (
						(state.settings.params !== null && typeof state.settings.params.conditions !== 'undefined') ||
						state.users.whoami.ticket_count === 0
					) {
						menuActive = 'conditions';
					} else {
						menuActive = 'mycases';
					}
				} else {
					menuActive = 'mycases';
				}
			}
		}
		dispatch({
			type: MENU_ACTIVE_ACTION,
			payload: {
				menu: menuActive,
			},
		});
	};

export const searchSsnAction = (form, callback) => (dispatch) => {
	const state = store.getState();

	let ssn = state.form[form].values.ssn;
	axios
		.get(state.config.apihost + `/users`, {
			params: { search: ssn },
			headers: { Authorization: 'Bearer ' + state.login.token },
		})
		.then((res) => {
			callback(res.data);
		})
		.catch((error) => {
			console.log(error);
		});
};

export const saveLayoutAction = (layout) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	axios
		.patch(
			state.config.apihost + `/users/` + state.users.whoami.id,
			{ settings: JSON.stringify(layout) },
			{
				headers: { Authorization: 'Bearer ' + state.login.token },
			}
		)
		.then((res) => {
			dispatch({
				type: WHOAMI,
				payload: res.data,
			});

			dispatch(whoamiAction());

			dispatch(
				addDelayAction(push('/dashboard'), () =>
					eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_update_dashboard_builder') })
				)
			);
		})
		.catch((err) => {
			console.log(err);
			console.log('Error fetching data.');

			dispatch(addDelayAction(null, () => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

export const sendRequestCodeAction = (form, handleRequestCode) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	var body = new FormData();
	body.append('medium', state.form[form].values.medium_type);
	body.append('value', state.form[form].values.medium_value);

	axios
		.post(state.config.apihost + `/reset`, body)
		.then(() => {
			dispatch(
				addDelayAction(null, [
					() =>
						eventsService.triggerEvent(state.login.login ? 'alert' : 'alert_login', {
							type: 'success',
							message: translate('alert_success_request_code'),
						}),
					handleRequestCode,
				])
			);
		})
		.catch((err) => {
			console.log(err);
			console.log('Error fetching data.');

			dispatch(
				addDelayAction(null, () =>
					eventsService.triggerEvent(state.login.login ? 'alert' : 'alert_login', { type: 'error', message: err.message })
				)
			);
		});
};

export const sendValidateCodeAction = (form, handleValidateCode) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	var body = new FormData();
	body.append('token', state.form[form].values.token);

	axios
		.post(state.config.apihost + `/reset/verify`, body)
		.then(() => {
			dispatch({
				type: RESET_TOKEN,
				payload: state.form[form].values.token,
			});

			dispatch(
				addDelayAction(
					[reset(form), reset('request_code')],
					[
						() =>
							eventsService.triggerEvent(state.login.login ? 'alert' : 'alert_login', {
								type: 'success',
								message: translate('alert_success_validate_code'),
							}),
						handleValidateCode,
					]
				)
			);
		})
		.catch((err) => {
			console.log(err);
			console.log('Error fetching data.');

			dispatch(
				addDelayAction(null, () =>
					eventsService.triggerEvent(state.login.login ? 'alert' : 'alert_login', { type: 'error', message: err.message })
				)
			);
		});
};

export const sendNewPasswordAction = (form, handleRecovery) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	var body = new FormData();
	body.append('password', state.form[form].values.password);
	body.append('password_verify', state.form[form].values.password_verify);
	body.append('token', state.login.reset_token);

	axios
		.post(state.config.apihost + `/reset/password`, body)
		.then((res) => {
			dispatch(
				addDelayAction(reset(form), () =>
					eventsService.triggerEvent(state.login.login ? 'alert' : 'alert_login', {
						type: 'success',
						message: translate('alert_success_new_password_created'),
					})
				)
			);
			if (state.login.login) {
				dispatch(logoutAction(true));
			} else {
				handleRecovery();
			}
		})
		.catch((err) => {
			console.log(err);
			console.log('Error fetching data.');

			dispatch(
				addDelayAction(null, () =>
					eventsService.triggerEvent(state.login.login ? 'alert' : 'alert_login', { type: 'error', message: err.message })
				)
			);
		});
};

export const resendTokenAction = (form) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	var body = new FormData();
	body.append('medium', 'email');
	body.append('value', state.form[form].values.email);
	

	axios
		.post(state.config.apihost + `/users/contact/resend`, body)
		.then((res) => {
			dispatch(
				addDelayAction( () =>
					eventsService.triggerEvent(state.login.login ? 'alert' : 'alert_login', {
						type: 'success',
						message: translate('alert_success_code_resend'),
					})
				)
			);
		})
		.catch((err) => {
			console.log(err);
			console.log('Error fetching data.');

			dispatch(
				addDelayAction(null, () =>
					eventsService.triggerEvent(state.login.login ? 'alert' : 'alert_login', { type: 'error', message: translate(err.response.data.message) })
				)
			);
		});
};