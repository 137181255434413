import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { closeModalAction } from './actions/genericActions';

class Modal extends Component {
	render() {
		return (
			<Dialog
				open={this.props.modal.open}
				onClose={() => this.props.closeModalAction()}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{this.props.modal.title}</DialogTitle>
				{this.props.modal.description && (
					<DialogContent>
						<DialogContentText id="alert-dialog-description">{this.props.modal.description}</DialogContentText>
					</DialogContent>
				)}
				<DialogActions>
					<Button
						{...(this.props.modal.button_no_id && { id: this.props.modal.button_no_id })}
						onClick={() => {
							this.props.modal.action_no_button && this.props.modal.action_no_button(this.props.modal.props_no_button);
							this.props.closeModalAction();
						}}
						color="primary"
					>
						{this.props.modal.button_no}
					</Button>
					<Button
						{...(this.props.modal.button_yes_id && { id: this.props.modal.button_yes_id })}
						onClick={() => {
							this.props.modal.action(this.props.modal.props);
							this.props.closeModalAction();
						}}
						color="secondary"
						autoFocus
					>
						{this.props.modal.button_yes}
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

const mapStateToProps = (state) => ({
	modal: state.generic.modal,
});

export default connect(mapStateToProps, { closeModalAction })(Modal);
