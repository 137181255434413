import { combineReducers } from 'redux';
import configReducer from './configReducer';
import usersReducer from '../components/Users/reducers/usersReducer';
import loginReducer from '../components/Login/reducers/loginReducer';
import publisherReducer from '../components/VideoConference/reducers/publisherReducer';
import subscriberReducer from '../components/VideoConference/reducers/subscriberReducer';
import ticketsReducer from '../components/Tickets/reducers/ticketsReducer';
import conditionsReducer from '../components/Conditions/reducers/conditionsReducer';
import questionnairesReducer from '../components/Questionnaires/reducers/questionnairesReducer';
import genericReducer from '../components/Generic/reducer/genericReducer';
import settingsReducer from './settingsReducer';
import { localizeReducer } from "react-localize-redux";
import calendarReducer from '../components/Users/reducers/calendarReducer';
import promptReducer from '../components/Generic/reducer/promptReducer';
import tableReducer from '../components/Generic/reducer/tableReducer';
import websocketsReducer from '../components/Chat/reducers/webSocketsReducer';
import activitiesReducer from '../components/Activities/reducers/activitiesReducer';
import librariesReducer from '../components/Libraries/reducers/librariesReducer';
import statisticsReducer from '../components/Statistics/reducers/statisticsReducer';
import meetingsReducer from '../components/Meetings/reducer/meetingsReducer';

import { reducer as reduxFormReducer } from 'redux-form';
import { connectRouter } from 'connected-react-router'

const createRootReducer = (history) => combineReducers({
	router: connectRouter(history),
	config: configReducer,
	generic: genericReducer,
	form: reduxFormReducer, // mounted under "form"
    login: loginReducer,
    users: usersReducer,
	publisher: publisherReducer,
	subscriber: subscriberReducer,
	tickets: ticketsReducer,
	conditions: conditionsReducer,
	questionnaires: questionnairesReducer,
	localize: localizeReducer,
	calendar: calendarReducer,
	prompt: promptReducer,
	settings: settingsReducer,
	table: tableReducer,
	websockets: websocketsReducer,
	activities: activitiesReducer,
	libraries: librariesReducer,
	statistics: statisticsReducer,
	meetings: meetingsReducer
});

export default createRootReducer;