import { CHANGE_TABLE_PAGE, SET_TABLE_COUNT, HANDLE_TABLE_LOADING } from '../actions/types';
import { CLEAR_REDUCER } from '../../Login/actions/types';

const initialState = {
	page: 0,
	count: 0,
	loading: false,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case CHANGE_TABLE_PAGE:
			return {
				...state,
				page: action.payload,
			};
		case SET_TABLE_COUNT:
			return {
				...state,
				count: action.payload,
			};
		case CLEAR_REDUCER:
			return {
				...initialState,
			};
		case HANDLE_TABLE_LOADING:
			return {
				...state,
				loading: action.payload,
			};
		default:
			return state;
	}
}
