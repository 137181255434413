import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Menu, MenuItem, Divider, ListItemIcon, ListItemText, Tooltip } from '@material-ui/core';
import { fetchTicketAction } from '../Tickets/actions/ticketsActions';
import {
	ChatOutlined as ChatOutlinedIcon,
	ImageOutlined as ImageOutlinedIcon,
	ReportProblemOutlined as ReportProblemOutlinedIcon,
	EventNoteOutlined as EventNoteOutlinedIcon,
	LocalHospitalOutlined as LocalHospitalOutlinedIcon,
	AssignmentOutlined as AssignmentOutlinedIcon
} from '@material-ui/icons';

const NotificationIcon = (notification, translate) => {
	switch (notification.link_type) {
		case 'appointment':
			return (
				<Tooltip title={translate(notification.text)} aria-label={translate(notification.text)}>
					<EventNoteOutlinedIcon fontSize="small" />
				</Tooltip>
			);
		case 'questionnaire':
			return (
				<Tooltip title={translate(notification.text)} aria-label={translate(notification.text)}>
					<AssignmentOutlinedIcon fontSize="small" />
				</Tooltip>
			);
		case 'ticket':
			return (
				<Tooltip title={translate(notification.text)} aria-label={translate(notification.text)}>
					<LocalHospitalOutlinedIcon fontSize="small" />
				</Tooltip>
			);
		case 'file':
		case 'certificates':
			return (
				<Tooltip title={translate(notification.text)} aria-label={translate(notification.text)}>
					<ImageOutlinedIcon fontSize="small" />
				</Tooltip>
			);
		case 'diagnosis':
			return (
				<Tooltip title={translate(notification.text)} aria-label={translate(notification.text)}>
					<LocalHospitalOutlinedIcon fontSize="small" />
				</Tooltip>
			);
		case 'messages':
			return (
				<Tooltip title={translate(notification.text)} aria-label={translate(notification.text)}>
					<ChatOutlinedIcon fontSize="small" />
				</Tooltip>
			);


		default:
			return (
				<Tooltip title={translate(notification.text)} aria-label={translate(notification.text)}>
					<ReportProblemOutlinedIcon frontSize="small" />
				</Tooltip>
			);
	}
};

function RenderNotificationsBell({ translate, ...props }) {
	return (
		<Menu
			getContentAnchorEl={null}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center'
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'center'
			}}
			id={props.menuId}
			anchorEl={props.anchorEl}
			open={props.isMenuOpen}
			onClose={props.handleMenuClose}
		>
			{props.notifications.map((notification, index) => (
				<div key={index}>
					<MenuItem
						onClick={() => {
							props.handleMenuClose();
							props.fetchTicketAction(notification.ticket_id);
							props.push('/case');
						}}
					>
						<ListItemIcon className={'notifications-list-item-icon'}>{NotificationIcon(notification, translate)}</ListItemIcon>
						<ListItemText primary={notification.display_id + ' - ' + notification.condition_title} secondary={notification.created} />
					</MenuItem>
					{props.notifications.length === index + 1 ? null : <Divider />}
				</div>
			))}
		</Menu>
	);
}




export default connect(null, { push, fetchTicketAction })(RenderNotificationsBell);
