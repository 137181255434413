import axios from 'axios';
import { store } from '../../../store.js';
import { getTranslate } from 'react-localize-redux';
import { push } from 'connected-react-router';
import {
	FETCH_LIBRARY,
	FETCH_LIBRARY_DRAFTS,
	HANDLE_LIBRARY_LOADING,
	FETCH_LIBRARY_FILE,
	FETCH_LIBRARY_FILE_DATA,
	FETCH_LIBRARY_FOLDER,
	FETCH_LIBRARY_DRIVE,
	NEW_LIBRARY_FOLDER,
	NEW_LIBRARY_DRIVE,
	NEW_LIBRARY_FILE,
	DELETE_LIBRARY_FILE,
	DELETE_LIBRARY_FOLDER,
	DELETE_LIBRARY_DRIVE,
} from './types';
import { DOCUMENT_PREVIEW, HANDLE_TABLE_LOADING } from '../../Generic/actions/types';
import {
	convertToFormData,
	addDelayAction,
	handleLoadingAction,
	displayDocumentPreviewAction,
	disableFormButtonAction,
	displayProgressAction,
	setProgressValueAction,
	closeProgressAction,
} from '../../Generic/actions/genericActions';
import eventsService from '../../../helpers/Events';
import { parseDateOffset } from '../../../helpers/functions/functions';
import { openPdfFIle } from '../../../helpers/functions/functions';

import { dump } from './dump';

export const fetchLibraryAction =
	(filter = {}) =>
	(dispatch) => {
		const state = store.getState();
		const translate = getTranslate(state.localize);

		// dispatch({
		//     type: FETCH_LIBRARY,
		//     payload: {
		//         data: dump,
		//         total_count: dump.length,
		//     },
		// });

		axios
			.get(state.config.driveshost + `/`, {
				params: filter,
				headers: { Authorization: 'Bearer ' + state.login.token },
			})
			.then((res) => {
				dispatch({
					type: FETCH_LIBRARY,
					payload: {
						data: res.data.data.length > 0 ? res.data.data : [],
						drafts: res.data.drafts.length > 0 ? res.data.drafts : [],
						total_count: res.data.length,
					},
				});
			})
			.catch((err) => {
				console.log(err);
				console.log('Error fetching data.');
			});
	};

export const fetchLibraryDraftsAction = () => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	axios
		.get(state.config.driveshost + `/drafts`, {
			headers: { Authorization: 'Bearer ' + state.login.token },
		})
		.then((res) => {
			dispatch({
				type: FETCH_LIBRARY_DRAFTS,
				payload: {
					data: res.data.length > 0 ? res.data : [],
				},
			});
		})
		.catch((err) => {
			console.log(err);
			console.log('Error fetching data.');
		});
};

export const fetchLibraryFolderAction = (link, type) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	axios
		.get(state.config.driveshost + link, {
			headers: { Authorization: 'Bearer ' + state.login.token },
		})
		.then((res) => {
			if (type == 'drive') {
				dispatch({
					type: FETCH_LIBRARY_DRIVE,
					payload: res.data,
				});
			} else {
				dispatch({
					type: FETCH_LIBRARY_FOLDER,
					payload: res.data,
				});
			}
		})
		.catch((err) => {
			console.log(err);
			console.log('Error fetching data.');
		});
};

export const fetchLibraryFileAction = (link) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	axios
		.get(state.config.driveshost + link, {
			headers: { Authorization: 'Bearer ' + state.login.token },
		})
		.then((res) => {
			dispatch({
				type: FETCH_LIBRARY_FILE,
				payload: res.data,
			});

			dispatch(fetchLibraryFileDataAction(res.data.content));
		})
		.catch((err) => {
			console.log(err);
			console.log('Error fetching data.');
		});
};

export const fetchLibraryFileDataAction =
	(link, isDocumentPreview = false) =>
	(dispatch) => {
		const state = store.getState();
		const translate = getTranslate(state.localize);

		if (isDocumentPreview) {
			dispatch(displayDocumentPreviewAction(true));
		}

		axios
			.get(state.config.driveshost + link, {
				headers: { Authorization: 'Bearer ' + state.login.token },
			})
			.then((res) => {
				dispatch({
					type: FETCH_LIBRARY_FILE_DATA,
					payload: { data: res.data },
				});

				if (isDocumentPreview) {
					setTimeout(() => {
						dispatch(displayDocumentPreviewAction(false));
					}, 1000);
				}
			})
			.catch((err) => {
				console.log(err);
				console.log('Error fetching data.');
			});
	};

export const newLibraryPDFFileAction = (drives_id, parent_id) => (dispatch) => {
	dispatch({
		type: NEW_LIBRARY_FILE,
		payload: { drives_id, parent_id, mime_type: 'application/pdf' },
	});
};

export const newLibraryEditableFileAction = (drives_id, parent_id) => (dispatch) => {
	dispatch({
		type: NEW_LIBRARY_FILE,
		payload: { drives_id, parent_id, mime_type: 'text/plain' },
	});
};

export const newLibraryFolderAction = (drives_id, parent_id) => (dispatch) => {
	dispatch({
		type: NEW_LIBRARY_FOLDER,
		payload: { drives_id, parent_id },
	});
};

export const newLibraryDriveAction = () => (dispatch) => {
	dispatch({
		type: NEW_LIBRARY_DRIVE,
	});
};

export const postLibraryDriveAction = (form, handleCloseFunction) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	let data = convertToFormData(state.form[form].values);

	axios
		.post(state.config.driveshost, data, {
			headers: { Authorization: 'Bearer ' + state.login.token },
		})
		.then((res) => {
			dispatch({
				type: FETCH_LIBRARY_DRIVE,
				payload: res.data,
			});

			dispatch(fetchLibraryAction());

			dispatch(
				addDelayAction(null, [
					() => eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_post_libraries_drive') }),
					() => handleCloseFunction(),
				])
			);
		})
		.catch((err) => {
			console.log(err);
			dispatch(addDelayAction(null, () => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

export const patchLibraryDriveAction = (id, form, handleCloseFunction) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	let data = convertToFormData(state.form[form].values);

	axios
		.patch(state.config.driveshost + `/` + id, data, {
			headers: { Authorization: 'Bearer ' + state.login.token },
		})
		.then((res) => {
			dispatch({
				type: FETCH_LIBRARY_DRIVE,
				payload: res.data,
			});

			dispatch(fetchLibraryAction());

			dispatch(
				addDelayAction(null, [
					() => eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_patch_libraries_drive') }),
					() => handleCloseFunction(),
				])
			);
		})
		.catch((err) => {
			console.log(err);
			dispatch(addDelayAction(null, () => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

export const postLibraryFolderAction = (drive_id, form, handleCloseFunction) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	let data = convertToFormData(state.form[form].values);

	axios
		.post(state.config.driveshost + `/` + drive_id + `/folders`, data, {
			headers: { Authorization: 'Bearer ' + state.login.token },
		})
		.then((res) => {
			dispatch({
				type: FETCH_LIBRARY_FOLDER,
				payload: res.data,
			});

			dispatch(fetchLibraryAction());

			dispatch(
				addDelayAction(null, [
					() => eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_post_libraries_folder') }),
					() => handleCloseFunction(),
				])
			);
		})
		.catch((err) => {
			console.log(err);
			dispatch(addDelayAction(null, () => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

export const patchLibraryFolderAction = (id, drive_id, form, handleCloseFunction) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	let data = convertToFormData(state.form[form].values);

	axios
		.patch(state.config.driveshost + `/` + drive_id + `/folders/` + id, data, {
			headers: { Authorization: 'Bearer ' + state.login.token },
		})
		.then((res) => {
			dispatch({
				type: FETCH_LIBRARY_FOLDER,
				payload: res.data,
			});

			dispatch(fetchLibraryAction());

			dispatch(
				addDelayAction(null, [
					() => eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_patch_libraries_folder') }),
					() => handleCloseFunction(),
				])
			);
		})
		.catch((err) => {
			console.log(err);
			dispatch(addDelayAction(null, () => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

// FILE save actions

// FILE save actions

export const postLibraryFileAction = (form) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);
	let data = convertToFormData(state.form[form].values);

	if (!state.form[form].values.chunked) {
		data.delete('chunked');
	}

	axios
		.post(state.config.driveshost + `/` + state.form[form].values.drive_id + `/folders/` + state.form[form].values.parent_id + `/files`, data, {
			headers: { Authorization: 'Bearer ' + state.login.token, 'Content-Type': 'multipart/form-data' },
		})
		.then((res) => {
			dispatch({
				type: FETCH_LIBRARY_FILE,
				payload: res.data,
			});
			/*TODO: Add the form disable button action on each submit action*/
			//dispatch(disableFormButtonAction(false));
			dispatch(fetchLibraryAction());

			dispatch(
				addDelayAction([disableFormButtonAction(false), push('/library/edit')], () =>
					eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_post_libraries_file') })
				)
			);
		})
		.catch((err) => {
			console.log(err);
			//dispatch(disableFormButtonAction(false));
			dispatch(addDelayAction(null, () => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

export const postLibraryChunkedFileAction = (form) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	let data = convertToFormData(state.form[form].values);
	let file = state.form[form].values.content[0];

	if (!state.form[form].values.chunked) {
		data.delete('chunked');
	}

	var size = file.size;
	
	var uid = '';
	var lastChunk = false;
	var sliceSize = 1024 * 1024 * 10;
	sliceSize = sliceSize > size ? size : sliceSize;
	var start = 0;
	var end = sliceSize;
	var slicedPart = file.slice(start, end);
	sliceSize = sliceSize > size ? size : sliceSize;

	var chunksCnt = Math.ceil(size / sliceSize);

	var chunk = 0;

	data.append('uid', '');

	dispatch(
		displayProgressAction({
			title: translate('file_form_progress_modal_title'), //File upload in progress
			description: translate('file_form_progress_modal_description', {
				size: ((Math.pow(10, -6) * size).toFixed(1)),
				file_name: file.name
			}),
			button_cancel: translate('generic_button_cancel'),
			button_cancel_id: 'generic-progress-modal-cancel-button',
			// props: data,
		})
	);

	const send = () => {
		chunk = Math.ceil(start / sliceSize);

		if (end >= size) {
			lastChunk = true;
			data.append('lastChunk', lastChunk);
		}

		if (size - end < 0) {
			end = size;
		}

		slicedPart = file.slice(start, end);

		data.set('content', slicedPart);

		if (uid.length > 0) {
			data.set('uid', uid);
		}

		axios
			.post(
				state.config.driveshost + `/` + state.form[form].values.drive_id + `/folders/` + state.form[form].values.parent_id + `/files`,
				data,
				{
					headers: { Authorization: 'Bearer ' + state.login.token, 'Content-Type': 'multipart/form-data' },
				}
			)
			.then((res) => {
				if (lastChunk) {
					dispatch(setProgressValueAction(100));

					dispatch({
						type: FETCH_LIBRARY_FILE,
						payload: res.data,
					});
					/*TODO: Add the form disable button action on each submit action*/
					//dispatch(disableFormButtonAction(false));
					dispatch(fetchLibraryAction());

					dispatch(
						addDelayAction([closeProgressAction(), disableFormButtonAction(false), push('/library/edit')], () =>
							eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_post_libraries_file') })
						)
					);
				} else {
					uid = res.data.uid;
					start = end;
					end = start + sliceSize;
					console.log('Sending Last Chunk: ' + (chunk + 1) + '/' + chunksCnt + ' (range: ' + start + '/' + end + ')');

					dispatch(setProgressValueAction(((chunk + 1) * 100) / chunksCnt));

					if (!store.getState().generic.progress.canceled) {
						send();
					} else {
						dispatch(fetchLibraryAction());

						dispatch(
							addDelayAction([disableFormButtonAction(false), push('/library/edit')], () =>
								eventsService.triggerEvent('alert', {
									type: 'warning',
									message: translate('alert_post_libraries_file_upload_canceled'),
								})
							)
						);
					}
				}
			})
			.catch((err) => {
				console.log(err);
				//dispatch(disableFormButtonAction(false));
				dispatch(closeProgressAction());
				dispatch(addDelayAction(null, () => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
			});
	};

	send();
};

export const patchLibraryFileAction = (id, form) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	let data = convertToFormData(state.form[form].values);

	axios
		.patch(
			state.config.driveshost + `/` + state.form[form].values.drive_id + `/folders/` + state.form[form].values.parent_id + `/files/` + id,
			data,
			{
				headers: { Authorization: 'Bearer ' + state.login.token, 'Content-Type': 'multipart/form-data' },
			}
		)
		.then((res) => {
			dispatch({
				type: FETCH_LIBRARY_FILE,
				payload: res.data,
			});

			dispatch(fetchLibraryAction());

			dispatch(
				addDelayAction([disableFormButtonAction(false), push('/library/edit')], () =>
					eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_patch_libraries_file') })
				)
			);
		})
		.catch((err) => {
			console.log(err);
			dispatch(addDelayAction(null, () => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

export const deleteLibraryFileAction = (link) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	axios
		.delete(state.config.driveshost + link, {
			headers: { Authorization: 'Bearer ' + state.login.token },
		})
		.then((res) => {
			dispatch({
				type: DELETE_LIBRARY_FILE,
			});

			dispatch(fetchLibraryAction());

			dispatch(
				addDelayAction(push('/library/edit'), () =>
					eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_delete_libraries_file') })
				)
			);
		})
		.catch((err) => {
			console.log(err);
			dispatch(addDelayAction(null, () => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

export const deleteLibraryFolderAction = (link) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	axios
		.delete(state.config.driveshost + link, {
			headers: { Authorization: 'Bearer ' + state.login.token },
		})
		.then((res) => {
			dispatch({
				type: DELETE_LIBRARY_FOLDER,
			});

			dispatch(fetchLibraryAction());

			dispatch(
				addDelayAction(push('/library/edit'), () =>
					eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_delete_libraries_folder') })
				)
			);
		})
		.catch((err) => {
			console.log(err);
			dispatch(addDelayAction(null, () => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

export const deleteLibraryDriveAction = (link) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	axios
		.delete(state.config.driveshost + link, {
			headers: { Authorization: 'Bearer ' + state.login.token },
		})
		.then((res) => {
			dispatch({
				type: DELETE_LIBRARY_DRIVE,
			});

			dispatch(fetchLibraryAction());

			dispatch(
				addDelayAction(push('/library/edit'), () =>
					eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_delete_libraries_drive') })
				)
			);
		})
		.catch((err) => {
			console.log(err);
			dispatch(addDelayAction(null, () => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

// DRAFT actions

export const postLibraryDraftAction = (form) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	let data = convertToFormData(state.form[form].values);

	axios
		.post(state.config.driveshost + `/drafts`, data, {
			headers: { Authorization: 'Bearer ' + state.login.token, 'Content-Type': 'multipart/form-data' },
		})
		.then((res) => {
			dispatch({
				type: FETCH_LIBRARY_FILE,
				payload: res.data,
			});

			dispatch(fetchLibraryAction());

			dispatch(
				addDelayAction(null, () => eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_post_libraries_draft') }))
			);
		})
		.catch((err) => {
			console.log(err);
			dispatch(addDelayAction(null, () => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

export const patchLibraryDraftAction = (id, form) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	let data = convertToFormData(state.form[form].values);

	axios
		.patch(state.config.driveshost + `/drafts/` + id, data, {
			headers: { Authorization: 'Bearer ' + state.login.token, 'Content-Type': 'multipart/form-data' },
		})
		.then((res) => {
			dispatch({
				type: FETCH_LIBRARY_FILE,
				payload: res.data,
			});

			dispatch(fetchLibraryAction());

			dispatch(
				addDelayAction(null, () =>
					eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_patch_libraries_draft') })
				)
			);
		})
		.catch((err) => {
			console.log(err);
			dispatch(addDelayAction(null, () => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

export const deleteLibraryDraftAction = (id) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	axios
		.delete(state.config.driveshost + `/drafts/` + id, {
			headers: { Authorization: 'Bearer ' + state.login.token },
		})
		.then((res) => {
			dispatch({
				type: DELETE_LIBRARY_FILE,
			});

			dispatch(fetchLibraryAction());
		})
		.catch((err) => {
			console.log(err);
			dispatch(addDelayAction(null, () => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

export const fetchLibraryDraftAction = (draft_id) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	axios
		.get(state.config.driveshost + '/drafts/' + draft_id, {
			headers: { Authorization: 'Bearer ' + state.login.token },
		})
		.then((res) => {
			dispatch({
				type: FETCH_LIBRARY_FILE,
				payload: res.data,
			});

			dispatch(fetchLibraryFileDataAction(res.data.content));
		})
		.catch((err) => {
			console.log(err);
			console.log('Error fetching data.');
		});
};
