import axios from 'axios';
import { getTranslate } from 'react-localize-redux';
import {
	FETCH_TICKET,
	UPDATE_TICKET,
	CHANGE_OVERVIEW_COMPONENT,
	TICKET_PAYMENT,
	FETCH_CARE_TEAM,
	FETCH_TICKETS_DATA,
	FETCH_TICKET_QUESTIONNAIRE,
	TICKET_DIAGNOSIS,
	FETCH_UNREAD_TICKETS_DATA,
	SAVE_DIAGNOSIS,
	FETCH_HISTORY_RECORD,
} from './types';
import { HANDLE_TABLE_LOADING } from '../../Generic/actions/types';
import { store } from '../../../store.js';
import eventsService from '../../../helpers/Events';
import { convertToFormData, handleLoadingAction, addDelayAction, closeLightboxAction } from '../../Generic/actions/genericActions';
import { setPromptDialogIsDirty } from '../../Generic/actions/promptActions';
import { setTableCountAction } from '../../Generic/actions/tableActions';
import { fetchQuestionnaireAction } from '../../Questionnaires/actions/questionnairesActions';
import { reset } from 'redux-form';
import { push } from 'connected-react-router';
import { openPdfFIle, dateToString, parseDateOffset } from '../../../helpers/functions/functions';
import { deleteLibraryDraftAction } from '../../Libraries/actions/librariesActions';
import jsPDF from 'jspdf';
import domtoimage from 'dom-to-image';

export const fetchTicketsAction =
	(config, pagination = {}, filters = {}) =>
	(dispatch) => {
		const state = store.getState();
		const translate = getTranslate(state.localize);

		var params = { ...pagination, ...filters };

		var columns = [];

		config.map((field) => {
			if (field.options.renderOnExpand) {
				field.options.display = 'excluded';
			}
			columns.push({
				name: field.label,
				label: translate('table_column_' + field.label),
				options: {
					...field.options,
				},
			});
		});

		dispatch({
			type: FETCH_TICKETS_DATA,
			payload: {
				columns: columns,
				data: [],
				count: 0,
			},
		});

		dispatch({
			type: HANDLE_TABLE_LOADING,
			payload: true,
		});

		setTimeout(() => {
			axios
				.get(state.config.apihost + `/tickets`, {
					params,
					headers: { Authorization: 'Bearer ' + state.login.token },
				})
				.then((res) => {
					dispatch({
						type: HANDLE_TABLE_LOADING,
						payload: false,
					});

					dispatch({
						type: FETCH_TICKETS_DATA,
						payload: {
							columns: columns,
							data: res.data.data,
							count: res.data.total_count,
						},
					});

					dispatch(setTableCountAction(res.data.total_count));
				})
				.catch((err) => {
					dispatch({
						type: HANDLE_TABLE_LOADING,
						payload: false,
					});

					console.log(err);
					console.log('Error fetching data.');
				});
		}, 1000);
	};

export const fetchTicketAction =
	(id, loading = true) =>
	(dispatch) => {
		if (loading) {
			dispatch(handleLoadingAction(true));
		}

		const state = store.getState();

		axios
			.get(state.config.apihost + `/tickets/` + id, {
				headers: { Authorization: 'Bearer ' + state.login.token },
			})
			.then((res) => {
				dispatch({
					type: FETCH_TICKET,
					payload: res.data,
				});
				dispatch(fetchCareTeamAction());
				dispatch(fetchTicketDiagnosisAction());

				if (loading) {
					dispatch(handleLoadingAction(false));
				}
			})
			.catch((error) => {
				if (loading) {
					dispatch(handleLoadingAction(false));
				}
				console.log(error);
			});
	};

export const updateTicketAction = (params) => (dispatch) => {
	const state = store.getState();
	let ticket_id = params.id;

	axios
		.get(state.config.apihost + `/tickets/` + ticket_id, {
			headers: { Authorization: 'Bearer ' + state.login.token },
		})
		.then((res) => {
			dispatch({
				type: UPDATE_TICKET,
				payload: res.data,
			});

			dispatch(fetchCareTeamAction());
			dispatch(fetchTicketDiagnosisAction());
		})
		.catch((error) => {
			console.log(error);
		});
};

export const assignTicketPractitionerAction = (user_id) => (dispatch) => {
	const state = store.getState();

	var params = new FormData();
	params.append('user_id', user_id);

	axios
		.post(state.config.apihost + `/tickets/` + state.tickets.ticket.id + `/assign`, params, {
			headers: { Authorization: 'Bearer ' + state.login.token },
		})
		.then((res) => {
			dispatch(fetchTicketAction(state.tickets.ticket.id));
		})
		.catch((error) => {
			console.log(error);
		});
};

export const fetchCareTeamAction =
	(ticket_id = false) =>
	(dispatch) => {
		const state = store.getState();

		if (!ticket_id) {
			ticket_id = state.tickets.ticket.id;
		}
		axios
			.get(state.config.apihost + `/tickets/` + ticket_id + `/careteam`, {
				headers: { Authorization: 'Bearer ' + state.login.token },
			})
			.then((res) => {
				dispatch({
					type: FETCH_CARE_TEAM,
					payload: res.data,
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};

export const addUserToCareTeamAction =
	(user_id, user_data = null, callback = null) =>
	(dispatch) => {
		const state = store.getState();
		const translate = getTranslate(state.localize);

		var form = new FormData();
		form.append('user_id', user_id);
		if (user_data) {
			form.append('role_id', user_data.roles_id);
			form.append('organization', user_data.organization);
		}

		axios
			.post(state.config.apihost + `/tickets/` + state.tickets.ticket.id + `/access`, form, {
				headers: { Authorization: 'Bearer ' + state.login.token },
			})
			.then((res) => {
				dispatch(updateTicketAction({ id: state.tickets.ticket.id }));
				if (callback) {
					callback(res.data);
				}
				dispatch(
					addDelayAction(null, () => eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_add_careteam') }))
				);
			})
			.catch((err) => {
				dispatch(addDelayAction(null, () => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
				if (callback) {
					callback(err);
				}
				console.log(err);
			});
	};

export const removeUserFromCareTeamAction = (user_id, callback) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	axios
		.delete(state.config.apihost + `/tickets/` + state.tickets.ticket.id + `/access/` + user_id, {
			headers: { Authorization: 'Bearer ' + state.login.token },
		})
		.then((res) => {
			dispatch(updateTicketAction({ id: state.tickets.ticket.id }));

			callback(res.data);
			dispatch(
				addDelayAction(null, () => eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_remove_careteam') }))
			);
		})
		.catch((err) => {
			dispatch(addDelayAction(null, () => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
			callback(err);
			console.log(err);
		});
};

export const fetchTicketHistoryAction =
	(config, pagination = {}, filters = {}) =>
	(dispatch) => {
		const state = store.getState();
		const translate = getTranslate(state.localize);

		var params = { ...pagination, ...filters };

		var columns = [];

		config.map((field) => {
			if (field.options.renderOnExpand) {
				field.options.display = 'excluded';
			}
			columns.push({
				name: field.label,
				label: translate('table_column_' + field.label),
				options: {
					...field.options,
				},
			});
		});

		dispatch({
			type: FETCH_TICKETS_DATA,
			payload: {
				columns: columns,
				data: [],
				count: 0,
			},
		});

		dispatch({
			type: HANDLE_TABLE_LOADING,
			payload: true,
		});
		setTimeout(() => {
			axios
				.get(state.config.apihost + `/auth/users/` + state.tickets.ticket.users_id + `/tickets`, {
					params,
					headers: { Authorization: 'Bearer ' + state.login.token },
				})
				.then((res) => {
					dispatch({
						type: HANDLE_TABLE_LOADING,
						payload: false,
					});

					dispatch({
						type: FETCH_TICKETS_DATA,
						payload: {
							columns: columns,
							data: res.data.data,
							count: res.data.total_count,
						},
					});

					dispatch(setTableCountAction(res.data.total_count));

					dispatch({
						type: FETCH_HISTORY_RECORD,
						payload: [],
					});
				})
				.catch((error) => {
					dispatch({
						type: HANDLE_TABLE_LOADING,
						payload: false,
					});

					console.log(error);
				});
		}, 1000);
	};

export const fetchHistoryRecordAction = (id) => (dispatch) => {
	const state = store.getState();

	axios
		.get(state.config.apihost + `/tickets/` + id, {
			headers: { Authorization: 'Bearer ' + state.login.token },
		})
		.then((res) => {
			dispatch({
				type: FETCH_HISTORY_RECORD,
				payload: res.data,
			});
		})
		.catch((error) => {
			console.log(error);
		});
};

export const fetchTicketQuestionnaireAction = () => (dispatch) => {
	const state = store.getState();

	axios
		.get(state.config.apihost + `/tickets/` + state.tickets.ticket.id + `/questionnaires/` + state.tickets.overview_component_id, {
			headers: { Authorization: 'Bearer ' + state.login.token },
		})
		.then((res) => {
			res.data.children.forEach((question) => {
				if (question.type === 'text') {
					question.answer = question.text;
				}
				question.triggered = typeof question.answer !== 'undefined' && question.answer.length > 0 ? true : false;
			});

			dispatch({
				type: FETCH_TICKET_QUESTIONNAIRE,
				payload: {
					questionnaire: res.data,
					completed: true,
				},
			});
		})
		.catch((error) => {
			console.log(error);
		});
};

export const fetchTicketFollowupQuestionnaireAction = (questionnaire_id) => (dispatch) => {
	const state = store.getState();

	axios
		.get(state.config.apihost + `/tickets/` + state.tickets.ticket.id + `/questionnaires/` + questionnaire_id, {
			headers: { Authorization: 'Bearer ' + state.login.token },
		})
		.then((res) => {
			res.data.questionnaire.children.forEach((question) => {
				question.answer = '';
				if (question.type === 'text') {
					question.answer = question.text;
				}
				question.triggered = question.parent_id === '0' ? true : false;
			});

			let followup_questionnaire = state.tickets.ticket.questionnaires.find((questionnaire) => questionnaire.id === questionnaire_id);

			if (followup_questionnaire.status === '0' && state.users.whoami.scopes.includes('customer')) {
				dispatch(handleLoadingAction(true));
			}

			dispatch({
				type: FETCH_TICKET_QUESTIONNAIRE,
				payload: {
					questionnaire: res.data.questionnaire,
					completed: followup_questionnaire.status === '0' ? false : true,
				},
			});

			//TODO: change scope check to ticket scopes
			// if (followup_questionnaire.status === '0' && state.tickets.ticket.scopes.includes('customer')) {
			if (followup_questionnaire.status === '0' && state.users.whoami.scopes.includes('customer')) {
				dispatch(handleLoadingAction(false));
				store.dispatch(push('/followup/tickets/' + state.tickets.ticket.id + '/questionnaire/' + questionnaire_id));
			} else {
				dispatch(handleLoadingAction(false));
				eventsService.triggerEvent('tab', 0);
				dispatch(changeOverviewComponentAction('questionnaires', followup_questionnaire.id));
				dispatch(fetchTicketQuestionnaireAction());
			}
		})
		.catch((error) => {
			console.log(error);
			dispatch(handleLoadingAction(false));
		});
};

export const fetchTicketDiagnosisAction = () => (dispatch) => {
	const state = store.getState();

	axios
		.get(state.config.apihost + `/tickets/` + state.tickets.ticket.id + `/diagnoses`, {
			headers: { Authorization: 'Bearer ' + state.login.token },
		})
		.then((res) => {
			dispatch({
				type: TICKET_DIAGNOSIS,
				payload: res.data,
			});
			if (res.data.length > 0) dispatch(saveDiagnosisAction(res.data.map((v) => Number(v.diagnoses_id))));
		})
		.catch((error) => {
			console.log(error);
		});
};

export const changeOverviewComponentAction =
	(component = '', id = '', back_component = '') =>
	(dispatch) => {
		dispatch({
			type: CHANGE_OVERVIEW_COMPONENT,
			payload: {
				overview_component: component,
				overview_component_id: id,
				overview_component_back: back_component,
			},
		});
	};

const convertModelToFormData = (model, form, namespace) => {
	let formData = form || new FormData();

	for (let propertyName in model) {
		if (!model.hasOwnProperty(propertyName) || !model[propertyName]) continue;
		let formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
		if (model[propertyName] instanceof Date) formData.append(formKey, model[propertyName].toISOString());
		else if (model[propertyName] instanceof Array) {
			model[propertyName].forEach((element, index) => {
				const tempFormKey = `${formKey}[${index}]`;
				convertModelToFormData(element, formData, tempFormKey);
			});
		} else if (typeof model[propertyName] === 'object' && !(model[propertyName] instanceof File))
			convertModelToFormData(model[propertyName], formData, formKey);
		else formData.append(formKey, model[propertyName].toString());
	}
	return formData;
};

export const postTicketAction = (form) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	let body = {
		conditions_id: state.conditions.selected.id,
	};

	if (
		state.conditions.filter.group !== 'adult' &&
		state.settings.site.display_condition_filters &&
		state.settings.site.display_condition_filters
	) {
		body['ward'] = state.form[form].values;
	}

	body = convertModelToFormData(body);

	axios
		.post(state.config.apihost + `/tickets/`, body, {
			headers: { Authorization: 'Bearer ' + state.login.token, 'Content-Type': 'multipart/form-data' },
		})
		.then((res) => {
			dispatch({
				type: FETCH_TICKET,
				payload: res.data,
			});

			if (res.data.patient.status === '10' && res.data.has_ward) {
				dispatch(push('/case/new/ward/data'));
			} else {
				dispatch(
					addDelayAction([
						fetchQuestionnaireAction({ questionnaire_id: res.data.questionnaires[0].questionnaires_id }),
						push('/case/new/' + res.data.id + '/questionnaires/' + res.data.questionnaires[0].id),
						setPromptDialogIsDirty({
							is_dirty: true,
							path_allowed: ['/case/new/'],
							back_label: 'questionnaires_prompt_back_btn_label',
							next_label: 'questionnaires_prompt_next_btn_label',
						}),
					])
				);
			}
		})
		.catch((err) => {
			dispatch(
				addDelayAction(null, () =>
					eventsService.triggerEvent('alert', {
						type: 'error',
						message:
							typeof err.response.data.context.message !== 'undefined' ? translate(err.response.data.context.message) : err.message,
					})
				)
			);
		});
};

export const handleIntiveTicketAction = (id) => (dispatch) => {
	dispatch(handleLoadingAction(true));

	const state = store.getState();

	axios
		.get(state.config.apihost + `/tickets/` + id, {
			headers: { Authorization: 'Bearer ' + state.login.token },
		})
		.then((res) => {
			dispatch({
				type: FETCH_TICKET,
				payload: res.data,
			});

			dispatch(
				addDelayAction([
					fetchQuestionnaireAction({ questionnaire_id: res.data.questionnaires[0].questionnaires_id }),
					push('/case/new/' + res.data.id + '/questionnaires/' + res.data.questionnaires[0].id),
					handleLoadingAction(false),
					setPromptDialogIsDirty({
						is_dirty: true,
						path_allowed: ['/case/new/'],
						back_label: 'questionnaires_prompt_back_btn_label',
						next_label: 'questionnaires_prompt_next_btn_label',
					}),
				])
			);
		})
		.catch((error) => {
			dispatch(handleLoadingAction(false));
			dispatch(addDelayAction(null, () => eventsService.triggerEvent('alert', { type: 'error', message: error.message })));
			console.log(error);
		});
};

export const patchWardPatientAction = (form) => (dispatch) => {
	const state = store.getState();

	let body = state.form[form].values;

	body['name'] = body.first_name + ' ' + body.last_name;
	body['birthdate'] = dateToString(new Date(state.form[form].values.birthdate));

	body['consented'] = state.form[form].values.consented ? '1' : '0';

	body = convertModelToFormData(body);

	axios
		.patch(state.config.apihost + `/tickets/` + state.tickets.ticket.id + `/ward`, body, {
			headers: { Authorization: 'Bearer ' + state.login.token, 'Content-Type': 'multipart/form-data' },
		})
		.then((res) => {
			dispatch(fetchTicketAction(state.tickets.ticket.id, false));

			dispatch(
				addDelayAction([
					fetchQuestionnaireAction({ questionnaire_id: state.tickets.ticket.questionnaires[0].questionnaires_id }),
					push('/case/new/' + state.tickets.ticket.id + '/questionnaires/' + state.tickets.ticket.questionnaires[0].id),
					setPromptDialogIsDirty({
						is_dirty: true,
						path_allowed: ['/case/new/'],
						back_label: 'questionnaires_prompt_back_btn_label',
						next_label: 'questionnaires_prompt_next_btn_label',
					}),
				])
			);
		})
		.catch((err) => {
			dispatch(addDelayAction(null, () => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

export const postPayment =
	(ticket_id, form, consent = false) =>
	(dispatch) => {
		const state = store.getState();
		const translate = getTranslate(state.localize);

		let payment_type = '';
		let data = '';

		if (consent) {
			payment_type = 'consent';
			data = convertToFormData({ payment_type: 'consent' });
		} else {
			payment_type = state.form[form].values.payment_type;
			data = convertToFormData(state.form[form].values);
		}

		axios
			.post(state.config.apihost + `/tickets/` + ticket_id + `/payments`, data, {
				params: { payment_type: payment_type },
				headers: { Authorization: 'Bearer ' + state.login.token, 'Content-Type': 'multipart/form-data' },
			})
			.then((res) => {
				dispatch({
					type: TICKET_PAYMENT,
					payload: res.data,
				});

				dispatch(
					addDelayAction([setPromptDialogIsDirty({ is_dirty: false }), push('/case/new/' + ticket_id + '/summary')], () =>
						eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_post_ticket') })
					)
				);
			})
			.catch((err) => {
				dispatch(addDelayAction(null, () => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
			});
	};

export const postFollowupAction = (form) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	const start_minutes = state.form[form].values.activation.getMinutes();

	var start = parseDateOffset(state.form[form].values.activation).toISOString().split('.')[0] + 'Z';
	start = start.replace('T', ' ').replace('Z', '');

	var end = state.form[form].values.activation.setMinutes(start_minutes + Number(state.form[form].values.duration));
	end = parseDateOffset(state.form[form].values.activation).toISOString().split('.')[0] + 'Z';
	end = end.replace('T', ' ').replace('Z', '');

	var body = new FormData();

	body.append('activation', start);
	body.append('ends_at', end);
	body.append('duration', state.form[form].values.duration);
	body.append('conditions_id', state.form[form].values.conditions_id);
	body.append('assignedtome', state.form[form].values.assignedtome ? '1' : '0');
	body.append('message', state.form[form].values.message);

	axios
		.post(state.config.apihost + '/tickets/' + state.tickets.ticket.id + '/followups', body, {
			headers: { Authorization: 'Bearer ' + state.login.token, 'Content-Type': 'multipart/form-data' },
		})
		.then((res) => {
			dispatch(updateTicketAction({ id: state.tickets.ticket.id }));

			dispatch(
				addDelayAction(reset(form), [
					() => eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_post_followup') }),
					() => eventsService.triggerEvent('tab'),
				])
			);
		})
		.catch((err) => {
			dispatch(addDelayAction(() => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

export const postAppointmentAction = (form) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	const start_minutes = state.form[form].values.starts_at.getMinutes();

	var start = parseDateOffset(state.form[form].values.starts_at).toISOString().split('.')[0] + 'Z';
	start = start.replace('T', ' ').replace('Z', '');

	var end = state.form[form].values.starts_at.setMinutes(start_minutes + Number(state.form[form].values.duration));
	end = parseDateOffset(state.form[form].values.starts_at).toISOString().split('.')[0] + 'Z';
	end = end.replace('T', ' ').replace('Z', '');

	var body = new FormData();

	body.append('starts_at', start);
	body.append('ends_at', end);
	body.append('duration', state.form[form].values.duration);
	body.append('text', state.form[form].values.text);

	var users_arr = [];
	state.form[form].values.users.map((user, key) => {
		users_arr[key] = user.value;
	});

	body.append('invitees', users_arr.toString());

	axios
		.post(state.config.apihost + '/tickets/' + state.tickets.ticket.id + '/appointments', body, {
			headers: { Authorization: 'Bearer ' + state.login.token, 'Content-Type': 'multipart/form-data' },
		})
		.then((res) => {
			dispatch(updateTicketAction({ id: state.tickets.ticket.id }));
			dispatch(
				addDelayAction(reset(form), [
					() => eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_post_appointment') }),
					() => eventsService.triggerEvent('tab'),
				])
			);
		})
		.catch((err) => {
			dispatch(addDelayAction(() => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

export const postRejectAction = (form) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	let data = convertToFormData(state.form[form].values);

	axios
		.post(state.config.apihost + '/tickets/' + state.tickets.ticket.id + '/reject', data, {
			headers: { Authorization: 'Bearer ' + state.login.token, 'Content-Type': 'multipart/form-data' },
		})
		.then((res) => {
			dispatch(updateTicketAction({ id: state.tickets.ticket.id }));
			dispatch(
				addDelayAction(reset(form), [
					() => eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_post_reject') }),
					() => eventsService.triggerEvent('tab'),
				])
			);
		})
		.catch((err) => {
			dispatch(addDelayAction(() => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

export const postNoteAction = (form) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	let data = convertToFormData(state.form[form].values);

	axios
		.post(state.config.apihost + '/tickets/' + state.tickets.ticket.id + '/notes', data, {
			headers: { Authorization: 'Bearer ' + state.login.token, 'Content-Type': 'multipart/form-data' },
		})
		.then((res) => {
			dispatch(updateTicketAction({ id: state.tickets.ticket.id }));
			dispatch(
				addDelayAction(reset(form), [
					() => eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_post_note') }),
					() => eventsService.triggerEvent('tab'),
				])
			);
		})
		.catch((err) => {
			dispatch(addDelayAction(() => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

export const postAddQuestionnaireAction = (form) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	let data = convertToFormData(state.form[form].values);

	axios
		.post(state.config.apihost + '/tickets/' + state.tickets.ticket.id + '/questionnaires', data, {
			headers: { Authorization: 'Bearer ' + state.login.token, 'Content-Type': 'multipart/form-data' },
		})
		.then((res) => {
			dispatch(updateTicketAction({ id: state.tickets.ticket.id }));

			dispatch(
				addDelayAction(reset(form), [
					() => eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_post_addquestionnaire') }),
					() => eventsService.triggerEvent('tab'),
				])
			);
		})
		.catch((err) => {
			dispatch(addDelayAction(() => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

export const postUploadFileAction = (form) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	let data = convertToFormData(state.form[form].values);

	axios
		.post(state.config.apihost + '/tickets/' + state.tickets.ticket.id + '/files', data, {
			headers: { Authorization: 'Bearer ' + state.login.token, 'Content-Type': 'multipart/form-data' },
		})
		.then(() => {
			dispatch(updateTicketAction({ id: state.tickets.ticket.id }));
			dispatch(
				addDelayAction(reset(form), [
					() => eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_post_uploadfile') }),
					() => eventsService.triggerEvent('tab'),
				])
			);
		})
		.catch((err) => {
			dispatch(addDelayAction(() => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

export const postQuestionnairePDFAction = (pdf, filename ,callback) => (dispatch) => {
	dispatch(handleLoadingAction(true));
	const state = store.getState();
	const translate = getTranslate(state.localize);
	const file =new File([pdf], filename)

	var data = new FormData();
	data.append('type', 'upload');
	data.append('upload', file);


	axios
		.post(state.config.apihost + '/tickets/' + state.tickets.ticket.id + '/files', data, {
			headers: { Authorization: 'Bearer ' + state.login.token, 'Content-Type': 'multipart/form-data' },
		})
		.then(() => {
			dispatch(updateTicketAction({ id: state.tickets.ticket.id }));
			dispatch(
				addDelayAction( [
					() => eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_post_uploadfile') }),
					() => eventsService.triggerEvent('tab'),
					callback,
					handleLoadingAction(false),
				])
			);
		})
		.catch((err) => {
			dispatch(addDelayAction(() => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

export const postScreenShotAction = () => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	let data = convertToFormData({
		type: 'screenshot',
		upload: [state.generic.lightbox.screenshot],
	});

	axios
		.post(state.config.apihost + '/tickets/' + state.tickets.ticket.id + '/files', data, {
			headers: { Authorization: 'Bearer ' + state.login.token, 'Content-Type': 'multipart/form-data' },
		})
		.then(() => {
			dispatch(updateTicketAction({ id: state.tickets.ticket.id }));
			dispatch(closeLightboxAction());
			dispatch(
				addDelayAction(null, [() => eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_post_uploadfile') })])
			);
		})
		.catch((err) => {
			dispatch(closeLightboxAction());
			eventsService.triggerEvent('alert', { type: 'error', message: err.message });
		});
};

export const postUploadFileChatAction = (data) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	axios
		.post(state.config.apihost + '/tickets/' + state.tickets.ticket.id + '/files', data, {
			headers: { Authorization: 'Bearer ' + state.login.token },
		})
		.then((res) => {
			dispatch(updateTicketAction({ id: state.tickets.ticket.id }));

			eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_post_uploadfile') });
		})
		.catch((err) => {
			eventsService.triggerEvent('alert', { type: 'error', message: err.message });
		});
};

export const postAlertAction = (form) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	let data = convertToFormData(state.form[form].values);

	axios
		.post(state.config.apihost + '/tickets/' + state.tickets.ticket.id + '/alerts', data, {
			headers: { Authorization: 'Bearer ' + state.login.token, 'Content-Type': 'multipart/form-data' },
		})
		.then((res) => {
			dispatch(updateTicketAction({ id: state.tickets.ticket.id }));
			dispatch(
				addDelayAction(reset(form), [
					() => eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_post_alert') }),
					() => eventsService.triggerEvent('tab'),
				])
			);
		})
		.catch((err) => {
			dispatch(addDelayAction(() => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

export const postCertificateAction = (form) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	const { template, ...values } = state.form[form].values;

	let data = {
		draft: 0,
		template: template,
		values: JSON.stringify(values),
	};

	axios
		.post(state.config.apihost + '/tickets/' + state.tickets.ticket.id + '/certificates', convertToFormData(data), {
			headers: { Authorization: 'Bearer ' + state.login.token, 'Content-Type': 'multipart/form-data' },
		})
		.then((res) => {
			dispatch(updateTicketAction({ id: state.tickets.ticket.id }));
			dispatch(
				addDelayAction(reset(form), [
					() => eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_post_certificate') }),
					() => eventsService.triggerEvent('tab'),
				])
			);
		})
		.catch((err) => {
			dispatch(addDelayAction(() => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

export const previewCertificateAction = (form) => () => {
	const state = store.getState();

	const { template, ...values } = state.form[form].values;

	let data = {
		draft: 1,
		template: template,
		values: JSON.stringify(values),
	};

	axios
		.post(state.config.apihost + '/tickets/' + state.tickets.ticket.id + '/certificates', convertToFormData(data), {
			responseType: 'blob',
			headers: { Accept: 'application/pdf', Authorization: 'Bearer ' + state.login.token, 'Content-Type': 'multipart/form-data' },
		})
		.then((res) => {
			const fileURL = URL.createObjectURL(res.data);
			openPdfFIle(fileURL);
		})
		.catch((err) => {
			eventsService.triggerEvent('alert', { type: 'error', message: err.message });
		});
};

export const postDiagnosesAction = (diagnoses) => (dispatch) => {
	const state = store.getState();

	var diagnosis = { diagnoses_id: JSON.stringify(diagnoses) };

	axios
		.post(state.config.apihost + '/tickets/' + state.tickets.ticket.id + '/diagnoses', convertToFormData(diagnosis), {
			headers: { Authorization: 'Bearer ' + state.login.token, 'Content-Type': 'multipart/form-data' },
		})
		.then((res) => {
			dispatch(updateTicketAction({ id: state.tickets.ticket.id }));
		})
		.catch((err) => {
			eventsService.triggerEvent('alert', { type: 'error', message: err.message });
		});
};

export const saveDiagnosisAction = (diagnosis_to_save) => (dispatch) => {
	dispatch({
		type: SAVE_DIAGNOSIS,
		payload: diagnosis_to_save,
	});
};

export const postSaveCloseTicketAction = (form, close) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	if (close) {
		state.form[form].values.status = 100;
	}

	Object.keys(state.form[form].values).forEach(
		(key) => (state.form[form].values[key] === null || state.form[form].values[key].length === 0) && delete state.form[form].values[key]
	);

	var body = convertToFormData(state.form[form].values);

	axios
		.patch(state.config.apihost + '/tickets/' + state.tickets.ticket.id, body, {
			headers: { Authorization: 'Bearer ' + state.login.token },
		})
		.then((res) => {
			dispatch(updateTicketAction({ id: state.tickets.ticket.id }));
			dispatch(
				addDelayAction(null, [
					() =>
						eventsService.triggerEvent('alert', {
							type: 'success',
							message: close ? translate('alert_post_closeticket') : translate('alert_post_saveticket'),
						}),
					() => eventsService.triggerEvent('tab'),
				])
			);
		})
		.catch((err) => {
			dispatch(addDelayAction(() => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

export const closeTicketAction = () => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	let data = convertToFormData({
		status: 100,
	});

	axios
		.patch(state.config.apihost + '/tickets/' + state.tickets.ticket.id, data, {
			headers: { Authorization: 'Bearer ' + state.login.token },
		})
		.then((res) => {
			dispatch(updateTicketAction({ id: state.tickets.ticket.id }));
			eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_post_closeticket') });
		})
		.catch((err) => {
			eventsService.triggerEvent('alert', { type: 'error', message: err.message });
		});
};

export const claimTicketAction = (ticket_id) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	axios
		.post(state.config.apihost + '/tickets/' + ticket_id + '/claim', null, {
			headers: { Authorization: 'Bearer ' + state.login.token },
		})
		.then((res) => {
			dispatch(fetchTicketAction(ticket_id));
			store.dispatch(push('/case'));

			eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_post_claimed_ticket') });
		})
		.catch((err) => {
			eventsService.triggerEvent('alert', { type: 'error', message: err.message });
		});
};

export const fetchUnreadTicketsAction = () => (dispatch) => {
	const state = store.getState();

	axios
		.get(state.config.apihost + `/tickets/unread`, {
			headers: { Authorization: 'Bearer ' + state.login.token },
		})
		.then((res) => {
			dispatch({
				type: FETCH_UNREAD_TICKETS_DATA,
				payload: res.data,
			});
		})
		.catch((err) => {
			console.log(err);
			console.log('Error fetching data.');
		});
};

export const postShareSelfCareFileAction = (data) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	axios
		.post(state.config.apihost + '/tickets/' + state.tickets.ticket.id + '/attachment', data, {
			headers: { Authorization: 'Bearer ' + state.login.token, 'Content-Type': 'multipart/form-data' },
		})
		.then((res) => {
			dispatch(updateTicketAction({ id: state.tickets.ticket.id }));
			dispatch(
				addDelayAction(null, [
					() => eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_post_shareselfcarefile') }),
					() => eventsService.triggerEvent('tab'),
				])
			);
		})
		.catch((err) => {
			dispatch(addDelayAction(() => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

export const postShareSelfCarePdfFileLibrariesAction = (file) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	axios
		.get(state.config.driveshost + file.link, {
			headers: { Authorization: 'Bearer ' + state.login.token },
		})
		.then((res) => {
			axios
				.get(state.config.driveshost + res.data.content, {
					responseType: 'blob',
					headers: { Authorization: 'Bearer ' + state.login.token },
				})
				.then((blob) => {
					var f = new File([blob.data], file.name, { type: 'application/pdf' });

					var body = new FormData();

					body.append('upload', f);
					body.append('type', 'selfcare');

					axios
						.post(state.config.apihost + '/tickets/' + state.tickets.ticket.id + '/files', body, {
							headers: { Authorization: 'Bearer ' + state.login.token, 'Content-Type': 'multipart/form-data' },
						})
						.then(() => {
							dispatch(updateTicketAction({ id: state.tickets.ticket.id }));
							dispatch(
								addDelayAction(null, [
									() => eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_post_uploadfile') }),
									() => eventsService.triggerEvent('tab'),
								])
							);
						})
						.catch((err) => {
							dispatch(addDelayAction(() => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
						});
				})
				.catch((err) => {
					console.log(err);
					console.log('Error fetching data.');
				});
		})
		.catch((err) => {
			console.log(err);
			console.log('Error fetching data.');
		});
};

export const postShareSelfCareEditableFileLibrariesAction =
	(form, handleClose, draft_id = false) =>
	(dispatch) => {
		const state = store.getState();
		const translate = getTranslate(state.localize);

		const input = document.getElementById('viewer-content');

		domtoimage
			.toPng(input)
			.then(function (dataUrl) {
				const pdf = new jsPDF('p', 'px', 'a4');

				var pdfWidth = pdf.internal.pageSize.getWidth();
				var pdfHeight = pdf.internal.pageSize.getHeight();

				var marginX = 10;

				var maxWidth = pdfWidth - marginX * 2;

				var ratio = pdfWidth / (input.offsetWidth - marginX * 2);

				pdf.addImage(dataUrl, 'PNG', 10, 10, maxWidth, input.offsetHeight * ratio);
				var blob = pdf.output('blob');

				var f = new File([blob], state.form[form].values.name, { type: 'application/pdf' });

				var body = new FormData();

				body.append('upload', f);
				body.append('type', 'selfcare');

				axios
					.post(state.config.apihost + '/tickets/' + state.tickets.ticket.id + '/files', body, {
						headers: { Authorization: 'Bearer ' + state.login.token, 'Content-Type': 'multipart/form-data' },
					})
					.then(() => {
						dispatch(updateTicketAction({ id: state.tickets.ticket.id }));

						if (draft_id) {
							dispatch(deleteLibraryDraftAction(draft_id));
						}

						dispatch(
							addDelayAction(
								() => handleClose(),
								[
									() => eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_post_uploadfile') }),
									() => eventsService.triggerEvent('tab'),
								]
							)
						);
					})
					.catch((err) => {
						dispatch(addDelayAction(() => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
					});
			})
			.catch(function (error) {
				dispatch(addDelayAction(() => eventsService.triggerEvent('alert', { type: 'error', message: error })));
			});
	};

export const updateTicketData = (ticket_id, params) => (dispatch) => {
	const state = store.getState();

	axios
		.patch(state.config.apihost + `/tickets/` + ticket_id, params, {
			headers: { Authorization: 'Bearer ' + state.login.token },
		})
		.then((res) => {
			// dispatch({
			// 	type: UPDATE_TICKET,
			// 	payload: res.data,
			// });
		})
		.catch((error) => {
			console.log(error);
		});
};

export const changeTicketResponsibleAction = (user_id, callback) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	let dataForm = convertToFormData({
		user_id: user_id,
	});

	axios
		.post(state.config.apihost + '/tickets/' + state.tickets.ticket.id + '/responsible', dataForm, {
			headers: { Authorization: 'Bearer ' + state.login.token },
		})
		.then((res) => {
			dispatch(updateTicketAction({ id: state.tickets.ticket.id }));
			callback(res.data);
			dispatch(
				addDelayAction(null, () =>
					eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_change_careteam_responsible') })
				)
			);
		})
		.catch((err) => {
			dispatch(addDelayAction(() => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
			callback(err);
		});
};

export const addExternalPersonAction =
	(form, user = null) =>
	(dispatch) => {
		if (user) {
			dispatch(patchExternalUserAction(form));
		} else {
			dispatch(postExternalUserAction(form));
		}
	};

export const postExternalUserAction = (form) => (dispatch) => {
	const state = store.getState();

	// TODO: conditionally treat birthdate date depending on setting
	if (typeof state.form[form].values.birthdate !== 'undefined') {
		state.form[form].values.birthdate = dateToString(
			new Date(
				state.form[form].values.birthdate.toLocaleString('en-US', {
					year: 'numeric',
					month: 'numeric',
					day: 'numeric',
					hour: 'numeric',
					minute: 'numeric',
					hour12: true,
				})
			)
		);
	}

	let data = {
		ssn: state.form[form].values.ssn,
		name: state.form[form].values.first_name + ' ' + state.form[form].values.last_name,
		first_name: state.form[form].values.first_name,
		last_name: state.form[form].values.last_name,
		email: state.form[form].values.email,
		phone: state.form[form].values.phone,
		roles_id: state.form[form].values.roles_id,
		// TODO: conditionally send gender and birthdate depending on setting
		gender: state.form[form].values.gender,
		birthdate: state.form[form].values.birthdate,
	};

	let dataForm = convertToFormData(data);

	axios
		.post(state.config.apihost + `/users`, dataForm, {
			headers: { Authorization: 'Bearer ' + state.login.token },
		})
		.then((res) => {
			dispatch(addUserToCareTeamAction(res.data.id, state.form[form].values));

			dispatch(addDelayAction(reset(form), [() => eventsService.triggerEvent('tab')]));
		})
		.catch((err) => {
			dispatch(addDelayAction(() => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

export const patchExternalUserAction = (form) => (dispatch) => {
	const state = store.getState();

	let data = {
		email: state.form[form].values.email,
		phone: state.form[form].values.phone,
	};

	let dataForm = convertToFormData(data);

	axios
		.patch(state.config.apihost + `/users/` + state.form[form].values.user_id, dataForm, {
			headers: { Authorization: 'Bearer ' + state.login.token },
		})
		.then((res) => {
			dispatch(addUserToCareTeamAction(res.data.id, state.form[form].values));
			dispatch(addDelayAction(reset(form), [() => eventsService.triggerEvent('tab')]));
		})
		.catch((err) => {
			dispatch(addDelayAction(() => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

export const ticketInviteAction =
	(form, user = null) =>
	(dispatch) => {
		if (user) {
			dispatch(patchTicketInviteUserAction(form));
		} else {
			dispatch(postTicketInviteUserAction(form));
		}
	};

export const postTicketInviteUserAction = (form) => (dispatch) => {
	const state = store.getState();

	// TODO: conditionally treat birthdate date depending on setting
	if (typeof state.form[form].values.birthdate !== 'undefined') {
		state.form[form].values.birthdate = dateToString(
			new Date(
				state.form[form].values.birthdate.toLocaleString('en-US', {
					year: 'numeric',
					month: 'numeric',
					day: 'numeric',
					hour: 'numeric',
					minute: 'numeric',
					hour12: true,
				})
			)
		);
	}

	let data = {
		ssn: state.form[form].values.ssn,
		name: state.form[form].values.first_name + ' ' + state.form[form].values.last_name,
		first_name: state.form[form].values.first_name,
		last_name: state.form[form].values.last_name,
		email: state.form[form].values.email,
		phone: state.form[form].values.phone,
		// TODO: conditionally send gender and birthdate depending on setting
		gender: state.form[form].values.gender,
		birthdate: state.form[form].values.birthdate,
	};

	let dataForm = convertToFormData(data);

	axios
		.post(state.config.apihost + `/users`, dataForm, {
			headers: { Authorization: 'Bearer ' + state.login.token },
		})
		.then((res) => {
			let ticketInviteData = {
				conditions_id: state.form[form].values.conditions_id,
				practitioner_id: state.form[form].values.assign_case_to_me == 1 ? state.users.whoami.id : '0',
				user_id: res.data.id,
			};

			dispatch(postTicketInviteAction(ticketInviteData));
			dispatch(addDelayAction(reset(form)));
		})
		.catch((err) => {
			dispatch(addDelayAction(() => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

export const patchTicketInviteUserAction = (form) => (dispatch) => {
	const state = store.getState();

	let data = {
		email: state.form[form].values.email,
		phone: state.form[form].values.phone,
	};

	let dataForm = convertToFormData(data);

	axios
		.patch(state.config.apihost + `/users/` + state.form[form].values.user_id, dataForm, {
			headers: { Authorization: 'Bearer ' + state.login.token },
		})
		.then((res) => {
			let ticketInviteData = {
				conditions_id: state.form[form].values.conditions_id,
				practitioner_id: state.form[form].values.assign_case_to_me == 1 ? state.users.whoami.id : '0',
				user_id: state.form[form].values.user_id,
			};

			dispatch(postTicketInviteAction(ticketInviteData));
			dispatch(addDelayAction(reset(form)));
		})
		.catch((err) => {
			dispatch(addDelayAction(() => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

export const postTicketInviteAction = (data) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	let dataForm = convertToFormData(data);

	axios
		.post(state.config.apihost + `/tickets/invitation`, dataForm, {
			headers: { Authorization: 'Bearer ' + state.login.token },
		})
		.then((res) => {
			dispatch(
				addDelayAction([...(data.practitioner_id == state.users.whoami.id ? [fetchTicketAction(res.data.id), push('/case')] : [push('/waitingroom')])], () =>
					eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_create_invite') })
				)
			);
		})
		.catch((err) => {
			dispatch(addDelayAction(() => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

export const patchReferenceAction = (form) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	let data = convertToFormData(state.form[form].values);

	axios
		.patch(state.config.apihost + '/tickets/' + state.tickets.ticket.id, data, {
			headers: { Authorization: 'Bearer ' + state.login.token, 'Content-Type': 'multipart/form-data' },
		})
		.then((res) => {
			dispatch(updateTicketAction({ id: state.tickets.ticket.id }));
			dispatch(
				addDelayAction(reset(form), [
					() => eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_patch_reference') }),
					() => eventsService.triggerEvent('tab'),
				])
			);
		})
		.catch((err) => {
			dispatch(addDelayAction(() => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};
