import { Dialog } from '@material-ui/core';
import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import { connect } from 'react-redux';

class Player extends Component {
	render() {
		return (
			<Dialog open={this.props.open} onClose={this.props.onClose} maxWidth={'lg'}>
				<ReactPlayer
					url={this.props.url}
					controls
					playing
					width="100%"
					height="100%"
					config={{
						file: {
							// attributes: { crossorigin: 'use-credentials' },
							hlsOptions: {
								xhrSetup: (xhr, url) => {
									xhr.setRequestHeader(
										'Authorization',
										'Bearer ' + this.props.token
									)
								},
							},
						},
					}}
				/>
			</Dialog>
		);
	}
}

const mapStateToProps = (state) => ({
	token: state.login.token
});

export default connect(mapStateToProps, null)(Player);