import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { IconButton, Typography, Menu, MenuItem, Link, Divider, FormControlLabel, Checkbox } from '@material-ui/core';
import {
	ExitToApp as ExitToAppIcon,
	AccountCircle as AccountCircleIcon,
	List as ListIcon,
	Event as EventIcon,
	PersonAdd as PersonAddIcon,
	Add as AddIcon,
	Dashboard as DashboardIcon,
	Group as GroupIcon,
} from '@material-ui/icons';

export function NavBarMenuMobile({ translate, ...props }) {
	return (
		<Menu
			anchorEl={props.mobileMoreAnchorEl}
			getContentAnchorEl={null}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			id={props.mobileMenuId}
			keepMounted
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			open={props.isMobileMenuOpen}
			onClose={props.handleMobileMenuClose}
		>
			<MenuItem disabled={true}>
				<Typography>{props.username}</Typography>
			</MenuItem>
			<Divider />
			{!props.is_temporary_user && typeof props.settings.feature !== 'undefined' && props.settings.feature.dashboard ? (
				<MenuItem
					onClick={props.handleMobileMenuClose}
					className={'generic-navbar-menu-item ' + (props.navButtonActive === 'dashboard' ? 'generic-navbar-menu-active' : null)}
				>
					<IconButton aria-label="Current user profile" color="primary">
						<DashboardIcon />
					</IconButton>
					<Link
						id="navbar_menu_dashboard"
						component={RouterLink}
						to="/dashboard"
						color="inherit"
						underline="none"
						onClick={() => (!props.prompt.is_dirty ? props.navButtonActiveAction('dashboard') : null)}
					>
						<Typography>{translate('navbar_button_dashboard')}</Typography>
					</Link>
				</MenuItem>
			) : null}
			{!props.is_temporary_user && (
				<MenuItem
					onClick={props.handleMobileMenuClose}
					className={'generic-navbar-menu-item ' + (props.navButtonActive === 'mypages' ? 'generic-navbar-menu-active' : null)}
				>
					<IconButton aria-label="Current user profile" color="primary">
						<AccountCircleIcon />
					</IconButton>
					<Link
						id="navbar_menu_mypages"
						component={RouterLink}
						to="/mypages"
						color="inherit"
						underline="none"
						onClick={() => (!props.prompt.is_dirty ? props.navButtonActiveAction('mypages') : null)}
					>
						<Typography>{translate('navbar_button_mypages')}</Typography>
					</Link>
				</MenuItem>
			)}
			{!props.is_temporary_user && (
				<MenuItem
					onClick={props.handleMobileMenuClose}
					className={'generic-navbar-menu-item ' + (props.navButtonActive === 'mycases' ? 'generic-navbar-menu-active' : null)}
				>
					<IconButton aria-label="Show my cases" color="primary">
						<ListIcon />
					</IconButton>
					<Link
						id="navbar_menu_mycases"
						component={RouterLink}
						to="/mycases"
						color="inherit"
						underline="none"
						onClick={() => (!props.prompt.is_dirty ? props.navButtonActiveAction('mycases') : null)}
					>
						<Typography>{translate('navbar_button_mycases')}</Typography>
					</Link>
				</MenuItem>
			)}
			{!props.is_temporary_user && props.scopes.includes('lists.waitingroom') && (
				<MenuItem
					onClick={props.handleMobileMenuClose}
					className={'generic-navbar-menu-item ' + (props.navButtonActive === 'waitingroom' ? 'generic-navbar-menu-active' : null)}
				>
					<IconButton aria-label="Show my cases" color="primary">
						<ListIcon />
					</IconButton>
					<Link
						id="navbar_menu_waitingroom"
						component={RouterLink}
						to="/waitingroom"
						color="inherit"
						underline="none"
						onClick={() => (!props.prompt.is_dirty ? props.navButtonActiveAction('waitingroom') : null)}
					>
						<Typography>{translate('navbar_button_waitingroom')}</Typography>
					</Link>
				</MenuItem>
			)}
			{!props.is_temporary_user && props.scopes.includes('lists.all_cases') && (
				<MenuItem
					onClick={props.handleMobileMenuClose}
					className={'generic-navbar-menu-item ' + (props.navButtonActive === 'allcases' ? 'generic-navbar-menu-active' : null)}
				>
					<IconButton aria-label="Show my cases" color="primary">
						<ListIcon />
					</IconButton>
					<Link
						id="navbar_menu_allcases"
						component={RouterLink}
						to="/allcases"
						color="inherit"
						underline="none"
						onClick={() => (!props.prompt.is_dirty ? props.navButtonActiveAction('allcases') : null)}
					>
						<Typography>{translate('navbar_button_allcases')}</Typography>
					</Link>
				</MenuItem>
			)}
			{!props.is_temporary_user && props.scopes.includes('customer') && (
				<MenuItem
					onClick={props.handleMobileMenuClose}
					className={'generic-navbar-menu-item ' + (props.navButtonActive === 'conditions' ? 'generic-navbar-menu-active' : null)}
				>
					<IconButton aria-label="Show my cases" color="primary">
						<AddIcon />
					</IconButton>
					<Link
						id="navbar_menu_conditions"
						component={RouterLink}
						to="/conditions"
						color="inherit"
						underline="none"
						onClick={() => (!props.prompt.is_dirty ? props.navButtonActiveAction('conditions') : null)}
					>
						<Typography>{translate('navbar_button_newcase')}</Typography>
					</Link>
				</MenuItem>
			)}
			{!props.is_temporary_user && props.scopes.includes('calendar') && (
				<MenuItem
					onClick={props.handleMobileMenuClose}
					className={'generic-navbar-menu-item ' + (props.navButtonActive === 'mycalendar' ? 'generic-navbar-menu-active' : null)}
				>
					<IconButton aria-label="calendar" color="primary">
						<EventIcon />
					</IconButton>
					<Link
						id="navbar_menu_mycalendar"
						component={RouterLink}
						to="/mycalendar"
						color="inherit"
						underline="none"
						onClick={() => (!props.prompt.is_dirty ? props.navButtonActiveAction('mycalendar') : null)}
					>
						<Typography>{translate('navbar_button_calendar')}</Typography>
					</Link>
				</MenuItem>
			)}

			{!props.is_temporary_user && props.scopes.includes('ticket.invite') && (
				<MenuItem
					onClick={props.handleMobileMenuClose}
					className={'generic-navbar-menu-item ' + (props.navButtonActive === 'ticket_invite' ? 'generic-navbar-menu-active' : null)}
				>
					<IconButton aria-label="invite" color="primary">
						<PersonAddIcon />
					</IconButton>
					<Link
						id="navbar_menu_invite"
						component={RouterLink}
						to="/invite"
						color="inherit"
						underline="none"
						onClick={() => (!props.prompt.is_dirty ? props.navButtonActiveAction('ticket_invite') : null)}
					>
						<Typography>{translate('navbar_button_ticket_invite')}</Typography>
					</Link>
				</MenuItem>
			)}
			{!props.is_temporary_user && typeof props.settings.feature !== 'undefined' && props.settings.feature.activities && (
				<MenuItem
					onClick={props.handleMobileMenuClose}
					className={'generic-navbar-menu-item ' + (props.navButtonActive === 'activities' ? 'generic-navbar-menu-active' : null)}
				>
					<IconButton aria-label="activity" color="primary">
						<GroupIcon />
					</IconButton>
					<Link
						id="navbar_menu_activities"
						component={RouterLink}
						to="/activities"
						color="inherit"
						underline="none"
						onClick={() => (!props.prompt.is_dirty ? props.navButtonActiveAction('activities') : null)}
					>
						<Typography>{translate('navbar_button_activity')}</Typography>
					</Link>
				</MenuItem>
			)}
			{!props.is_temporary_user &&
				typeof props.settings.feature !== 'undefined' &&
				props.settings.feature.libraries &&
				props.scopes.includes('library') && (
					<MenuItem
						onClick={props.handleMobileMenuClose}
						className={'generic-navbar-menu-item ' + (props.navButtonActive === 'library' ? 'generic-navbar-menu-active' : null)}
					>
						<IconButton aria-label="activity" color="primary">
							<GroupIcon />
						</IconButton>
						<Link
							id="navbar_menu_library"
							component={RouterLink}
							to="/library"
							color="inherit"
							underline="none"
							onClick={() => (!props.prompt.is_dirty ? props.navButtonActiveAction('library') : null)}
						>
							<Typography>{translate('navbar_button_library')}</Typography>
						</Link>
					</MenuItem>
				)}
			{!props.is_temporary_user && typeof props.settings.feature !== 'undefined' && props.settings.feature.meetings && (
				<MenuItem
					onClick={props.handleMobileMenuClose}
					className={'generic-navbar-menu-item ' + (props.navButtonActive === 'meeting' ? 'generic-navbar-menu-active' : null)}
				>
					<IconButton aria-label="activity" color="primary">
						<GroupIcon />
					</IconButton>
					<Link
						id="navbar_menu_meeting"
						component={RouterLink}
						to="/meeting"
						color="inherit"
						underline="none"
						onClick={() => (!props.prompt.is_dirty ? props.navButtonActiveAction('meeting') : null)}
					>
						<Typography>{translate('navbar_button_meeting')}</Typography>
					</Link>
				</MenuItem>
			)}
			{/* {props.scopes.includes('practitioner') && (
				<MenuItem>
					<FormControlLabel
						control={
							<Checkbox
								onClick={props.activeAction.bind(this)}
								defaultChecked={props.active === '1' ? true : false}
								value="onduty"
								color="primary"
							/>
						}
						label={translate('navbar_checkbox_active')}
					/>
				</MenuItem>
			)} */}
			<Divider />
			<MenuItem id="navbar_menu_logout" className="generic-navbar-menu-item" onClick={() => props.logout()}>
				<IconButton aria-label="Logout" aria-controls="primary-search-account-menu" aria-haspopup="true" color="primary">
					<ExitToAppIcon />
				</IconButton>
				<Typography>{translate('navbar_button_logout')}</Typography>
			</MenuItem>
		</Menu>
	);
}
