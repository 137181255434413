// export { default as FilesForPatients } from './FilesForPatients';
// export { default as Guidelines } from './Guidelines';
// export { default as MyPages } from './MyPages';
// export { default as MyProfile } from './MyProfile';
// export { default as MyTexts } from './MyTexts';
// export { default as MyCalendar } from './Calendar/MyCalendar';
// export { default as PrivacyPolicy } from './PrivacyPolicy';
// export { default as Appointments } from './Appointments';

import React from 'react';

const FilesForPatients = React.lazy(async () => {
	const [moduleExports] = await Promise.all([import('./FilesForPatients'), new Promise((resolve) => setTimeout(resolve, 1000))]);
	return moduleExports;
});
const Guidelines = React.lazy(async () => {
	const [moduleExports] = await Promise.all([import('./Guidelines'), new Promise((resolve) => setTimeout(resolve, 1000))]);
	return moduleExports;
});
const MyPages = React.lazy(async () => {
	const [moduleExports] = await Promise.all([import('./MyPages'), new Promise((resolve) => setTimeout(resolve, 1000))]);
	return moduleExports;
});
const MyProfile = React.lazy(async () => {
	const [moduleExports] = await Promise.all([import('./MyProfile'), new Promise((resolve) => setTimeout(resolve, 1000))]);
	return moduleExports;
});
const Password = React.lazy(async () => {
	const [moduleExports] = await Promise.all([import('./ChangePassword/Password'), new Promise((resolve) => setTimeout(resolve, 1000))]);
	return moduleExports;
});
const MyTexts = React.lazy(async () => {
	const [moduleExports] = await Promise.all([import('./MyTexts'), new Promise((resolve) => setTimeout(resolve, 1000))]);
	return moduleExports;
});
const MyCalendar = React.lazy(async () => {
	const [moduleExports] = await Promise.all([import('./Calendar/MyCalendar'), new Promise((resolve) => setTimeout(resolve, 1000))]);
	return moduleExports;
});
const PrivacyPolicy = React.lazy(async () => {
	const [moduleExports] = await Promise.all([import('./PrivacyPolicy'), new Promise((resolve) => setTimeout(resolve, 1000))]);
	return moduleExports;
});
const Appointments = React.lazy(async () => {
	const [moduleExports] = await Promise.all([import('./Appointments'), new Promise((resolve) => setTimeout(resolve, 1000))]);
	return moduleExports;
});
const MyDashboard = React.lazy(async () => {
	const [moduleExports] = await Promise.all([import('./MyDashboard'), new Promise((resolve) => setTimeout(resolve, 1000))]);
	return moduleExports;
});

export { FilesForPatients, Guidelines, MyPages, MyProfile, MyTexts, MyCalendar, PrivacyPolicy, Password, Appointments, MyDashboard };
