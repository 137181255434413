
import React from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';

function RenderSingleCheckbox({ input: { onChange, value }, meta: { invalid, touched, error }, ...custom }) {
    return (
        <div>
            <FormControlLabel
                control={
                    <Checkbox
                        value={value}
                        checked={value ? true : false}
                        onChange={(event) => {
                            if (typeof custom.inputdata.onChange !== 'undefined') {
                                custom.inputdata.onChange(event.target.checked);
                            }
                            onChange(event);
                        }}
                    />
                }
                label={custom.inputdata.label}
            />
        </div>
    );
}

export default RenderSingleCheckbox;