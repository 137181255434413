
import React from 'react';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { enUS, sv } from 'date-fns/locale';
const localeMap = {
    'en-US': enUS,
    'sv-SE': sv,
};

function RenderDatePicker({ input: { onChange, value }, meta: { invalid, touched, error }, ...custom }) {
    if (typeof value === 'string') {
        value = value.split(' ')[0];
    }

    return (
        <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            locale={Object.keys(localeMap).includes(custom.locale) ? localeMap[custom.locale] : localeMap['en-US']}
        >
            <DatePicker
                id={custom.inputdata.id}
                name={custom.inputdata.name}
                format={'yyyy-MM-dd'}
                label={custom.inputdata.label}
                fullWidth
                inputVariant="outlined"
                onChange={(date) => {
                    if (typeof custom.inputdata.onChange !== 'undefined') {
                        custom.inputdata.onChange(date);
                    }
                    onChange(date);
                }}
                value={value === '' ? null : value}
                disableFuture={custom.inputdata.disableFuture}
                disablePast={custom.inputdata.disablePast}
                disabled={custom.inputdata.readonly}
                error={custom.inputdata.validate ? touched && invalid : false}
                helperText={custom.inputdata.validate ? touched && error : false}
                openTo={custom.inputdata.openTo}
            />
        </MuiPickersUtilsProvider>
    );
}

export default RenderDatePicker;