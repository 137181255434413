import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Box, Grid, Typography } from '@material-ui/core';
import { isMobileSize } from '../helpers/functions/functions';

import PlaceholderIMG from '../assets/images/placeholder.svg';

class NotFound extends Component {
	render() {
		const { translate } = this.props;

		return (
			<Grid container component="main" justify="center" className="login-main login-content-left-image">
				<Grid item xs={12} lg={12}>
					<Box height="100%" className="login-content-left-title" p={1} href="/">
						<Link component={RouterLink} to={'/'} color="inherit" underline="none">
							<img
								src={
									typeof this.props.settings.site !== 'undefined' &&
									this.props.settings.site.files[isMobileSize() ? 'login_mobile_image' : 'login_image']
										? this.props.config.apihost +
										  '/configuration/files/' +
										  this.props.settings.site.files[isMobileSize() ? 'login_mobile_image' : 'login_image'].uuid
										: PlaceholderIMG
								}
								alt="Login logo"
								style={{ width: '40%' }}
							/>
						</Link>
					</Box>
				</Grid>
				<Grid item xs={6} lg={6}>
					<Typography color="primary" paragraph={true} component="div" variant="h1">
						{translate('notfound_page_title')}
					</Typography>
					<Typography color={'inherit'} paragraph={true} component="div" variant="h5">
						{translate('notfound_page_subtitle')}
					</Typography>
					<Link component={RouterLink} to={'/'} variant="body2">
						&crarr; {translate('notfound_page_button')}
					</Link>
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => ({
	translate: getTranslate(state.localize),
	settings: state.settings,
	config: state.config,
});

export default connect(mapStateToProps)(NotFound);
