import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Button } from '@material-ui/core';

export function NavBarLinks({ translate, ...props }) {
	return (
		<div className="generic-navbar-section-desktop">
			{!props.is_temporary_user && typeof props.settings.feature !== 'undefined' && props.settings.feature.dashboard ? (
				<Link
					id="navbar_link_dashboard"
					data-role={props.scopes.includes('api.practitioner') ? 'practitioner' : 'customer'}
					component={RouterLink}
					to="/dashboard"
					color="primary"
					underline="none"
					onClick={() => (!props.prompt.is_dirty ? props.navButtonActiveAction('dashboard') : null)}
				>
					<Button
						className={
							props.navButtonActive === 'dashboard' ? 'generic-navbar-section-active navbar-links-button' : 'navbar-links-button'
						}
						color="inherit"
					>
						{translate('navbar_button_dashboard')}
					</Button>
				</Link>
			) : null}
			{!props.is_temporary_user && <Link
				id="navbar_link_mypages"
				component={RouterLink}
				to="/mypages"
				color="primary"
				underline="none"
				onClick={() => (!props.prompt.is_dirty ? props.navButtonActiveAction('mypages') : null)}
			>
				<Button
					className={props.navButtonActive === 'mypages' ? 'generic-navbar-section-active navbar-links-button' : 'navbar-links-button'}
					color="inherit"
				>
					{translate('navbar_button_mypages')}
				</Button>
			</Link>}
			{!props.is_temporary_user && <Link
				id="navbar_link_mycases"
				component={RouterLink}
				to="/mycases"
				color="primary"
				underline="none"
				onClick={() => (!props.prompt.is_dirty ? props.navButtonActiveAction('mycases') : null)}
			>
				<Button
					className={props.navButtonActive === 'mycases' ? 'generic-navbar-section-active navbar-links-button' : 'navbar-links-button'}
					color="inherit"
				>
					{translate('navbar_button_mycases')}
				</Button>
			</Link>}
			{!props.is_temporary_user && props.scopes.includes('lists.waitingroom') && (
				<Link
					id="navbar_link_waitingroom"
					component={RouterLink}
					to="/waitingroom"
					color="primary"
					underline="none"
					onClick={() => (!props.prompt.is_dirty ? props.navButtonActiveAction('waitingroom') : null)}
				>
					<Button
						className={
							props.navButtonActive === 'waitingroom' ? 'generic-navbar-section-active navbar-links-button' : 'navbar-links-button'
						}
						color="inherit"
					>
						{translate('navbar_button_waitingroom')}
					</Button>
				</Link>
			)}
			{!props.is_temporary_user && props.scopes.includes('lists.all_cases') && (
				<Link
					id="navbar_link_allcases"
					component={RouterLink}
					to="/allcases"
					color="primary"
					underline="none"
					onClick={() => (!props.prompt.is_dirty ? props.navButtonActiveAction('allcases') : null)}
				>
					<Button
						className={props.navButtonActive === 'allcases' ? 'generic-navbar-section-active navbar-links-button' : 'navbar-links-button'}
						color="inherit"
					>
						{translate('navbar_button_allcases')}
					</Button>
				</Link>
			)}
			{!props.is_temporary_user && props.scopes.includes('customer') && (
				<Link
					id="navbar_link_conditions"
					component={RouterLink}
					to="/conditions"
					color="primary"
					underline="none"
					onClick={() => (!props.prompt.is_dirty ? props.navButtonActiveAction('conditions') : null)}
				>
					<Button
						className={
							props.navButtonActive === 'conditions' ? 'generic-navbar-section-active navbar-links-button' : 'navbar-links-button'
						}
						color="inherit"
					>
						{translate('navbar_button_newcase')}
					</Button>
				</Link>
			)}
			{!props.is_temporary_user && props.scopes.includes('calendar') && (
				<Link
					id="navbar_link_mycalendar"
					component={RouterLink}
					to="/mycalendar"
					color="primary"
					underline="none"
					onClick={() => (!props.prompt.is_dirty ? props.navButtonActiveAction('mycalendar') : null)}
				>
					<Button
						className={
							props.navButtonActive === 'mycalendar' ? 'generic-navbar-section-active navbar-links-button' : 'navbar-links-button'
						}
						color="inherit"
					>
						{translate('navbar_button_calendar')}
					</Button>
				</Link>
			)}
			{!props.is_temporary_user && props.scopes.includes('ticket.invite') && (
				<Link
					id="navbar_link_invite"
					component={RouterLink}
					to="/invite"
					color="primary"
					underline="none"
					onClick={() => (!props.prompt.is_dirty ? props.navButtonActiveAction('ticket_invite') : null)}
				>
					<Button
						className={
							props.navButtonActive === 'ticket_invite' ? 'generic-navbar-section-active navbar-links-button' : 'navbar-links-button'
						}
						color="inherit"
					>
						{translate('navbar_button_ticket_invite')}
					</Button>
				</Link>
			)}
			{!props.is_temporary_user && typeof props.settings.feature !== 'undefined' && props.settings.feature.activities && (
				<Link
					id="navbar_link_activities"
					component={RouterLink}
					to="/activities"
					color="primary"
					underline="none"
					onClick={() => (!props.prompt.is_dirty ? props.navButtonActiveAction('activities') : null)}
				>
					<Button
						className={
							props.navButtonActive === 'activities' ? 'generic-navbar-section-active navbar-links-button' : 'navbar-links-button'
						}
						color="inherit"
					>
						{translate('navbar_button_activity')}
					</Button>
				</Link>
			)}
			{!props.is_temporary_user && typeof props.settings.feature !== 'undefined' && props.settings.feature.libraries && props.scopes.includes('library') && (
				<Link
					id="navbar_link_library"
					component={RouterLink}
					to="/library"
					color="primary"
					underline="none"
					onClick={() => (!props.prompt.is_dirty ? props.navButtonActiveAction('library') : null)}
				>
					<Button
						className={props.navButtonActive === 'library' ? 'generic-navbar-section-active navbar-links-button' : 'navbar-links-button'}
						color="inherit"
					>
						{translate('navbar_button_library')}
					</Button>
				</Link>
			)}
			)
		</div>
	);
}
