import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField, FormHelperText } from '@material-ui/core';

function RenderAutocomplete({ input, meta: { invalid, touched, error }, ...custom }) {
    return (
        <div>
            <Autocomplete
                className={custom.inputdata.validate && touched && invalid ? 'generic-form-autocomplete-error' : ''}
                multiple={custom.inputdata.multiple}
				id={custom.inputdata.id}
				name={custom.inputdata.name}
                options={custom.inputdata.options}
                groupBy={custom.inputdata.groupby}
                getOptionLabel={(option) => option.label}
                onInputChange={(event, value) => input.onChange(value)}
				blurOnSelect={true}
                defaultValue={
                    custom.inputdata.multiple
                        ? []
                        : {
                            label: input.value,
                            value: input.value,
                        }
                }
                filterSelectedOptions={true}
                renderInput={(params) => {
                    return (
                        <TextField
                            label={custom.inputdata.label}
							onChange={(event, value) => {
								input.onChange(value);
								if (typeof custom.inputdata.onChange !== 'undefined') {
									custom.inputdata.onChange(value);
								}
							}}
                            fullWidth
                            variant="outlined"
                            disabled={custom.inputdata.readonly}
							value={params.inputProps.value}
                            {...custom}
                            {...params}
                        />
                    );
                }}
            />
            {custom.inputdata.validate && touched && invalid ? (
                <FormHelperText className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">{error}</FormHelperText>
            ) : null}
        </div>
    );
}

export default RenderAutocomplete;
