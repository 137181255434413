import { SET_CHANNEL, SET_CHANNEL_HISTORY, SEND_CHANNEL_MESSAGE, FILTER_CHANNEL_MESSAGES, CLEAR_CHANNEL } from '../actions/types';
import { WEB_SOCKET_CHANNELS } from '../../../helpers/webSocketChannels';

const initialState = WEB_SOCKET_CHANNELS.reduce((result, item, index, array) => {
	result[item] = {
		channel: '',
		messages: [],
		connection_date: null,
	};
	return result;
}, {});

export default function (state = initialState, action) {
	switch (action.type) {
		case SET_CHANNEL:
			return {
				...state,
				[action.payload.channel_name]: { ...action.payload.data },
			};
		case SET_CHANNEL_HISTORY:
			return {
				...state,
				[action.payload.channel_name]: {
					...state[action.payload.channel_name],
					messages: [...state[action.payload.channel_name].messages, ...action.payload.data.messages],
				},
			};
		case SEND_CHANNEL_MESSAGE:
			return {
				...state,
				[action.payload.channel_name]: {
					...state[action.payload.channel_name],
					messages: [...state[action.payload.channel_name].messages, action.payload.message],
				},
			};
		case FILTER_CHANNEL_MESSAGES:
			return {
				...state,
				[action.payload.channel_name]: {
					...state[action.payload.channel_name],
					messages: state[action.payload.channel_name].messages.filter(
						(message) => !action.payload.messages_to_remove.some((message_to_remove) => message.id == message_to_remove.id)
					),
				},
			};
		case CLEAR_CHANNEL:
			return {
				...state,
				[action.payload.channel_name]: initialState[action.payload.channel_name],
			};
		default:
			return state;
	}
}
