import { SET_CHANNEL, SET_CHANNEL_HISTORY, SEND_CHANNEL_MESSAGE, FILTER_CHANNEL_MESSAGES, CLEAR_CHANNEL } from '../actions/types';

export const setChannelAction = (channel_name, channel, messages, connection_date) => (dispatch) => {
	dispatch({
		type: SET_CHANNEL,
		payload: {
			channel_name,
			data: { channel, messages, connection_date },
		},
	});
};

export const setChannelHistoryAction = (channel_name, messages) => (dispatch) => {
	dispatch({
		type: SET_CHANNEL_HISTORY,
		payload: {
			channel_name,
			data: { messages },
		},
	});
};

export const sendChannelMessageAction = (channel_name, message) => (dispatch) => {
	dispatch({
		type: SEND_CHANNEL_MESSAGE,
		payload: {
			channel_name,
			message,
		},
	});
};

export const filterChannelMessagesAction = (channel_name, messages_to_remove) => (dispatch) => {
	dispatch({
		type: FILTER_CHANNEL_MESSAGES,
		payload: { channel_name, messages_to_remove },
	});
};

export const clearChannelAction = (channel_name) => (dispatch) => {
	dispatch({
		type: CLEAR_CHANNEL,
		payload: { channel_name },
	});
};
