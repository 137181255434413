import {
	VIDEO_TOGGLE_STREAM_ID,
	VIDEO_TOGGLE_PUB,
	VIDEO_SOURCE_PUB,
	AUDIO_TOGGLE_PUB,
	ERROR_MESSAGE_PUB,
	TOGGLE_SESSION,
	TOGGLE_PRECALL_SESSION,
	CLOSE_VIDEO_SESSION,
	CAMERA_POSITION_PUB,
	DEFAULT_SETTINGS
} from '../actions/types';

const initialState = {
	error: null,
	audio: true,
	video: true,
	video_source: 'camera',
	camera_position: 'front',
	session: false,
	tickets_id: null,
	session_id: null,
	patient_token: null,
	practitioner_token: null,
	token: null,
	apiKey: null,
	timestamp: null,
	stream_id: null,

	precall_api_key: null,
	precall_session_id: null,
	precall_token: null,

	name: null,
	video_source_device_id: null,
	audio_source_device_id: null,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case VIDEO_TOGGLE_STREAM_ID:
			return {
				...state,
				stream_id: action.payload,
			};
		case VIDEO_TOGGLE_PUB:
			return {
				...state,
				video: action.payload,
			};
		case VIDEO_SOURCE_PUB:
			return {
				...state,
				video_source: action.payload,
			};
		case CAMERA_POSITION_PUB:
			return {
				...state,
				camera_position: action.payload,
			};
		case AUDIO_TOGGLE_PUB:
			return {
				...state,
				audio: action.payload,
			};
		case ERROR_MESSAGE_PUB:
			return {
				...state,
				error: action.payload,
			};
		case TOGGLE_SESSION:
			return {
				...state,
				...action.payload,
			};
		case TOGGLE_PRECALL_SESSION:
			return {
				...state,
				...action.payload,
			};
		case CLOSE_VIDEO_SESSION:
			return {
				...initialState,
			};
		case DEFAULT_SETTINGS:
			return {
				...state,
				...action.payload
			};
		default:
			return state;
	}
}
