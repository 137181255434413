const mycalendar = (theme) => {
	const meetingColor = 'green';
	const followupColor = 'blue';
	const activityColor = 'orange';
	const appointmentColor = 'red';
	return {
		// '.MuiLink-button': {
		// 	border: 'unset',
		// 	padding: '6px 16px',
		// 	backgroundColor: '#e0e0e0',
		// },
		'.mycalendar-newevent-button-close': {
			float: 'right',
		},
		'.date-in-past': {
			width: '14.3%',
			background: '#ccc',
			borderRight: 'solid 1px #fff',
		},
		'.special-day': {
			width: '14.3%',
			background: 'bisque',
			borderRight: 'solid 1px #fff',
		},
		'.unavailable-class': {
			backgroundColor: '#e6e6e6',
		},
		'.rbc-event': {
			backgroundColor: theme.palette.primary.main,
		},
		'.rbc-event.rbc-selected': {
			backgroundColor: theme.palette.primary.main,
			opacity: '80%',
		},
		'.calendar-toolbar-grid-item': {
			display: 'flex',
		},
		'.calendar-toolbar-grid-item-label': {
			justifyContent: 'space-evenly',
		},
		'.calendar-toolbar-grid-item-buttongroup': {
			justifyContent: 'flex-end',
		},
		'.rbc-time-header-content > .rbc-row.rbc-row-resource': {
			borderBottom: '1px solid ' + theme.palette.grey[400],
			backgroundColor: theme.palette.grey[100],
		},
		'.availability-calendar-button': {
			marginLeft: theme.spacing(2),
		},
		'.rbc-allday-cell': {
			display: 'none',
		},
		'.rbc-time-view .rbc-header': {
			borderBottom: 'none',
		},
		'.calendar-event-preview': {
			backgroundColor: 'red',
			border: '1px solid ' + theme.palette.grey[400] + '!important',
			opacity: '50%',
		},
		'div.calendar-event-appointment': {
			backgroundColor: appointmentColor,
			border: '1px solid ' + theme.palette.grey[400] + '!important',
			opacity: '50%',
		},
		'.rbc-event.rbc-selected.calendar-event-appointment': {
			backgroundColor: appointmentColor,
			opacity: '40%',
		},
		'div.calendar-event-followup': {
			backgroundColor: followupColor,
			border: '1px solid ' + theme.palette.grey[400] + '!important',
			opacity: '50%',
		},

		'.rbc-event.calendar-event-followup.rbc-selected': {
			backgroundColor: followupColor,
			opacity: '40%',
		},
		'div.calendar-event-meetings': {
			backgroundColor: meetingColor,
			border: '1px solid ' + theme.palette.grey[400] + '!important',
			opacity: '50%',
		},
		'div.calendar-event-activity': {
			backgroundColor: activityColor,
			border: '1px solid ' + theme.palette.grey[400] + '!important',
			opacity: '50%',
		},
		'.rbc-event.calendar-event-meetings.rbc-selected': {
			backgroundColor: meetingColor,
			opacity: '40%',
		},
		'.rbc-agenda-view': {
			fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
		},
		'.appointment-toolbar-grid-container': {
			marginBottom: theme.spacing(1),
		},

		'.appointment-toolbar-grid-item': {
			display: 'flex',
			[theme.breakpoints.down('xs')]: {
				display: 'block',
				textAlign: 'center',
			},
		},
		'.appointment-toolbar-grid-item-label': {
			justifyContent: 'flex-end',
			[theme.breakpoints.down('xs')]: {
				display: 'block',
				textAlign: 'center',
			},
		},
		'.calendar-box-max-width': {
			width: '100%',
		},
		'.calendar-event-legend-event': {
			color: 'blue',
			opacity: '80%',
		},
		'.calendar-event-legend-appointment': {
			color: appointmentColor,
			opacity: '50%',
		},
		'.calendar-event-legend-followup': {
			color: followupColor,
			opacity: '50%',
		},
		'.calendar-event-legend-meeting': {
			color: meetingColor,
			opacity: '50%',
		},
		'.calendar-event-legend-activity': {
			color: activityColor,
			opacity: '50%',
		},
		'.calendar-button-activity': {
			marginTop: theme.spacing(2),
		},
		'.event-form-info': {
			marginBottom: theme.spacing(2),
		},
	};
};

export default mycalendar;
