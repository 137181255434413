import React from 'react';
import { Typography } from '@material-ui/core';

import ImageDisplay from '../ImageDisplay';

function RenderImageUpload({ input, meta, ...custom }) {
	return (
		<div>
			<Typography id="continuous-slider" gutterBottom>
				{custom.inputdata.label}
			</Typography>
			<ImageDisplay
				onRef={custom.onRef}
				id={custom.inputdata.id}
				accept={custom.inputdata.accept ? custom.inputdata.accept : "image/*"}
				name={custom.inputdata.name}
				onChange={(value) => {
					input.onChange(value);
					if (typeof custom.inputdata.onChange !== 'undefined') {
						custom.inputdata.onChange(value);
					}
				}}
				initialFiles={custom.inputdata.initialFiles}
				limitFiles={custom.inputdata.limitFiles}
				uploadEnabled={typeof custom.inputdata.uploadEnabled === 'undefined' || custom.inputdata.uploadEnabled ? true : false}
			/>
		</div>
	);
}

export default RenderImageUpload;
