import React, { Component } from 'react';
import { withLocalize, addTranslation } from 'react-localize-redux';
import { renderToStaticMarkup } from 'react-dom/server';
import { SnackbarProvider } from 'notistack';
import Routes from './Routes';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import { fetchTranslationsAction } from './actions/translationsActions';
import { fetchSettingsAction, addSettingsAction, handleTranslationsAction } from './actions/settingsActions';
import { handleLoadingAction, displayModalAction } from './components/Generic/actions/genericActions';
import { setPromptDialogIsDirty } from './components/Generic/actions/promptActions';
import { withRouter } from 'react-router-dom';
import ReactRouterPause from '@allpro/react-router-pause';
import PromptDialog from './components/Generic/PromptDialog';
import FullsizeLoading from './components/Generic/FullsizeLoading';
import CssBaseline from '@material-ui/core/CssBaseline';
import { logoutAction } from './components/Login/actions/loginActions';

// Our theme provider
import { ThemeProvider } from '@material-ui/styles';
import { theme } from './assets/Theme';

const onMissingTranslation = ({ translationId, languageCode }) => {
	return `${translationId}`;
};

class Main extends Component {
	constructor(props) {
		super(props);

		if (props.location.search.length !== 0) {
			let search = props.location.search.substring(1);

			search = search.split('&');

			let params = {};

			search.forEach((param) => {
				param = param.split('=');
				Object.assign(params, { [param[0]]: param[1] });
			});

			if (params.conditions || params.language) {
				props.addSettingsAction(params);
			} else {
				props.replace(props.location.pathname);
			}
		}

		this.state = { showDialog: false, showDialogLogoutPrompt: false };
		this.navigation = null;

		if (typeof props.settings.site !== 'undefined' && props.settings.site.available_languages) {
			var codes = props.settings.site.available_languages.reduce((array, object) => array.concat(object.code), []);
		}

		this.props.initialize({
			languages: props.settings.site.available_languages,
			options: {
				onMissingTranslation,
				renderToStaticMarkup,
				renderInnerHtml: true,
				defaultLanguage:
					props.settings.params !== null && props.settings.params.language && codes.includes(props.settings.params.language)
						? props.settings.params.language
						: props.user && props.user.language.length > 0
						? props.user.language
						: props.settings.site.default_language,
			},
		});

		if (this.props.login.login && this.props.login.token.length > 0) {
			this.props.handleTranslationsAction();
			this.props.fetchTranslationsAction(this.props.user.language ? this.props.user.language : props.settings.site.default_language);
		} else {
			this.props.handleTranslationsAction();
		}

		if (typeof props.settings.site !== 'undefined' && props.settings.site.files.favicon) {
			const favElement = document.getElementById('favicon');

			favElement.href = props.config.apihost + '/configuration/files/' + props.settings.site.files.favicon.uuid;
		}

		if (typeof props.settings.site !== 'undefined' && props.settings.site.page_title) {
			document.title = props.settings.site.page_title;
		}
	}

	componentDidMount() {
		window.scrollTo(0, 0);

		if (
			typeof this.props.settings.feature !== 'undefined' &&
			this.props.settings.feature.matomo &&
			typeof this.props.settings.matomo !== 'undefined' &&
			this.props.settings.matomo.container
		) {
			this.setupMatomoConnection();
		}
	}

	componentDidUpdate() {
		if (!this.props.lightbox.open) {
			Object.assign(document.body.style, { overflow: 'auto', margin: '0px', paddingRight: '0px' });
		}

		if (this.state.showDialog && !this.props.login.login) {
			this.closeDialog();
		}

		if (
			this.props.login.login &&
			typeof this.props.settings.feature !== 'undefined' &&
			this.props.settings.feature.matomo &&
			typeof this.props.settings.matomo !== 'undefined' &&
			this.props.settings.matomo.container
		) {
			var _mtm = (window._mtm = window._mtm || []);
			_mtm.push({ role: this.props.user.role });
		}
	}

	// INFO set up matomo connection with the matomo host from confg and container from settings
	setupMatomoConnection = () => {
		var _paq = (window._paq = window._paq || []);
		_paq.push(['trackPageView']);
		_paq.push(['enableLinkTracking']);
		let config = this.props.config; // cache the this
		let container = this.props.settings.matomo.container; // cache the this
		(function () {
			var u = config.matomohost;
			_paq.push(['setTrackerUrl', u + 'matomo.php']);
			_paq.push(['setSiteId', '2']);
			var d = document,
				g = d.createElement('script'),
				s = d.getElementsByTagName('script')[0];
			g.type = 'text/javascript';
			g.async = true;
			g.src = u + 'matomo.js';
			s.parentNode.insertBefore(g, s);
		})();

		var _mtm = (window._mtm = window._mtm || []);
		_mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' });
		var d = document,
			g = d.createElement('script'),
			s = d.getElementsByTagName('script')[0];
		g.type = 'text/javascript';
		g.async = true;
		g.src = config.matomohost + 'js/container_' + container + '.js';
		s.parentNode.insertBefore(g, s);
	};

	handleNavigationAttempt = (navigation, location, action) => {
		this.navigation = navigation;

		// Implement path_not_allowed behavior:
		// if (this.props.path_not_allowed !== null && this.props.path_not_allowed.some(v => location.pathname.includes(v)))

		if (this.props.is_dirty) {
			if (action === 'POP') {
				this.setState({ showDialog: true });
				return null;
			} else if (this.props.path_allowed !== null && !this.props.path_allowed.some((v) => location.pathname.includes(v))) {
				this.setState({ showDialog: true });
				return null;
			} else {
				return true;
			}
		} else if (this.props.login.login) {
			if (action === 'POP' && location.pathname == '/login') {
				this.setState({ showDialogLogoutPrompt: true });
				return null;
			} else {
				return true;
			}
		}

		// Return null to 'pause' and save the route so can 'resume'
	};

	closeDialog = () => {
		this.setState({ showDialog: false });
	};

	closeLogoutDialog = () => {
		this.setState({ showDialogLogoutPrompt: false });
	};

	handleStay = () => {
		this.closeDialog();
		this.navigation.cancel();
	};

	handleStayLogout = () => {
		this.closeLogoutDialog();
		this.navigation.cancel();
	};

	handleLeave = () => {
		this.closeDialog();

		this.navigation.resume();
		this.props.setPromptDialogIsDirty({ is_dirty: false });

		if (this.props.leave_action !== null && typeof this.props.leave_action == 'function') {
			this.props.leave_action();
		}
	};

	handleLeaveLogout = () => {
		this.closeLogoutDialog();

		this.navigation.resume();
		this.props.logoutAction();
	};

	render() {
		// const Routes = React.lazy(() => import('./Routes'));

		return (
			<ThemeProvider theme={theme(this.props.settings, this.props.client)}>
				<CssBaseline />
				<ReactRouterPause handler={this.handleNavigationAttempt} when={this.props.is_dirty || this.props.login.login} />
				{/* <ReactRouterPause handler={this.handleNavigationLogoutAttempt} when={this.props.login.login} /> */}
				<PromptDialog
					open={this.state.showDialog}
					cancel={this.handleStay}
					resume={this.handleLeave}
					back_label={this.props.back_label}
					next_label={this.props.next_label}
				/>
				<PromptDialog
					open={this.state.showDialogLogoutPrompt}
					cancel={this.handleStayLogout}
					resume={this.handleLeaveLogout}
					back_label={'generic_button_stay'}
					next_label={'generic_button_logout'}
					prompt_dialog_title={'prompt_logout_dialog_title'}
					prompt_dialog_description={'prompt_logout_dialog_description'}
				/>
				<SnackbarProvider maxSnack={10} classes={{ variantInfo: 'snackbar-variant-info' }}>
					{this.props.splash_screen ? <FullsizeLoading /> : <Routes settings={this.props.settings} config={this.props.config} />}
				</SnackbarProvider>
			</ThemeProvider>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.users.whoami,
	is_dirty: state.prompt.is_dirty,
	path_allowed: state.prompt.path_allowed,
	path_not_allowed: state.prompt.path_not_allowed,
	leave_action: state.prompt.leave_action,
	back_label: state.prompt.back_label,
	next_label: state.prompt.next_label,
	lightbox: state.generic.lightbox,
	splash_screen: state.generic.splash_screen,
	login: state.login,
	settings: state.settings,
	config: state.config,
	client: state.login.client,
});

export default connect(mapStateToProps, {
	fetchTranslationsAction,
	addTranslation,
	handleLoadingAction,
	displayModalAction,
	setPromptDialogIsDirty,
	fetchSettingsAction,
	handleTranslationsAction,
	addSettingsAction,
	replace,
	logoutAction,
})(withLocalize(withRouter(Main)));
