import React from 'react';
import { RadioGroup, Radio, FormHelperText, FormControlLabel, FormControl, FormLabel } from '@material-ui/core';

function RenderRadioGroup({ input, meta: { invalid, touched, error }, ...custom }) {
	return (
		<FormControl component="fieldset" error={touched && error}>
			<FormLabel component="legend">{custom.inputdata.label}</FormLabel>
			<RadioGroup
				{...input}
				id={custom.inputdata.id}
				name={custom.inputdata.name}
				onChange={(event) => {
					input.onChange(event);
					if (typeof custom.inputdata.onChange !== 'undefined') {
						custom.inputdata.onChange(event.target.value);
					}
				}}
				{...custom}
			>
				
				{custom.inputdata.options.map((option) => (
					<FormControlLabel key={option.value} value={option.value} control={<Radio />} label={option.label} disabled={option.readonly} />
				))}
			</RadioGroup>
			<FormHelperText>{custom.inputdata.validate ? touched && error : false}</FormHelperText>
		</FormControl>
	);
}

export default RenderRadioGroup;