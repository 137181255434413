const table = (theme) => {
	return {
		'.activity-events-container': {
            backgroundColor: '#e6e6e6',
            borderRadius: theme.spacing(0.5),
            marginTop: theme.spacing(1)
        },
        '.activity-locked-form-container': {
            opacity: '70%'
        },
        '.activity-events-list-top': {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(1),
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
        },
        '.activity-events-list-middle': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
        },
        '.activity-events-list-bottom': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(2),
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
        },
        '.activity-events-list-button-close': {
			float: 'right',
			right: theme.spacing(2),
			top: theme.spacing(2)
		},
        '.activity-event-meeting-content-back-botton': {
			marginTop: theme.spacing(4),
			marginBottom: theme.spacing(2),
		},
	};
};

export default table;
