import {
	LOGIN,
	LOGIN_BANKID,
	BANKID_PROGRESS,
	LOGIN_NETID,
	NETID_PROGRESS,
	REFRESH_TOKEN,
	ADBLOCK_WARNING,
	SELECT_CLIENT,
	BANKID_QRCODE,
	RESET_TOKEN,
	LOGIN_SELF_REGISTRATION,
	LOGIN_CONFIRMED,
} from '../actions/types';

const initialState = {
	login: false,
	adblock_warning: false,
	token: '',
	refresh_token: '',
	expires_in: null,
	expire_date_time: null,
	bankid_progress: false,
	netid_progress: false,
	netid_check_tries: 0,
	session_id: '',
	autostarttoken: '',
	client: null,
	platform: null,
	reset_token: '',
	self_registration: false,
	confirmed: true,
	email_confirm: '',
};

export default function (state = initialState, action) {
	switch (action.type) {
		case LOGIN:
			return {
				...state,
				login: action.payload.login,
				token: action.payload.token,
				refresh_token: action.payload.refresh_token,
				expires_in: action.payload.expires_in,
				expire_date_time: action.payload.expire_date_time,
				confirmed: action.payload.confirmed,
				email_confirm:action.payload.email_confirm,
				...(typeof action.payload.self_registration !== 'undefined' && { self_registration: action.payload.self_registration }),
				...(typeof action.payload.client !== 'undefined' && { client: action.payload.client }),
				...(typeof action.payload.platform !== 'undefined' && { platform: action.payload.platform }),
			};
		case LOGIN_BANKID:
			return {
				...state,
				login: action.payload.login,
				token: action.payload.token,
				bankid_progress: action.payload.progress,
				refresh_token: action.payload.refresh_token,
				expires_in: action.payload.expires_in,
				expire_date_time: action.payload.expire_date_time,
				session_id: '',
				confirmed: action.payload.confirmed,
			};
		case BANKID_PROGRESS:
			return {
				...state,
				bankid_progress: action.payload.bankid_progress,
				session_id: action.payload.session_id,
			};
		case BANKID_QRCODE:
			return {
				...state,
				session_id: action.payload.session_id,
				autostarttoken: action.payload.autostarttoken,
			};
		case LOGIN_NETID:
			return {
				...state,
				login: action.payload.login,
				token: action.payload.token,
				netid_progress: action.payload.progress,
				netid_check_tries: 0,
				session_id: '',
			};
		case NETID_PROGRESS:
			state.netid_check_tries++;
			return {
				...state,
				netid_progress: action.payload.netid_progress,
				session_id: action.payload.session_id,
				netid_check_tries: action.payload.netid_progress ? state.netid_check_tries++ : 0,
			};
		case REFRESH_TOKEN:
			return {
				...state,
				login: action.payload.login,
				token: action.payload.token,
				refresh_token: action.payload.refresh_token,
				expires_in: action.payload.expires_in,
				expire_date_time: action.payload.expire_date_time,
			};

		case ADBLOCK_WARNING:
			return {
				...state,
				adblock_warning: action.payload.adblock_warning,
			};
		case SELECT_CLIENT:
			return {
				...state,
				client: action.payload.client,
				platform: action.payload.platform,
			};
		case RESET_TOKEN:
			return {
				...state,
				reset_token: action.payload,
			};
		case LOGIN_SELF_REGISTRATION:
			return {
				...state,
				self_registration: action.payload.self_registration,
				email_confirm:action.payload.email,
			};
		case LOGIN_CONFIRMED:
			return {
				...state,
				confirmed: action.payload.confirmed,
				email_confirm:action.payload.email
			};
		default:
			return state;
	}
}
