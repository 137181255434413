
import React from 'react';
import { FormHelperText } from '@material-ui/core';

import { FunctionMultiAutocomplete } from './';

function RenderMultiAutocomplete({ input, meta: { invalid, touched, error }, ...custom }) {
    return (
        <div>
            <FunctionMultiAutocomplete initialValue={custom.inputdata.initialvalue} options={custom.inputdata.options} customProps={custom} input={input} />
            {custom.inputdata.validate && touched && invalid ? (
                <FormHelperText className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">{error}</FormHelperText>
            ) : null}
        </div>
    );
}

export default RenderMultiAutocomplete;