import React, { Component } from 'react';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Form } from '../../../Generic';
import { push } from 'connected-react-router';
import { sendRequestCodeAction, sendValidateCodeAction } from '../../actions/usersActions';
import { Collapse } from '@material-ui/core';
import { formValueSelector } from 'redux-form';

class PasswordValidate extends Component {
	constructor(props) {
		super(props);

		this.state = {
			validate: false,
		};
	}

	handleRequestCode = () => {
		this.setState({
			validate: !this.state.validate,
		});
	};

	handleConfigRequest = () => {
		const { translate } = this.props;

		const structure = {
			form: 'request_code',
			title: '',
			subtitle: '',
			helperText: '',
			maxWidth: 'sm',
			button_label: translate('user_change_password_send_request_validate'),
			fields: [
				{
					id: 'medium_type',
					name: 'medium_type',
					label: translate('user_change_password_type_request_validate'),
					type: 'select',
					options: [
						{
							label: translate('user_change_password_type_validate_email'),
							value: 'email',
						},
					],
					validate: {
						required: true,
					},
					initialvalue: this.props.request_code_field,
				},
				{
					id: 'medium_value',
					name: 'medium_value',
					label: translate('user_change_password_type_sms_email'),
					type: 'input',
					validate: {
						required: true,
					},
					helperText: translate('user_change_password_helper_email'),
					initialvalue: this.props.request_code_field === 'email' ? this.props.user.email : '',
				},
			],
		};

		let initialValues = this.handleDefaultValuesRequest(structure);

		return { structure: structure, initialValues: initialValues };
	};

	handleDefaultValuesRequest = (config) => {
		let values = {};
		config.fields.filter((field) => typeof field.initialvalue !== 'undefined').map((field, i) => (values[field.name] = field.initialvalue));
		return values;
	};

	handleConfig = () => {
		const { translate } = this.props;

		const structure = {
			form: 'code',
			title: '',
			subtitle: '',
			helperText: '',
			maxWidth: 'sm',
			button_label: translate('user_change_password_send_validate_code'),
			fields: [
				{
					id: 'token',
					name: 'token',
					label: translate('user_change_password_write_validate_code'),
					type: 'input',
					validate: {
						required: true,
					},
					initialvalue: '',
				},
			],
		};

		let initialValues = this.handleDefaultValues(structure);

		return { structure: structure, initialValues: initialValues };
	};

	handleDefaultValues = (config) => {
		let values = {};
		config.fields.filter((field) => typeof field.initialvalue !== 'undefined').map((field, i) => (values[field.name] = field.initialvalue));
		return values;
	};
	handleBackButton = () => {
		this.props.push('/mypages');
	};

	render() {
		const configuration = this.handleConfig();
		const configurationRequest = this.handleConfigRequest();

		return (
			<div>
				<Form
					form={configurationRequest.structure.form}
					formData={configurationRequest.structure}
					initialValues={configurationRequest.initialValues}
					handleSubmit={() => this.props.sendRequestCodeAction(configurationRequest.structure.form, this.handleRequestCode)}
				/>

				<Collapse in={this.state.validate}>
					<Form
						form={configuration.structure.form}
						formData={configuration.structure}
						initialValues={configuration.initialValues}
						handleSubmit={() =>
							this.props.sendValidateCodeAction(configuration.structure.form, () => {
								this.handleRequestCode();
								this.props.handleValidateCode();
							})
						}
						backButton={true}
						handleBackButton={this.handleBackButton}
					/>
				</Collapse>
			</div>
		);
	}
}
const selector = formValueSelector('request_code');
const mapStateToProps = (state) => ({
	translate: getTranslate(state.localize),
	request_code_field: selector(state, 'medium_type'),
	user: state.users.whoami,
});

export default connect(mapStateToProps, { sendRequestCodeAction, sendValidateCodeAction, push })(PasswordValidate);
