import axios from 'axios';
import { store } from '../store.js';
import { addTranslationForLanguage } from 'react-localize-redux';
import { setActiveLanguage } from 'react-localize-redux';
import { handleSplashScreenAction } from '../components/Generic/actions/genericActions';

export const fetchTranslationsAction = (code) => (dispatch) => {
	const state = store.getState();

	dispatch(handleSplashScreenAction(true));

	axios
		.get(state.config.apihost + `/translations`, {
			headers: { Authorization: 'Bearer ' + state.login.token },
			params: { system_requesting: 'user-app', code: code, system: 'frontoffice' },
		})
		.then((res) => {
			dispatch(addTranslationForLanguage(res.data, code));
			dispatch(setActiveLanguage(code));
			document.getElementsByTagName('html')[0].setAttribute('lang', code.split('-')[0]);
			dispatch(handleSplashScreenAction(false));
		})
		.catch((error) => {
			console.log(error.message);
			dispatch(handleSplashScreenAction(false));
		});
};
