import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { getTranslate } from 'react-localize-redux';

import { isIOS } from 'react-device-detect';
import { isMobileSize } from '../../helpers/functions/functions';
import { AppBar, Tabs, Tab, Typography, Box, Paper } from '@material-ui/core';

import { Alert } from '@material-ui/lab';

import LoginForm from './LoginForm';
import LoginBankID from './LoginBankID';
import LoginSITHS from './LoginSITHS';
import BankIDlogo from '../../assets/images/bankid.png';
import NetIDlogo from '../../assets/images/netid.png';

import TabContainer from '../Generic/TabContainer';

import { bankIDQRGeneratorAction } from './actions/loginActions';

class LoginTabs extends Component {
	constructor(props) {
		super(props);

		this.loginMethods = this.props.settings.supported_login_methods
			? this.props.settings.supported_login_methods.replace(/,\s*$/, "").split(',').map((item) => item.trim())
			: [];

		this.state = {
			selected:
				typeof this.props.settings.preferred_login_method !== 'undefined'
					? this.props.settings.preferred_login_method
					: this.loginMethods.length > 0
					? this.loginMethods[0]
					: 'password',
		};
	}

	handleChange = (event, newValue) => {
		if (newValue === 'bankid' && this.props.client !== null && this.props.client.id != '0' && !isMobileSize()) {
			this.props.bankIDQRGeneratorAction(this.props.selectedLanguage);
		}

		this.setState({
			selected: newValue,
		});
	};

	render() {
		const { translate } = this.props;
		const { theme } = this.props;

		const messageBlock =
			this.props.message.error !== '' ? (
				<Box component="span">
					<Typography variant="body2" color="error" align="center">
						{this.props.message.error}
					</Typography>
				</Box>
			) : null;

		return (
			<Paper elevation={this.props.client !== null && this.props.client.id == '0' ? 3 : 0} className="login-content-right-content-tabs">
				{this.loginMethods.length > 0 ? (
					<AppBar position="static" color="default">
						{this.loginMethods.length > 1 ? (
							<Tabs
								value={this.state.selected}
								onChange={this.handleChange}
								indicatorColor="primary"
								textColor="primary"
								variant="scrollable"
								scrollButtons="auto"
							>
								{this.loginMethods.map((method, key) => (
									<Tab key={key} label={translate('login_tab_' + method)} value={method} />
								))}
							</Tabs>
						) : null}
					</AppBar>
				) : null}
				{this.loginMethods.includes('password') ? (
					<TabContainer value={this.state.selected} index="password" dir={theme.direction} style={{ padding: '30px' }}>
						{messageBlock}
						<LoginForm selectedLanguage={this.props.selectedLanguage} />
					</TabContainer>
				) : (
					false
				)}
				{this.loginMethods.includes('bankid') ? (
					<TabContainer value={this.state.selected} index="bankid" dir={theme.direction} style={{ padding: '30px' }}>
						{isIOS ? (
							<Alert className="login-bankid-alert-infobox" severity="info">
								{' '}
								{translate('login_bankid_alert_infobox')}{' '}
							</Alert>
						) : null}
						<Box mb={2}>
							<Typography align="center" variant="body2" component="p" gutterBottom>
								{translate('user_register_banck_id_info')}
							</Typography>
						</Box>
						<img src={BankIDlogo} height="70" alt="BankID logo" />
						{messageBlock}
						<LoginBankID selectedLanguage={this.props.selectedLanguage} type="login" />
					</TabContainer>
				) : (
					false
				)}
				{this.loginMethods.includes('siths') ? (
					<TabContainer value={this.state.selected} index="siths" dir={theme.direction} style={{ padding: '30px' }}>
						<img src={NetIDlogo} height="70" alt="NetID logo" />
						{messageBlock}
						<LoginSITHS selectedLanguage={this.props.selectedLanguage} />
					</TabContainer>
				) : (
					false
				)}
			</Paper>
		);
	}
}

const mapStateToProps = (state) => ({
	settings: state.settings.site,
	translate: getTranslate(state.localize),
	client: state.login.client,
});

export default withStyles(null, { withTheme: true })(connect(mapStateToProps, { bankIDQRGeneratorAction })(LoginTabs));
