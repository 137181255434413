import React from 'react';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { Container, Typography, Link } from '@material-ui/core';

function Footer({ translate }) {
	return (
		<footer className="generic-footer">
			<Container maxWidth="sm">
				<Typography variant="body2" color="textSecondary" align="center">
					{translate("footer_copyright", {
						footer_copyright_company: (
							<Link color="inherit" href="https://altericare.se" target="_blank">
								{translate('company_name')}
							</Link>
						)
					})}{' '}
					{new Date().getFullYear()}
				</Typography>
			</Container>
		</footer>
	);
}

const mapStateToProps = (state) => ({
	translate: getTranslate(state.localize)
});

export default connect(mapStateToProps)(Footer);