import React, { Component } from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";

/*
    see this again
    How the hell ComponentName makes the "same" as a Route!?!?

*/
class PrivateRoute extends Component {
    render() {
        var { component, extraProps, ...rest } = this.props;
        
        if (this.props.login && this.props.token.length > 0) {
            const ComponentName = component;
            return (
                <Route>
                    <ComponentName extraProps={extraProps} {...rest} />
                </Route>
            );
        } else {
            return <Redirect to="/login" />;
        }
    }
}

const mapStateToProps = state => ({
    login: state.login.login,
    token: state.login.token
});

export default connect(mapStateToProps)(PrivateRoute);
