import axios from 'axios';
import {
	HANDLE_SPLASH_SCREEN,
	HANDLE_LOADING,
	FETCH_FILES,
	MODAL_ACTION,
	LIGHTBOX_ACTION,
	DISABLE_FORM_BUTTON_ACTION,
	ADD_WRAPPER,
	ZOOM_BUTTON_LIGHTBOX_ACTION,
	FETCH_ROLES,
	DOCUMENT_PREVIEW,
	PROGRESS_ACTION,
	SET_PROGRESS_ACTION
} from './types';
import { push } from 'connected-react-router';
import { store } from '../../../store.js';
import { openPdfFIle } from '../../../helpers/functions/functions';

export const convertToFormData = (fields) => {
	if (fields) {
		const form_data = new FormData();

		for (var key in fields) {
			if (fields[key] !== null && fields[key] instanceof FileList) {
				console.log(fields[key]);
				Array.from(fields[key]).forEach((element) => {
					form_data.append(key, element);
				});
				// fields[key].forEach(element => {
				// 	form_data.append(key, element);
				// });
			} else if (fields[key] !== null && fields[key][0] instanceof File) {
				fields[key].forEach((element) => {
					form_data.append(key, element);
				});
			} else {
				form_data.append(key, fields[key]);
			}
		}

		return form_data;
	}
};

export const handleLoadingAction = (trigger) => (dispatch) => {
	if (!trigger) {
		setTimeout(function () {
			dispatch({
				type: HANDLE_LOADING,
				payload: trigger,
			});
		}, 500);
	} else {
		dispatch({
			type: HANDLE_LOADING,
			payload: trigger,
		});
	}
};

export const handleSplashScreenAction = (trigger) => (dispatch) => {
	if (!trigger) {
		setTimeout(function () {
			dispatch({
				type: HANDLE_SPLASH_SCREEN,
				payload: trigger,
			});
		}, 1000);
	} else {
		dispatch({
			type: HANDLE_SPLASH_SCREEN,
			payload: trigger,
		});
	}
};

export const handleRedirectsAction = (path) => (dispatch) => {
	dispatch(push(path));
};

export const fetchGuidelines = () => (dispatch) => {
	const state = store.getState();

	axios
		.get(state.config.apihost + `/guidelines`, {
			headers: { Authorization: 'Bearer ' + state.login.token },
		})
		.then((res) => {
			dispatch({
				type: FETCH_FILES,
				payload: res.data,
			});
		})
		.catch((error) => {
			console.log(error);
		});
};

export const fetchFileForPatients = () => (dispatch) => {
	const state = store.getState();

	axios
		.get(state.config.apihost + `/selfcares`, {
			headers: { Authorization: 'Bearer ' + state.login.token },
		})
		.then((res) => {
			dispatch({
				type: FETCH_FILES,
				payload: res.data,
			});
		})
		.catch((error) => {
			console.log(error);
		});
};

export const displayModalAction = (params) => (dispatch) => {
	dispatch({
		type: MODAL_ACTION,
		payload: {
			open: true,
			title: params.title,
			description: params.description,
			button_no: params.button_no,
			button_no_id: params.button_no_id,
			button_yes: params.button_yes,
			button_yes_id: params.button_yes_id,
			action: params.action,
			...(typeof params.action_no_button !== 'undefined' && { action_no_button: params.action_no_button }),
			...(typeof params.props_no_button !== 'undefined' && { props_no_button: params.props_no_button }),
			props: params.props,
		},
	});
};

export const closeModalAction = () => (dispatch) => {
	dispatch({
		type: MODAL_ACTION,
		payload: {
			open: false,
		},
	});
};

export const displayProgressAction = (params) => (dispatch) => {
	dispatch({
		type: PROGRESS_ACTION,
		payload: {
			open: true,
			title: params.title,
			description: params.description,
			button_cancel: params.button_cancel,
			button_cancel_id: params.button_cancel_id,
			action: params.action,
			props_cancel_button: params.props_cancel_button,
			props: params.props,
		},
	});
};

export const closeProgressAction = () => (dispatch) => {
	dispatch({
		type: PROGRESS_ACTION,
		payload: {
			open: false,
		},
	});
};

export const setProgressValueAction = (value) => (dispatch) => {
	dispatch({
		type: SET_PROGRESS_ACTION,
		payload: value,
	});
};

export const cancelProgressAction = () => (dispatch) => {
	dispatch({
		type: PROGRESS_ACTION,
		payload: {
			canceled: true,
			open: false,
		},
	});
};

export const fetchFileAction =
	(type, uuid, link = null) =>
	(dispatch) => {
		const state = store.getState();

		let url = state.config.apihost;

		switch (type) {
			case 'ticket_file':
				let file = state.tickets.ticket.files.find((file) => file.uuid === uuid);
				if (file.type === 'questionnaire') {
					url = url + `/tickets/` + state.tickets.ticket.id + `/questionnaires/` + file.questionnaires_id + `/files/` + uuid;
					break;
				} else {
					url = url + `/tickets/` + state.tickets.ticket.id + `/files/` + uuid;
					break;
				}

			case 'ticket_certificate':
				url = url + `/tickets/` + state.tickets.ticket.id + `/certificates/` + uuid;
				break;

			case 'guidelines':
				url = url + `/guidelines/files/` + uuid;
				break;

			case 'files_for_patients':
				url = url + `/selfcares/files/` + uuid;
				break;

			case 'libraries':
				url = state.config.driveshost + link + '/data';
				break;

			default:
				break;
		}

		fetch(url, {
			headers: { Authorization: 'Bearer ' + state.login.token },
		})
			.then((resp) => resp.blob())
			.then((blob) => {
				const fileURL = URL.createObjectURL(blob);

				if (blob.type.split('/')[0] === 'image') {
					dispatch({
						type: LIGHTBOX_ACTION,
						payload: {
							open: true,
							image: [
								{
									src: fileURL,
									thumbnail: fileURL,
									thumbnailWidth: 0,
									thumbnailHeight: 0,
								},
							],
						},
					});
				} else {
					openPdfFIle(fileURL);
				}
			})
			.catch(() => alert('oh no!'));
	};

const blobPdfFromBase64String = (base64String) => {
	const byteArray = Uint8Array.from(
		atob(base64String)
			.split('')
			.map((char) => char.charCodeAt(0))
	);

	return new File([byteArray], 'Videoimage', { type: 'image/png' });
};

export const openLightBox = (img) => (dispatch) => {
	let image = blobPdfFromBase64String(img);

	dispatch({
		type: LIGHTBOX_ACTION,
		payload: {
			open: true,
			image: [
				{
					src: URL.createObjectURL(image),
					thumbnail: URL.createObjectURL(image),
					thumbnailWidth: 0,
					thumbnailHeight: 0,
				},
			],
			screenshot: image,
		},
	});
};

export const closeLightboxAction = () => (dispatch) => {
	dispatch({
		type: LIGHTBOX_ACTION,
		payload: {
			open: false,
			image: [],
			disabledZoomButton: false,
		},
	});

	Object.assign(document.body.style, { overflow: 'auto', margin: '0px', paddingRight: '0px' });
};
export const disableLightboxZoomButtonAction = () => (dispatch) => {
	dispatch({
		type: ZOOM_BUTTON_LIGHTBOX_ACTION,
		payload: true,
	});
};

export const disableFormButtonAction = (value) => (dispatch) => {
	dispatch({
		type: DISABLE_FORM_BUTTON_ACTION,
		payload: value,
	});
};

export const addDelayAction =
	(actions = null, events = null) =>
	(dispatch) => {
		actions = actions === null ? [] : !Array.isArray(actions) ? [actions] : actions;
		events = events === null ? [] : !Array.isArray(events) ? [events] : events;

		if (actions.length > 0 || events.length > 0) {
			setTimeout(() => {
				if (actions.length > 0) actions.forEach((action) => dispatch(action));
				if (events.length > 0) events.forEach((event) => event());
			}, 1000);
		}
	};

export const addWrapper = () => (dispatch) => {
	const state = store.getState();

	dispatch({
		type: ADD_WRAPPER,
		payload: state.generic.wrapper + 1,
	});
};

export const fetchRolesAction =
	(type = 'internal') =>
	(dispatch) => {
		const state = store.getState();

		//external or internal
		axios
			.get(state.config.apihost + `/roles`, {
				params: { type },
				headers: { Authorization: 'Bearer ' + state.login.token },
			})
			.then((res) => {
				dispatch({
					type: FETCH_ROLES,
					payload: {
						type: type,
						data: res.data,
					},
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};

export const displayDocumentPreviewAction = (loading) => (dispatch) => {
	dispatch({
		type: DOCUMENT_PREVIEW,
		payload: {
			loading,
		},
	});
};
