import axios from 'axios';
import { GET_MEETING, GET_MEETING_ACCESS, GET_MEETING_PUBLIC_ACCESS, MEETING_ERROR, GET_MEETING_FIELDS, GET_MEETING_FILES_FIELDS } from './types';
import { LOGIN, SELECT_CLIENT, BANKID_PROGRESS, LOGIN_BANKID, BANKID_QRCODE } from '../../Login/actions/types';
import { store } from '../../../store.js';
import { addDelayAction, handleSplashScreenAction } from '../../Generic/actions/genericActions';
import { whoamiAction } from '../../Users/actions/usersActions';
import eventsService from '../../../helpers/Events';
import { getTranslate } from 'react-localize-redux';
import { push } from 'connected-react-router';
import { isMobileSize, openPdfFIle } from '../../../helpers/functions/functions';
import { LIGHTBOX_ACTION } from '../../Generic/actions/types';

let checkBankIdProgressTimer = null;
let checkBankIdProgressTries = 0;

export const fetchMeetingByIdAction = (id) => (dispatch) => {
	const state = store.getState();

	axios
		.get(state.config.apihost + `/meetings/` + id)
		.then((res) => {
			console.log(res.data);
			dispatch({
				type: GET_MEETING,
				payload: res.data,
			});
		})
		.catch((err) => {
			console.log(err);
			console.log('Error fetching data.');
		});
};

export const fetchMeetingByTokenAction = (token, callback) => (dispatch) => {
	const state = store.getState();

	// dispatch(handleSplashScreenAction(true));

	axios
		.get(state.config.apihost + `/meetings/token/` + token, {
			headers: { Authorization: 'Bearer ' + state.login.token },
		})
		.then((res) => {
			let internal_attendees = res.data.internal_attendees.map((attendee) => ({
				...attendee,
				system: 'internal',
			}));
			let external_attendees = res.data.external_attendees.map((attendee) => ({
				...attendee,
				system: 'external',
			}));
			dispatch({
				type: GET_MEETING,
				payload: res.data,
				invitees_list: [...internal_attendees, ...external_attendees],
			});
			if (typeof callback === 'function') {
				callback();
			}
		})
		.catch((err) => {
			console.log(err);
			console.log('Error fetching data.');

			callback();
		});
};

export const fetchMeetingFieldsAction = (config) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	var columns = [];

	config.map((field) => {
		if (field.options.renderOnExpand) {
			field.options.display = 'excluded';
		}
		columns.push({
			name: field.label,
			label: translate('table_column_' + field.label),
			options: {
				...field.options,
			},
		});
	});

	dispatch({
		type: GET_MEETING_FIELDS,
		columns: columns,
	});
};

export const fetchMeetingFieldsFilesAction = (config) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	var files_columns = [];

	config.map((field) => {
		if (field.options.renderOnExpand) {
			field.options.display = 'excluded';
		}
		files_columns.push({
			name: field.label,
			label: translate('table_column_' + field.label),
			options: {
				...field.options,
			},
		});
	});

	dispatch({
		type: GET_MEETING_FILES_FIELDS,
		files_columns: files_columns,
	});
};

export const getMeetingAccessAction = (link) => (dispatch) => {
	const state = store.getState();

	const tokenAux = link.split('/');
	const token = tokenAux[tokenAux.length - 1];

	axios
		.post(state.config.apihost + `/meetings/logins/` + token, {
			headers: { Authorization: 'Bearer ' + state.login.token },
		})
		.then((res) => {
			dispatch({
				type: GET_MEETING_ACCESS,
				payload: { token: token, status: res.data.meeting_status, login_type: res.data.login_type, organizer: res.data.organizer },
			});

			dispatch(push(link));
		})
		.catch((err) => {
			console.log(err);
			dispatch(addDelayAction(null, () => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

export const getMeetingAccessLoginAction = (form, token, login_form) => (dispatch) => {
	const state = store.getState();

	// let loginFormData = convertToFormData(state.form[login_form].values);
	let loginFormData = new FormData();

	if (state.form[form].values) {
		Object.keys(state.form[form].values).forEach((key, index) => {
			if (state.form[form].values[key] !== null && state.form[form].values[key].length > 0 && state.form[form].values[key] !== 'null') {
				loginFormData.append(key, state.form[form].values[key]);
			}
		});
	}

	// if (state.form[language_form].values) {
	// 	loginFormData.append('language', state.form[language_form].values.language);
	// }

	switch (login_form) {
		case 'login':
			loginFormData.append('grant_type', 'password');
			loginFormData.append('username', state.form[login_form].values.login_email);
			loginFormData.append('password', state.form[login_form].values.login_password);
			loginFormData.append('language', state.form[login_form].values.language);
			loginFormData.append('client_id', state.login.client.id);
			break;
		case 'bankid':
			loginFormData.append('grant_type', 'bankid');
			loginFormData.append('ssn', state.form[login_form].values.bankid_ssn);
			loginFormData.append('language', state.form[login_form].values.language);
			loginFormData.append('client_id', state.login.client.id);
			break;
		case 'guest':
			loginFormData.append('grant_type', 'external_meeting_attendee');
			loginFormData.append('language', state.form[login_form].values.language);
			break;
		default:
			loginFormData.append('grant_type', 'external_meeting_attendee');
			break;
	}

	for (var key of loginFormData.keys()) {
		console.log(key);
	}

	for (var value of loginFormData.values()) {
		console.log(value);
	}

	if (login_form !== 'bankid') {
		dispatch(handleSplashScreenAction(true));
	}

	axios
		.post(state.config.apihost + `/meetings/logins/` + token, loginFormData, {
			headers: { Authorization: 'Bearer ' + state.login.token },
		})
		.then((res) => {
			console.log(res.data);

			if (login_form === 'bankid') {
				dispatch({
					type: BANKID_PROGRESS,
					payload: {
						bankid_progress: true,
						session_id: res.data.sessionId,
					},
				});
				// always update meeting token from response

				let qrCode = document.getElementById('bankid_qrcode');
				if (qrCode !== null) {
					qrCode.classList.remove('login-bankid-qrcode-disabled');
				}

				clearInterval(checkBankIdProgressTimer);
				checkBankIdProgressTimer = null;

				checkBankIdProgressTimer = setInterval(() => dispatch(checkBankIdProgressMeeting(res.data.meeting_token)), 1000);

				if (isMobileSize()) {
					window.location.replace('bankid://' + res.data.autoStartToken);
				}
			} else {
				let expireDate = new Date();
				expireDate.setSeconds(expireDate.getSeconds() + res.data.expires_in);

				Promise.resolve(
					// <-- async dispatch chaining in action
					dispatch({
						type: LOGIN,
						payload: {
							login: true,
							client: res.data.client_id,
							token: res.data.access_token,
							refresh_token: res.data.refresh_token,
							expires_in: res.data.expires_in,
							expire_date_time: expireDate,
						},
					})
				)
					.then(() => {
						dispatch(whoamiAction(true));
					})
					.then(() => {
						dispatch({
							type: GET_MEETING_ACCESS,
							payload: {
								token: token,
								status: res.data.meeting_status,
								login_type: res.data.login_type,
								organizer: res.data.organizer,
							},
						});
					})
					.then(() => {
						dispatch(handleSplashScreenAction(false));
					});

				// dispatch({
				// 	type: LOGIN,
				// 	payload: {
				// 		login: true,
				// 		client: res.data.client_id,
				// 		token: res.data.access_token,
				// 		refresh_token: res.data.refresh_token,
				// 		expires_in: res.data.expires_in,
				// 		expire_date_time: expireDate,
				// 	},
				// });

				// dispatch(whoamiAction(true)); // check this login without cookies set

				// dispatch({
				// 	type: GET_MEETING_ACCESS,
				// 	payload: { token: token, status: res.data.meeting_status, login_type: res.data.login_type, organizer: res.data.organizer },
				// });

				// setTimeout(() => {
				// 	dispatch(handleSplashScreenAction(false));
				// }, 1000);
			}
		})
		.catch((err) => {
			console.log(err);

			setTimeout(() => {
				dispatch(handleSplashScreenAction(false));
			}, 1000);
		});
};

export const bankIDQRGeneratorMeetingAction = (selectedLanguage) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	var formData = new FormData();

	formData.append('grant_type', 'bankid');
	formData.append('language', selectedLanguage);
	formData.append('client_id', state.login.client.id);

	axios
		.post(state.config.apihost + `/login`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
		.then((result) => {
			dispatch({
				type: BANKID_QRCODE,
				payload: {
					session_id: result.data.sessionId,
					autostarttoken: result.data.autoStartToken,
				},
			});

			let qrCode = document.getElementById('bankid_qrcode');
			if (qrCode !== null) {
				qrCode.classList.remove('login-bankid-qrcode-disabled');
			}

			clearInterval(checkBankIdProgressTimer);
			checkBankIdProgressTimer = null;

			checkBankIdProgressTimer = setInterval(() => dispatch(checkBankIdProgressMeeting(state.meetings.token)), 1000);
		})
		.catch((err) => {
			eventsService.triggerEvent('alert_login', { type: 'error', message: translate('alert_post_login_no_response') });
			dispatch({
				type: LOGIN,
				payload: {
					login: false,
					token: '',
					refresh_token: '',
					expires_in: null,
					expire_date_time: null,
				},
			});
		});
};

export const checkBankIdProgressMeeting = (token) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	let qrCode = document.getElementById('bankid_qrcode');

	/**
 * Add to body params
 * 'user_type' => $this->request->getQuery('user_type') ?? null,
    'meeting_token' => $this->request->getQuery('meeting_token') ?? null)
 */

	axios
		.get(state.config.apihost + `/auth/logins/bankid/` + state.login.session_id, {
			params: { user_type: 'meeting_access', meeting_token: token },
		})
		.then((res) => {
			if (typeof res.data.access_token !== 'undefined') {
				let expireDate = new Date();
				expireDate.setSeconds(expireDate.getSeconds() + res.data.expires_in);

				clearInterval(checkBankIdProgressTimer);

				dispatch(handleSplashScreenAction(true));

				Promise.all([
					// <-- async dispatch chaining in action
					dispatch({
						type: LOGIN_BANKID,
						payload: {
							login: true,
							token: res.data.access_token,
							refresh_token: res.data.refresh_token,
							expires_in: res.data.expires_in,
							expire_date_time: expireDate,
							bankid_progress: false,
							confirmed: true,
						},
					}),
					dispatch(whoamiAction(true)),
					dispatch({
						type: GET_MEETING_ACCESS,
						payload: { token: token, status: res.data.meeting_status, login_type: res.data.login_type, organizer: res.data.organizer },
					}),
				]);

				// dispatch({
				// 	type: LOGIN_BANKID,
				// 	payload: {
				// 		login: true,
				// 		token: res.data.access_token,
				// 		refresh_token: res.data.refresh_token,
				// 		expires_in: res.data.expires_in,
				// 		expire_date_time: expireDate,
				// 		bankid_progress: false,
				// 	},
				// });

				// dispatch({
				// 	type: GET_MEETING_ACCESS,
				// 	payload: { token: token, status: res.data.meeting_status, login_type: res.data.login_type, organizer: res.data.organizer },
				// });

				// dispatch(whoamiAction(true));
			} else if (checkBankIdProgressTries >= 60) {
				clearInterval(checkBankIdProgressTimer);
				checkBankIdProgressTries = 0;
				dispatch({
					type: BANKID_PROGRESS,
					payload: {
						bankid_progress: false,
						session_id: '',
					},
				});

				if (qrCode !== null) {
					qrCode.classList.add('login-bankid-qrcode-disabled');
				}
			} else {
				checkBankIdProgressTries++;
			}
		})
		.catch((err) => {
			if (err.request && err.request.status === 401) {
				eventsService.triggerEvent('alert_login', { type: 'error', message: JSON.parse(err.request.responseText).error });
			} else if (err.name && err.name === 'login_backoffice_no_access') {
				eventsService.triggerEvent('alert_login', { type: 'error', message: err.message });
			} else {
				eventsService.triggerEvent('alert_login', { type: 'error', message: translate('alert_post_login_no_response') });
			}

			clearInterval(checkBankIdProgressTimer);
			dispatch({
				type: BANKID_PROGRESS,
				payload: {
					bankid_progress: false,
					session_id: '',
				},
			});
			if (qrCode !== null) {
				qrCode.classList.add('login-bankid-qrcode-disabled');
			}
		});
};

export const getMeetingPublicAccessAction = (token, callback) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	axios
		.get(state.config.apihost + `/meetings/logins/` + token)
		.then((res) => {
			console.log(res.data);

			dispatch({
				type: GET_MEETING_ACCESS,
				payload: {
					token: token,
					status: res.data.meeting_status,
					login_type: res.data.login_type,
					organizer: res.data.organizer,
					client: res.data.client_id,
					invitee: {
						ssn: res.data.ssn,
						name: res.data.name,
						phone: res.data.phone,
						email: res.data.email,
					},
				},
			});

			dispatch({
				type: SELECT_CLIENT,
				payload: {
					client: state.settings.clients.find((client) => client.id == res.data.client_id),
					platform: 'frontoffice',
				},
			});

			dispatch(
				addDelayAction(typeof callback === 'function' ? callback : [], [
					() => eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_get_meeting_access') }),
				])
			);

			// if(typeof callback === 'function') {
			//     callback();
			// }

			// dispatch(whoamiAction(true));
		})
		.catch((err) => {
			console.log(err);
			console.log('Error fetching data.');

			dispatch(
				addDelayAction(typeof callback === 'function' ? callback : [], () =>
					eventsService.triggerEvent('alert', { type: 'error', message: err.message })
				)
			);

			// if(typeof callback === 'function') {
			//     callback();
			// }
		});
};

export const getMeetingPublicToken = (id) => (dispatch) => {
	const state = store.getState();

	axios
		.get(state.config.apihost + `/meetings/` + id, {
			headers: { Authorization: 'Bearer ' + state.login.token },
		})
		.then((res) => {
			dispatch({
				type: GET_MEETING_PUBLIC_ACCESS,
				payload: res.data.meeting_token_public,
			});
		})
		.catch((err) => {
			console.log(err);
			console.log('Error fetching data.');
		});
};

export const errorAction = (err) => (dispatch) => {
	dispatch({
		type: MEETING_ERROR,
		payload: {
			error_message: err.message,
		},
	});
};

export const resetErrorAction = () => (dispatch) => {
	dispatch({
		type: MEETING_ERROR,
		payload: {
			error_message: '',
		},
	});
};

export const postUploadFileChatAction = (data) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	axios
		.post(state.config.apihost + '/storage/meetings/' + state.meetings.data.id, data, {
			headers: { Authorization: 'Bearer ' + state.login.token },
		})
		.then((res) => {
			dispatch(fetchMeetingByTokenAction(state.meetings.token));

			eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_post_uploadfile') });
		})
		.catch((err) => {
			eventsService.triggerEvent('alert', { type: 'error', message: err.message });
		});
};

export const getMeetingFileAction = (link) => (dispatch) => {
	const state = store.getState();

	fetch(state.config.apihost + link, {
		headers: { Authorization: 'Bearer ' + state.login.token },
	})
		.then((resp) => resp.blob())
		.then((blob) => {
			const fileURL = URL.createObjectURL(blob);

			if (blob.type === 'application/pdf') {
				openPdfFIle(fileURL);
			} else {
				dispatch({
					type: LIGHTBOX_ACTION,
					payload: {
						open: true,
						image: [
							{
								src: fileURL,
								thumbnail: fileURL,
								thumbnailWidth: 0,
								thumbnailHeight: 0,
							},
						],
					},
				});
			}
		})
		.catch(() => alert('oh no!'));
};
