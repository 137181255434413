import React, { Component } from 'react';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Typography, Grid, Container } from '@material-ui/core';
import { createNewAccount } from '../Login/actions/loginActions';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Form } from '../Generic';
import { push } from 'connected-react-router';
import NavBar from '../Welcome/NavBar';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { formValueSelector } from 'redux-form';
import { MessageToastLogin } from '../Generic';

class CreateNewAccount extends Component {
	state = {
		consented: this.props.user !== null ? this.props.user.consented : 0,
		password: '',
		confirmPassword: '',
		match: null,
		charNumberValid: false,
		specialCharValid: false,
		uppercaseValid: false,
		numberValid: false,
		visibility: false,
		valid: false,
	};

	componentDidUpdate() {
		if (typeof this.props.user === 'object' && this.props.login.login && this.props.login.self_registration) {
			this.props.push('/conditions');
		}
	}

	// Check the length of the input
	checkPasswordLength = (password) => {
		if (
			typeof this.props.settings.site.password_complexity === 'undefined' ||
			this.props.settings.site.password_complexity === null ||
			password.length >= this.props.settings.site.password_complexity.length ||
			!this.props.settings.site.password_complexity.length
		) {
			this.setState({
				charNumberValid: true,
			});
		} else {
			this.setState({
				charNumberValid: false,
			});
		}
	};

	// Check for special characters
	checkSpecialCharacters = (password) => {
		const pattern = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
		const valSC = (password.match(pattern) || []).length;
		if (
			typeof this.props.settings.site.password_complexity === 'undefined' ||
			this.props.settings.site.password_complexity === null ||
			valSC >= this.props.settings.site.password_complexity.special_characters ||
			!this.props.settings.site.password_complexity.special_characters
		) {
			this.setState({
				specialCharValid: true,
			});
		} else {
			this.setState({
				specialCharValid: false,
			});
		}
	};

	// Check for an uppercase character
	checkUppercase = (password) => {
		const pattern = /[A-Z]/g;
		const valU = (password.match(pattern) || []).length;

		if (
			typeof this.props.settings.site.password_complexity === 'undefined' ||
			this.props.settings.site.password_complexity === null ||
			valU >= this.props.settings.site.password_complexity.uppercase ||
			!this.props.settings.site.password_complexity.uppercase
		) {
			this.setState({
				uppercaseValid: true,
			});
		} else {
			this.setState({
				uppercaseValid: false,
			});
		}
	};

	// Check for a number
	checkNumber = (password) => {
		const pattern = /[0-9]/g;
		const valN = (password.match(pattern) || []).length;
		if (
			typeof this.props.settings.site.password_complexity === 'undefined' ||
			this.props.settings.site.password_complexity === null ||
			valN >= this.props.settings.site.password_complexity.numbers ||
			!this.props.settings.site.password_complexity.numbers
		) {
			this.setState({
				numberValid: true,
			});
		} else {
			this.setState({
				numberValid: false,
			});
		}
	};
	handlePasswordChange = (value) => {
		this.checkPasswordLength(value);
		this.checkSpecialCharacters(value);
		this.checkUppercase(value);
		this.checkNumber(value);
		this.setState({ valid: this.props.password_verify === value });
	};

	handleSubmitButton = (form) => {
		this.props.createNewAccount(form);
	};

	handleDefaultValues = (config) => {
		let values = {};
		config.fields.filter((field) => typeof field.initialvalue !== 'undefined').map((field, i) => (values[field.name] = field.initialvalue));
		return values;
	};

	handleConfig = () => {
		const { translate } = this.props;

		let password_complexity = this.props.settings.site.password_complexity;

		const structure = {
			form: 'newuser',
			id: 'registeruser-form',
			title: '',
			subtitle: '',
			helperText: '',
			maxWidth: 'lg',
			backButtonWidth: 'sm',
			fields: [
				{
					id: 'first_name',
					name: 'first_name',
					label: translate('users_register_form_myprofile_field_first_name'),
					type: 'input',
					validate: {
						required: true,
					},
					grid_layout: { xs: 12, md: 6 },
				},
				{
					id: 'last_name',
					name: 'last_name',
					label: translate('users_register_form_myprofile_field_last_name'),
					type: 'input',
					validate: {
						required: true,
					},
					grid_layout: { xs: 12, md: 6 },
				},
				{
					id: 'gender',
					name: 'gender',
					label: translate('users_register_form_myprofile_field_gender'),
					type: 'select',
					grid_layout: { xs: 12, md: 6 },
					options: [
						{
							label: translate('users_register_form_myprofile_field_gender_option_male'),
							value: 'male',
						},
						{
							label: translate('users_register_form_myprofile_field_gender_option_female'),
							value: 'female',
						},
						{
							label: translate('users_register_form_personal_data_field_gender_option_other'),
							value: 'unknown',
						},
					],
					validate: {
						required: true,
					},
				},
				{
					id: 'birthdate',
					name: 'birthdate',
					label: translate('users_register_form_personal_data_field_birthdate'),
					type: 'datepicker',
					openTo: 'year',
					disableFuture: true,
					validate: {
						required: true,
					},
					grid_layout: { xs: 12, md: 6 },
				},
				{
					id: 'address',
					name: 'address',
					label: translate('users_register_form_myprofile_field_address'),
					type: 'input',
					validate: {
						required: true,
					},
					grid_layout: { xs: 12, md: 6 },
				},
				{
					id: 'zip_code',
					name: 'zip_code',
					label: translate('users_register_form_myprofile_field_zip_code'),
					type: 'input',
					validate: {
						required: true,
					},
					grid_layout: { xs: 12, md: 6 },
				},
				{
					id: 'city',
					name: 'city',
					label: translate('users_register_form_myprofile_field_city'),
					type: 'input',
					validate: {
						required: true,
					},
					grid_layout: { xs: 12, md: 6 },
				},
				{
					id: 'phone',
					name: 'phone',
					label: translate('users_register_form_myprofile_field_phone'),
					type: 'number',
					disableNumberArrows: true,
					validate: {
						required: true,
					},
					grid_layout: { xs: 12, md: 6 },
				},
				{
					id: 'login_email',
					name: 'login_email',
					label: translate('users_register_form_myprofile_field_email'),
					type: 'input',
					validate: {
						required: true,
					},
					grid_layout: { xs: 12, md: 6 },
				},
				{
					id: 'language',
					name: 'language',
					label: translate('users_register_form_myprofile_field_language'),
					type: 'select',
					options: this.props.settings.site.available_languages
						? this.props.settings.site.available_languages.map((obj) => {
								return {
									value: obj.code,
									label: translate('language_' + obj.label),
								};
						  })
						: [
								{
									label: translate('users_register_form_myprofile_field_language_option_english'),
									value: 'en-US',
								},
								{
									label: translate('users_register_form_myprofile_field_language_option_swedish'),
									value: 'sv-SE',
								},
						  ],
					validate: {
						required: true,
					},
					grid_layout: { xs: 12, md: 6 },
				},
				{
					id: 'login_password',
					name: 'login_password',
					label: translate('user_change_write_new_password'),
					type: 'inputadornment',
					inputType: this.state.visibility ? 'input' : 'password',
					validate: {
						required: true,
						functionValidation: [
							{
								validation: () => {
									return (
										this.state.charNumberValid &&
										this.state.specialCharValid &&
										this.state.uppercaseValid &&
										this.state.numberValid
									);
								},

								message: 'user_change_password_need_all_values_validate',
							},
						],
					},
					onChange: (value) => {
						this.handlePasswordChange(value);
					},
					adornment: {
						action: () =>
							this.setState({
								visibility: !this.state.visibility,
							}),
						triggered: false,
						alwaysEnabled: true,
						icon: this.state.visibility ? <Visibility /> : <VisibilityOff />,
					},
					grid_layout: { xs: 12, md: 6 },
					initialvalue: '',
				},
				{
					id: 'password_confirm',
					name: 'password_confirm',
					label: translate('user_change_password_write_repeat'),
					type: 'password',
					validate: {
						required: true,
						functionValidation: [
							{
								validation: () => {
									return this.state.valid;
								},

								message: 'user_change_password_confirm_validate',
							},
						],
					},
					onChange: (value) => {
						this.setState({ valid: this.props.password_field === value });
					},
					grid_layout: { xs: 12, md: 6 },
					initialvalue: '',
				},
				...(this.props.settings.site.password_complexity ? [{
					id: 'password_complexity',
					type: 'external',
					content: this.getPasswordComplexityLayout(),
				}] : []),
				{
					id: 'consented',
					name: 'consented',
					label: translate('users_register_form_personal_data_field_consented'),
					type: 'singleCheckbox',
					initialvalue: this.props.user !== null ? (this.props.user.consented == '1' ? true : false) : null,
					onChange: (value) => {
						this.setState((state) => ({
							...state,
							consented: value ? '1' : '0',
						}));
					},
				},
				{
					id: 'client_id',
					name: 'client_id',
					label: '',
					type: 'hidden',
					initialvalue: this.props.login.client.id,
				},
			],
		};

		let initialValues = this.handleDefaultValues(structure);

		return { structure: structure, initialValues: initialValues };
	};
	handleBackButton = () => {
		this.props.push('/login');
	};

	getPasswordComplexityLayout = () => {
		const { translate } = this.props;

		return (
			<Container maxWidth="lg">
				<Grid container>
					{!this.props.settings.site.password_complexity.length || !this.props.settings.site.password_complexity.length === 0 ? (
						''
					) : (
						<Grid item md={3} xs={12}>
							<Typography align="justify" variant="subtitle2" paragraph={true}>
								{this.state.charNumberValid ? <CheckIcon color="primary" /> : <ClearIcon color="error" />}
								{translate('user_change_password_carachters', {
									length: this.props.settings.site.password_complexity.length,
								})}
							</Typography>
						</Grid>
					)}
					{!this.props.settings.site.password_complexity.special_characters ||
					this.props.settings.site.password_complexity.special_characters === 0 ? (
						''
					) : (
						<Grid item md={3} xs={12}>
							<Typography align="justify" variant="subtitle2" paragraph={true}>
								{this.state.specialCharValid ? <CheckIcon color="primary" /> : <ClearIcon color="error" />}
								{translate('user_change_password_special_carachters', {
									length: this.props.settings.site.password_complexity.special_characters,
								})}
							</Typography>
						</Grid>
					)}
					{!this.props.settings.site.password_complexity.uppercase || this.props.settings.site.password_complexity.uppercase === 0 ? (
						''
					) : (
						<Grid item md={3} xs={12}>
							<Typography align="justify" variant="subtitle2" paragraph={true}>
								{this.state.uppercaseValid ? <CheckIcon color="primary" /> : <ClearIcon color="error" />}
								{translate('user_change_password_uppercase_letter', {
									length: this.props.settings.site.password_complexity.uppercase,
								})}
							</Typography>
						</Grid>
					)}
					{!this.props.settings.site.password_complexity.numbers || this.props.settings.site.password_complexity.numbers === 0 ? (
						''
					) : (
						<Grid item md={3} xs={12}>
							<Typography align="justify" variant="subtitle2" paragraph={true}>
								{this.state.numberValid ? <CheckIcon color="primary" /> : <ClearIcon color="error" />}
								{translate('user_rchange_password_number', {
									length: this.props.settings.site.password_complexity.numbers,
								})}
							</Typography>
						</Grid>
					)}
				</Grid>
			</Container>
		);
	};

	render() {
		const { translate } = this.props;
		const configuration = this.handleConfig();

		return (
			<div className={'generic-defaultlayout-main'}>
				<MessageToastLogin />
				<NavBar disabledPositionFixed={true} />
				<Container
					component="main"
					maxWidth={this.props.fullsizeContainer ? false : 'lg'}
					className="generic-defaultlayout-content"
					disableGutters={this.props.disableGutters}
				>
					<Typography align="center" variant="h2" component="h2" gutterBottom>
						{translate('user_register_register_new_account_title')}
					</Typography>
					<Form
						form={configuration.structure.form}
						formData={configuration.structure}
						initialValues={configuration.initialValues}
						handleSubmit={() => this.handleSubmitButton(configuration.structure.form)}
						actionDisabled={this.state.consented === '0'}
						handleBackButton={this.handleBackButton}
						backButton={true}
						buttonId={'users_register_new_account_submit_button'}
					/>
				</Container>
			</div>
		);
	}
}
const selector = formValueSelector('newuser');
const mapStateToProps = (state) => ({
	user: state.users.whoami,
	config: state.config,
	login: state.login,
	translate: getTranslate(state.localize),
	settings: state.settings,
	password_field: selector(state, 'login_password'),
	password_verify: selector(state, 'password_confirm'),
});

export default connect(mapStateToProps, { push, createNewAccount })(CreateNewAccount);
