import { LOGIN, LOGIN_BANKID, BANKID_PROGRESS, REFRESH_TOKEN } from '../components/Login/actions/types';
import { FETCH_UNREAD_TICKETS_DATA } from '../components/Tickets/actions/types';
import { MENU_ACTIVE_ACTION } from '../components/Users/actions/types';
import { refreshTokenAction, logoutAction } from '../components/Login/actions/loginActions';
import { displayModalAction } from '../components/Generic/actions/genericActions';
import { getTranslate } from 'react-localize-redux';

export default function refreshToken({ dispatch, getState }) {
	const timers = {};
	let flag = false;

	return (next) => (action) => {
		let settingsStore = getState().settings;
		let loginStore = getState().login;
		let publisherStore = getState().publisher;

		let translate = getTranslate(getState().localize);

		const maxInactiveTime =
			typeof settingsStore.site !== 'undefined'
				? settingsStore.site.max_inactive_time_milliseconds
					? settingsStore.site.max_inactive_time_milliseconds
					: 2700000
				: 2700000;

		const resetInactivity = () => {
			clearInterval(timers.expireToken);
			clearInterval(timers.onVideoTimer);
			timers.expireToken = setInterval(() => {
				if (publisherStore.session) {
					dispatch(
						displayModalAction({
							title: translate('inactivity_check_title'),
							description: translate('inactivity_check_description'),
							button_no: translate('generic_button_logout'),
							button_yes: translate('generic_yes'),
							action_no_button: () => {
								clearInterval(timers.expireToken);
								clearInterval(timers.onVideoTimer);
								dispatch(logoutAction());
							},
							action: resetInactivity,
						})
					);

					timers.onVideoTimer = setInterval(() => {
						dispatch(logoutAction());
					}, 300000);
				} else {
					dispatch(logoutAction());
				}
			}, maxInactiveTime);
		};

		if (loginStore.login || [LOGIN, LOGIN_BANKID].includes(action.type)) {
			switch (action.type) {
				case LOGIN:
				case LOGIN_BANKID:
					if (action.payload.login) {
						resetInactivity();
					} else {
						clearInterval(timers.expireToken);
						clearInterval(timers.onVideoTimer);
					}
					break;

				case REFRESH_TOKEN:
					resetInactivity();
					break;

				// Avoid refresh token on timed actions
				case MENU_ACTIVE_ACTION:
				case FETCH_UNREAD_TICKETS_DATA:
				case BANKID_PROGRESS:
					break;

				default:
					if (!action.type.startsWith('@@')) {
						const now = new Date();

						resetInactivity();

						var diff = loginStore.expire_date_time - now;

						var maxRemainingValidation =
							typeof settingsStore.site !== 'undefined'
								? settingsStore.site.remaining_minutes_before_refresh_token
									? settingsStore.site.remaining_minutes_before_refresh_token
									: 10
								: 10;

						// Use 1/4 of the expires_in value to refresh the token
						if (loginStore.expire_date_time && Math.round(((diff % 86400000) % 3600000) / 60000) <= maxRemainingValidation) {
							if (!flag) {
								dispatch(refreshTokenAction());
								flag = true;

								setTimeout(() => {
									flag = false;
								}, 2000);
							}
						}
						break;
					} else if (!action.type.startsWith('@@redux-form')) {
						resetInactivity();
					}
			}
		}

		// Call the next dispatch method in the middleware chain.
		const returnValue = next(action);

		// console.log('state after dispatch', getState());

		// This will likely be the action itself, unless
		// a middleware further in chain changed it.
		return returnValue;
	};
}
