import { FETCH_SETTINGS, ADD_PARAMS_SETTINGS, TRANSLATIONS_LABEL_TAG } from '../actions/types';
import { LOGIN } from '../components/Login/actions/types';

const initialState = {
	params: null,
	show_label_tag: false
};

export default function (state = initialState, action) {
	switch (action.type) {
		case FETCH_SETTINGS:
			return {
				...state,
				...action.payload,
			};
		case ADD_PARAMS_SETTINGS:
			return {
				...state,
				params: action.payload,
			};
		case TRANSLATIONS_LABEL_TAG:
			return {
				...state,
				show_label_tag: action.payload,
			};
		case LOGIN:
			return {
				...state,
				...(!action.payload.login && { params: null }),
			};
		default:
			return state;
	}
}
