import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { getTranslate } from 'react-localize-redux';
import { formValueSelector } from 'redux-form';

import { isIOS } from 'react-device-detect';
import { isMobileSize } from '../../helpers/functions/functions';
import { AppBar, Tabs, Tab, Typography, Box, Paper, Button } from '@material-ui/core';

import { Alert } from '@material-ui/lab';

import LoginForm from '../Login/LoginForm';
import LoginGuest from '../Login/LoginGuest';
import LoginBankID from '../Login/LoginBankID';
import LoginSITHS from '../Login/LoginSITHS';
import BankIDlogo from '../../assets/images/bankid.png';
import NetIDlogo from '../../assets/images/netid.png';

import TabContainer from '../Generic/TabContainer';

import { getMeetingAccessLoginAction, bankIDQRGeneratorMeetingAction } from '../Meetings/actions/meetingsActions';

class MeetingLoginTabs extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selected: '',
			loginMethods: [],
		};
	}

	componentDidMount() {
		let loginMethods = this.props.meeting.login_type == 'internal' ? ['password', 'bankid'] : ['guest', 'bankid'];

		this.setState({
			loginMethods: loginMethods,
			selected: loginMethods[0],
		});
	}

	handleChange = (event, newValue) => {
		if (newValue === 'bankid' && this.props.client !== null && this.props.client.id != '0' && !isMobileSize()) {
			this.props.bankIDQRGeneratorMeetingAction(this.props.selectedLanguage);
		}

		this.setState({
			selected: newValue,
		});
	};

	getMeetingLogin = (form) => {
		this.props.getMeetingAccessLoginAction(this.props.formUserInfo, this.props.meeting.token, form);
	};

	render() {
		const { translate } = this.props;
		const { theme } = this.props;

		return (
			<Paper elevation={this.props.client !== null && this.props.client.id == '0' ? 3 : 0} className="meeting-login-content-right-content-tabs">
				{this.state.loginMethods.length > 0 ? (
					<AppBar position="static" color="default">
						{this.state.loginMethods.length > 1 ? (
							<Tabs
								value={this.state.selected}
								onChange={this.handleChange}
								indicatorColor="primary"
								textColor="primary"
								variant="scrollable"
								scrollButtons="auto"
							>
								{this.state.loginMethods.map((method, key) => (
									<Tab key={key} label={translate('login_tab_' + method)} value={method} />
								))}
							</Tabs>
						) : null}
					</AppBar>
				) : null}
				{this.state.loginMethods.includes('guest') ? (
					<TabContainer parent="meeting_logins" value={this.state.selected} index="guest" dir={theme.direction} style={{ padding: '30px' }}>
						<LoginGuest selectedLanguage={this.props.selectedLanguage} handleSubmitAction={this.getMeetingLogin} />
					</TabContainer>
				) : (
					false
				)}
				{this.state.loginMethods.includes('password') ? (
					<TabContainer parent="meeting_logins" value={this.state.selected} index="password" dir={theme.direction} style={{ padding: '30px' }}>
						<LoginForm disableSelfRegistration={true} hideLinks={true} selectedLanguage={this.props.selectedLanguage} handleSubmitAction={this.getMeetingLogin} />
					</TabContainer>
				) : (
					false
				)}
				{this.state.loginMethods.includes('bankid') ? (
					<TabContainer parent="meeting_logins" value={this.state.selected} index="bankid" dir={theme.direction} style={{ padding: '30px' }}>
						{isIOS ? (
							<Alert className="login-bankid-alert-infobox" severity="info">
								{' '}
								{translate('login_bankid_alert_infobox')}{' '}
							</Alert>
						) : null}
						<img src={BankIDlogo} height="70" alt="BankID logo" />

						<LoginBankID selectedLanguage={this.props.selectedLanguage} handleSubmitAction={this.getMeetingLogin} type="login_meeting" />
					</TabContainer>
				) : (
					false
				)}
			</Paper>
		);
	}
}

const selector = formValueSelector('meeting_public_user_language');
const mapStateToProps = (state) => ({
	settings: state.settings.site,
	translate: getTranslate(state.localize),
	client: state.login.client,
	meeting: state.meetings,
	selectedLanguage: selector(state, 'language'),
});

export default withStyles(null, { withTheme: true })(
	connect(mapStateToProps, { bankIDQRGeneratorMeetingAction, getMeetingAccessLoginAction })(MeetingLoginTabs)
);
