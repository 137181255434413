import React, { Component } from 'react';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Form } from '../../../Generic';
import { push } from 'connected-react-router';
import { sendNewPasswordAction } from '../../actions/usersActions';
import { Grid, Typography, Container } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { formValueSelector } from 'redux-form';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

class PasswordForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			password: '',
			confirmPassword: '',
			match: null,
			charNumberValid: false,
			specialCharValid: false,
			uppercaseValid: false,
			numberValid: false,
			visibility: false,
			valid: false,
		};
	}
	// Check the length of the input
	checkPasswordLength = (password) => {
		if (
			typeof this.props.settings.password_complexity === 'undefined' ||
			this.props.settings.password_complexity === null ||
			password.length >= this.props.settings.password_complexity.length ||
			!this.props.settings.password_complexity.length
		) {
			this.setState({
				charNumberValid: true,
			});
		} else {
			this.setState({
				charNumberValid: false,
			});
		}
	};

	// Check for special characters
	checkSpecialCharacters = (password) => {
		const pattern = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
		const valSC = (password.match(pattern) || []).length;
		if (
			typeof this.props.settings.password_complexity === 'undefined' ||
			this.props.settings.password_complexity === null ||
			valSC >= this.props.settings.password_complexity.special_characters ||
			!this.props.settings.password_complexity.special_characters
		) {
			this.setState({
				specialCharValid: true,
			});
		} else {
			this.setState({
				specialCharValid: false,
			});
		}
	};

	// Check for an uppercase character
	checkUppercase = (password) => {
		const pattern = /[A-Z]/g;
		const valU = (password.match(pattern) || []).length;

		if (
			typeof this.props.settings.password_complexity === 'undefined' ||
			this.props.settings.password_complexity === null ||
			valU >= this.props.settings.password_complexity.uppercase ||
			!this.props.settings.password_complexity.uppercase
		) {
			this.setState({
				uppercaseValid: true,
			});
		} else {
			this.setState({
				uppercaseValid: false,
			});
		}
	};

	// Check for a number
	checkNumber = (password) => {
		const pattern = /[0-9]/g;
		const valN = (password.match(pattern) || []).length;
		if (
			typeof this.props.settings.password_complexity === 'undefined' ||
			this.props.settings.password_complexity === null ||
			valN >= this.props.settings.password_complexity.numbers ||
			!this.props.settings.password_complexity.numbers
		) {
			this.setState({
				numberValid: true,
			});
		} else {
			this.setState({
				numberValid: false,
			});
		}
	};
	handlePasswordChange = (value) => {
		this.checkPasswordLength(value);
		this.checkSpecialCharacters(value);
		this.checkUppercase(value);
		this.checkNumber(value);
		this.setState({ valid: this.props.password_verify === value });
	};

	handleDefaultValues = (config) => {
		let values = {};
		config.fields.filter((field) => typeof field.initialvalue !== 'undefined').map((field, i) => (values[field.name] = field.initialvalue));
		return values;
	};

	handleConfig = () => {
		const { translate } = this.props;

		const structure = {
			form: 'password',
			title: '',
			subtitle: '',
			helperText: '',
			maxWidth: 'sm',
			button_label: translate('user_change_new_password'),
			fields: [
				{
					id: 'password',
					name: 'password',
					label: translate('user_change_write_new_password'),
					type: 'inputadornment',
					inputType: this.state.visibility ? 'input' : 'password',
					validate: {
						required: true,
						functionValidation: [
							{
								validation: () => {
									return (
										this.state.charNumberValid &&
										this.state.specialCharValid &&
										this.state.uppercaseValid &&
										this.state.numberValid
									);
								},

								message: 'user_change_password_need_all_values_validate',
							},
						],
					},
					onChange: (value) => {
						this.handlePasswordChange(value);
					},
					adornment: {
						action: () =>
							this.setState({
								visibility: !this.state.visibility,
							}),
						triggered: false,
						alwaysEnabled: true,
						icon: this.state.visibility ? <Visibility /> : <VisibilityOff />,
					},
					initialvalue: '',
				},
				{
					id: 'password_verify',
					name: 'password_verify',
					label: translate('user_change_password_write_repeat'),
					type: 'password',
					validate: {
						required: true,
						functionValidation: [
							{
								validation: () => {
									return this.state.valid;
								},

								message: 'user_change_password_confirm_validate',
							},
						],
					},
					onChange: (value) => {
						this.setState({ valid: this.props.password_field === value });
					},
					initialvalue: '',
				},
			],
		};
		let initialValues = this.handleDefaultValues(structure);

		return { structure: structure, initialValues: initialValues };
	};

	handleBackButton = () => {
		this.props.push('/mypages');
	};

	render() {
		const { translate } = this.props;
		const configuration = this.handleConfig();

		return (
			<div>
				<Grid container>
					<Grid xs={12} md={12}>
						<Form
							form={configuration.structure.form}
							formData={configuration.structure}
							initialValues={configuration.initialValues}
							handleSubmit={() =>
								this.props.sendNewPasswordAction(configuration.structure.form, () => {
									this.props.handleRecovery();
									this.props.handleValidateCode();
								})
							}
							backButton={true}
							handleBackButton={this.handleBackButton}
						/>
					</Grid>
					<Container maxWidth="sm">
						{!this.props.settings.password_complexity ? (
							''
						) : (
							<Grid xs={12} md={12}>
								{!this.props.settings.password_complexity.length || !this.props.settings.password_complexity.length === 0 ? (
									''
								) : (
									<Typography align="justify" variant="subtitle2" paragraph={true}>
										{this.state.charNumberValid ? <CheckIcon color="primary" /> : <ClearIcon color="error" />}
										{translate('user_change_password_carachters', {
											length: this.props.settings.password_complexity.length,
										})}
									</Typography>
								)}
								{!this.props.settings.password_complexity.special_characters ||
								this.props.settings.password_complexity.special_characters === 0 ? (
									''
								) : (
									<Typography align="justify" variant="subtitle2" paragraph={true}>
										{this.state.specialCharValid ? <CheckIcon color="primary" /> : <ClearIcon color="error" />}
										{translate('user_change_password_special_carachters', {
											length: this.props.settings.password_complexity.special_characters,
										})}
									</Typography>
								)}
								{!this.props.settings.password_complexity.uppercase || this.props.settings.password_complexity.uppercase === 0 ? (
									''
								) : (
									<Typography align="justify" variant="subtitle2" paragraph={true}>
										{this.state.uppercaseValid ? <CheckIcon color="primary" /> : <ClearIcon color="error" />}
										{translate('user_change_password_uppercase_letter', {
											length: this.props.settings.password_complexity.uppercase,
										})}
									</Typography>
								)}
								{!this.props.settings.password_complexity.numbers || this.props.settings.password_complexity.numbers === 0 ? (
									''
								) : (
									<Typography align="justify" variant="subtitle2" paragraph={true}>
										{this.state.numberValid ? <CheckIcon color="primary" /> : <ClearIcon color="error" />}
										{translate('user_change_password_number', {
											length: this.props.settings.password_complexity.numbers,
										})}
									</Typography>
								)}
							</Grid>
						)}
					</Container>
				</Grid>
			</div>
		);
	}
}
const selector = formValueSelector('password');
const mapStateToProps = (state) => ({
	translate: getTranslate(state.localize),
	password_field: selector(state, 'password'),
	password_verify: selector(state, 'password_verify'),
	settings: state.settings.site,
});

export default connect(mapStateToProps, { sendNewPasswordAction, push })(PasswordForm);
