import { store } from '../../../store.js';
import { LIGHTBOX_ACTION } from '../../Generic/actions/types';
import { openPdfFIle } from '../../../helpers/functions/functions';

export const fetchLinkTypeAction = (params) => (dispatch) => {
	const state = store.getState();

	let url = state.config.apihost;

	switch (params.link_type) {
		case 'files':
			url = url + params.link;
			break;
		default:
			break;
	}

	fetch(url, {
		headers: { Authorization: 'Bearer ' + state.login.token }
	})
	.then(resp => resp.blob())
	.then(blob => {
		const fileURL = URL.createObjectURL(blob);

            if(blob.type === 'application/pdf') {
                openPdfFIle(fileURL);
            } else {
                dispatch({
                    type: LIGHTBOX_ACTION,
                    payload: {
                        open: true,
                        image: [
                            {
                                src: fileURL,
                                thumbnail: fileURL,
                                thumbnailWidth: 0,
                                thumbnailHeight: 0
                            }
                        ]
                    }
                });
            }
	})
	.catch(() => alert('oh no!'));
}