import axios from 'axios';
import {
	LOGIN,
	LOGIN_BANKID,
	BANKID_PROGRESS,
	REFRESH_TOKEN,
	ADBLOCK_WARNING,
	SELECT_CLIENT,
	BANKID_QRCODE,
	CLEAR_REDUCER,
	LOGIN_SELF_REGISTRATION,
	LOGIN_CONFIRMED,
} from './types';
import { store } from '../../../store.js';
import { whoamiAction, cleanWhoamiAction } from '../../Users/actions/usersActions';
import { addDelayAction, convertToFormData } from '../../Generic/actions/genericActions';
import eventsService from '../../../helpers/Events';
import { getTranslate } from 'react-localize-redux';
import { setPromptDialogIsDirty } from '../../Generic/actions/promptActions';
import { isMobileSize } from '../../../helpers/functions/functions';
import { getActiveLanguage } from 'react-localize-redux';
import { push } from 'connected-react-router';
import { reset } from 'redux-form';
import { dateToString } from '../../../helpers/functions/functions';

let checkBankIdProgressTimer = null;
let checkBankIdProgressTries = 0;

export const loginAction = (form) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	var formData = new FormData();
	formData.append('grant_type', 'password');
	formData.append('username', state.form[form].values.login_email);
	formData.append('password', state.form[form].values.login_password);
	formData.append('language', state.form[form].values.selectedLanguage);
	formData.append('client_id', state.login.client.id);

	axios
		.post(state.config.apihost + `/login`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
		.then((res) => {
			let expireDate = new Date();
			expireDate.setSeconds(expireDate.getSeconds() + res.data.expires_in);

			if (res.data.confirmed === false) {
				dispatch(push('/login'));

				dispatch({
					type: LOGIN,
					payload: {
						login: false,
						token: res.data.access_token,
						refresh_token: res.data.refresh_token,
						expires_in: res.data.expires_in,
						expire_date_time: expireDate,
						confirmed: res.data.confirmed,
						email_confirm: state.form[form].values.login_email,
					},
				});
			} else {
				if (state.login.platform === 'backoffice' && !res.data.scopes.includes('admin.access')) {
					var err = new Error(translate('login_backoffice_no_access'));
					err.name = 'login_backoffice_no_access';
					throw err;
				}

				dispatch({
					type: LOGIN,
					payload: {
						login: true,
						token: res.data.access_token,
						refresh_token: res.data.refresh_token,
						expires_in: res.data.expires_in,
						expire_date_time: expireDate,
						confirmed: res.data.confirmed,
					},
				});

				dispatch(whoamiAction(true));
				dispatch(handleAdblockWarning(true));
			}
		})
		.then(() => {
			if (state.login.platform === 'backoffice') {
				dispatch(setAdminTokenAction());
			}
		})
		.catch((err) => {
			if (err.request && err.request.status === 401) {
				eventsService.triggerEvent('alert_login', { type: 'error', message: JSON.parse(err.request.responseText).error });
			} else if (err.name && err.name === 'login_backoffice_no_access') {
				eventsService.triggerEvent('alert_login', { type: 'error', message: err.message });
			} else {
				eventsService.triggerEvent('alert_login', { type: 'error', message: translate('alert_post_login_no_response') });
			}

			dispatch({
				type: LOGIN,
				payload: {
					login: false,
					token: '',
					refresh_token: '',
					expires_in: null,
					expire_date_time: null,
					confirmed: true,
				},
			});
		});
};

export const refreshTokenAction = () => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	var form = new FormData();

	form.append('token', state.login.refresh_token);

	axios
		.post(state.config.apihost + `/login/refresh`, form, {
			headers: { 'Content-Type': 'multipart/form-data', Authorization: 'Bearer ' + state.login.token },
		})
		.then((res) => {
			let expireDate = new Date();
			expireDate.setSeconds(expireDate.getSeconds() + res.data.expires_in);

			dispatch({
				type: REFRESH_TOKEN,
				payload: {
					login: true,
					token: res.data.access_token,
					refresh_token: res.data.refresh_token,
					expires_in: res.data.expires_in,
					expire_date_time: expireDate,
				},
			});

			dispatch(handleAdblockWarning(true));
		})
		.catch((err) => {
			eventsService.triggerEvent('alert_login', { type: 'error', message: translate('alert_post_login_no_response') });
			dispatch(logoutAction());
		});
};

export const checkBankIdProgress = () => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	let qrCode = document.getElementById('bankid_qrcode');

	axios
		.get(state.config.apihost + `/auth/logins/bankid/` + state.login.session_id)
		.then((res) => {
			let expireDate = new Date();
			expireDate.setSeconds(expireDate.getSeconds() + res.data.expires_in);

			if (res.data.confirmed === false) {
				clearInterval(checkBankIdProgressTimer);
				dispatch(push('/login'));
				dispatch({
					type: LOGIN_BANKID,
					payload: {
						login: false,
						token: res.data.access_token,
						refresh_token: res.data.refresh_token,
						expires_in: res.data.expires_in,
						expire_date_time: expireDate,
						confirmed: res.data.confirmed,
						bankid_progress: false,
					},
				});
			} else {
				if (typeof res.data.access_token !== 'undefined') {
					let expireDate = new Date();
					expireDate.setSeconds(expireDate.getSeconds() + res.data.expires_in);

					clearInterval(checkBankIdProgressTimer);

					dispatch({
						type: LOGIN_BANKID,
						payload: {
							login: true,
							token: res.data.access_token,
							refresh_token: res.data.refresh_token,
							expires_in: res.data.expires_in,
							expire_date_time: expireDate,
							bankid_progress: false,
							confirmed: true,
						},
					});

					if (state.login.platform === 'backoffice') {
						if (!res.data.scopes.includes('admin.access')) {
							var err = new Error(translate('login_backoffice_no_access'));
							err.name = 'login_backoffice_no_access';
							throw err;
						}

						dispatch(setAdminTokenAction());
					}

					dispatch(whoamiAction(true));
				} else if (checkBankIdProgressTries >= 60) {
					clearInterval(checkBankIdProgressTimer);
					checkBankIdProgressTries = 0;
					dispatch({
						type: BANKID_PROGRESS,
						payload: {
							bankid_progress: false,
							session_id: '',
						},
					});

					if (qrCode !== null) {
						qrCode.classList.add('login-bankid-qrcode-disabled');
					}
				} else {
					checkBankIdProgressTries++;
				}
			}
		})
		.catch((err) => {
			if (err.request && err.request.status === 401) {
				eventsService.triggerEvent('alert_login', { type: 'error', message: JSON.parse(err.request.responseText).error });
			} else if (err.name && err.name === 'login_backoffice_no_access') {
				eventsService.triggerEvent('alert_login', { type: 'error', message: err.message });
			} else {
				eventsService.triggerEvent('alert_login', { type: 'error', message: translate('alert_post_login_no_response') });
			}

			clearInterval(checkBankIdProgressTimer);
			dispatch({
				type: BANKID_PROGRESS,
				payload: {
					bankid_progress: false,
					session_id: '',
				},
			});
			if (qrCode !== null) {
				qrCode.classList.add('login-bankid-qrcode-disabled');
			}
		});
};

export const loginBankIDAction = (form, selectedLanguage) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	var formData = new FormData();

	formData.append('grant_type', 'bankid');
	formData.append('ssn', state.form[form].values.bankid_ssn);
	formData.append('language', selectedLanguage);
	formData.append('client_id', state.login.client.id);

	axios
		.post(state.config.apihost + `/login`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
		.then((result) => {
			dispatch({
				type: BANKID_PROGRESS,
				payload: {
					bankid_progress: true,
					session_id: result.data.sessionId,
				},
			});

			let qrCode = document.getElementById('bankid_qrcode');
			if (qrCode !== null) {
				qrCode.classList.remove('login-bankid-qrcode-disabled');
			}

			clearInterval(checkBankIdProgressTimer);
			checkBankIdProgressTimer = null;

			checkBankIdProgressTimer = setInterval(() => dispatch(checkBankIdProgress()), 1000);

			if (isMobileSize()) {
				window.location.replace('bankid://' + result.data.autoStartToken);
			}
		})
		.catch((err) => {
			eventsService.triggerEvent('alert_login', { type: 'error', message: translate('alert_post_login_no_response') });
			dispatch({
				type: LOGIN,
				payload: {
					login: false,
					token: '',
					refresh_token: '',
					expires_in: null,
					expire_date_time: null,
				},
			});
		});
};

export const bankIDQRGeneratorAction = (selectedLanguage) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	var formData = new FormData();

	formData.append('grant_type', 'bankid');
	formData.append('language', selectedLanguage);
	formData.append('client_id', state.login.client.id);

	axios
		.post(state.config.apihost + `/login`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
		.then((result) => {
			dispatch({
				type: BANKID_QRCODE,
				payload: {
					session_id: result.data.sessionId,
					autostarttoken: result.data.autoStartToken,
				},
			});

			let qrCode = document.getElementById('bankid_qrcode');
			if (qrCode !== null) {
				qrCode.classList.remove('login-bankid-qrcode-disabled');
			}

			clearInterval(checkBankIdProgressTimer);
			checkBankIdProgressTimer = null;

			checkBankIdProgressTimer = setInterval(() => dispatch(checkBankIdProgress()), 1000);
		})
		.catch((err) => {
			eventsService.triggerEvent('alert_login', { type: 'error', message: translate('alert_post_login_no_response') });
			dispatch({
				type: LOGIN,
				payload: {
					login: false,
					token: '',
					refresh_token: '',
					expires_in: null,
					expire_date_time: null,
				},
			});
		});
};

export const loginNetIDAction = (values) => (dispatch) => {};

export const checkNetIdProgress = () => (dispatch) => {};

export const logoutAction =
	(keepClient = false, redirect = true) =>
	(dispatch) => {
		const state = store.getState();
		if (state.prompt.is_dirty) {
			dispatch(
				setPromptDialogIsDirty({
					is_dirty: false,
				})
			);
		}

		axios
			.post(state.config.apihost + `/logout`, {
				headers: { 'Content-Type': 'multipart/form-data', Authorization: 'Bearer ' + state.login.token },
			})
			.catch((err) => {
				eventsService.triggerEvent('alert_login', { type: 'error', message: err });
			});

		Promise.resolve(
			dispatch({
				type: LOGIN,
				payload: {
					login: false,
					token: '',
					refresh_token: '',
					expires_in: null,
					expire_date_time: null,
					self_registration: false,
					confirmed: true,
					...(!keepClient && { client: null }),
				},
			})
		)
			.then(() => (redirect ? dispatch(push('/login')) : null))
			.then(() => dispatch({ type: CLEAR_REDUCER }))
			.then(() => dispatch(cleanWhoamiAction()));
		dispatch(handleAdblockWarning(false));
	};

export const prepareStoreLogoutAction = () => (dispatch) => {
	const state = store.getState();

	if (state.prompt.is_dirty) {
		dispatch(
			setPromptDialogIsDirty({
				is_dirty: false,
			})
		);
	}

	dispatch({
		type: LOGIN,
		payload: {
			login: false,
			token: '',
			refresh_token: '',
			expires_in: null,
			expire_date_time: null,
			client: null,
			platform: 'backoffice',
			confirmed: true,
		},
	});
	dispatch(cleanWhoamiAction());
	dispatch(handleAdblockWarning(false));
};

export const setAdminTokenAction = () => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	var form = new FormData();

	form.append('access_token', state.login.token);
	form.append('refresh_token', state.login.refresh_token);
	form.append('expires_in', state.login.expires_in);
	form.append('expire_date_time', state.login.expire_date_time);

	axios
		.post(state.config.adminhost + `/login`, form, { headers: { 'Content-Type': 'multipart/form-data' } })
		.then((res) => {
			dispatch(prepareStoreLogoutAction());
		})
		.then(() => {
			window.location.href = state.config.adminhost;
		})
		.catch((err) => {
			eventsService.triggerEvent('alert_login', { type: 'error', message: translate('alert_post_admin_no_response') });
		});
};

export const handleAdblockWarning = (value) => (dispatch) => {
	setTimeout(function () {
		dispatch({
			type: ADBLOCK_WARNING,
			payload: {
				adblock_warning: value,
			},
		});
	}, 300);
};

export const handleSelectClientAction =
	(client, platform, method = null) =>
	(dispatch) => {
		const state = store.getState();

		if (client !== null && client.page_title) {
			document.title = client.page_title;
		} else {
			document.title = state.settings.site.page_title;
		}

		dispatch({
			type: SELECT_CLIENT,
			payload: {
				client,
				platform,
			},
		});

		var language = getActiveLanguage(state.localize);

		if (platform == 'backoffice' && method == 'bankID' && !isMobileSize()) {
			dispatch(addDelayAction(bankIDQRGeneratorAction(language.code)));
		}
	};
export const createNewAccount = (form) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	if (typeof state.form[form].values.birthdate !== 'undefined') {
		state.form[form].values.birthdate = dateToString(
			new Date(
				state.form[form].values.birthdate.toLocaleString('en-US', {
					year: 'numeric',
					month: 'numeric',
					day: 'numeric',
					hour: 'numeric',
					minute: 'numeric',
					hour12: true,
				})
			)
		);
	}

	state.form[form].values.consented = state.form[form].values.consented ? '1' : '0';

	let data = convertToFormData(state.form[form].values);

	data.append('email', state.form[form].values.login_email);
	data.append('password', state.form[form].values.login_password);

	axios
		.post(state.config.apihost + `/users/register`, data)
		.then((res) => {
			dispatch(
				addDelayAction(reset(form), () =>
					eventsService.triggerEvent('alert_login', {
						type: 'success',
						message: translate('alert_success_new_user_created'),
					})
				)
			);
			dispatch({
				type: LOGIN_SELF_REGISTRATION,
				payload: {
					self_registration: true,
					email_confirm: res.data.email,
				},
			});
			dispatch(loginAction(form));
		})

		.catch((err) => {
			console.log(err);
			console.log('Error fetching data.');

			dispatch(addDelayAction(null, () => eventsService.triggerEvent('alert_login', { type: 'error', message: err.message })));
		});
};

export const handleLoginConfirmed = (value) => (dispatch) => {
	dispatch({
		type: LOGIN_CONFIRMED,
		payload: {
			confirmed: value,
			email_confirm: '',
		},
	});
};

export const sendVerifyTokenAction = (form) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	var body = new FormData();
	body.append('token', state.form[form].values.token);
	body.append('medium', 'email');
	body.append('value', state.form[form].values.email);

	axios
		.post(state.config.apihost + `/users/contact/verify`, body)
		.then((res) => {
			let expireDate = new Date();
			expireDate.setSeconds(expireDate.getSeconds() + res.data.expires_in);
			dispatch(
				addDelayAction(reset(form), () =>
					eventsService.triggerEvent(state.login.login ? 'alert' : 'alert_login', {
						type: 'success',
						message: translate('alert_success_verify'),
					})
				)
			);
			dispatch({
				type: LOGIN,
				payload: {
					login: true,
					token: res.data.access_token,
					refresh_token: res.data.refresh_token,
					expires_in: res.data.expires_in,
					expire_date_time: expireDate,
					confirmed: res.data.confirmed,
				},
			});

			dispatch(whoamiAction(true));
			dispatch(handleAdblockWarning(true));
		})
		.catch((err) => {
			console.log(err);
			console.log('Error fetching data.');

			dispatch(
				addDelayAction(null, () =>
					eventsService.triggerEvent(state.login.login ? 'alert' : 'alert_login', {
						type: 'error',
						message: translate(err.response.data.message),
					})
				)
			);
		});
};
