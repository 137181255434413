import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchLinkTypeAction } from './actions/chatActions';
import { setChannelAction, setChannelHistoryAction, sendChannelMessageAction, clearChannelAction } from './actions/websocketsActions';
import { displayModalAction } from '../Generic/actions/genericActions';
import { fetchTicketFollowupQuestionnaireAction, fetchTicketAction } from '../Tickets/actions/ticketsActions';

export const SocketContext = React.createContext();

class SocketProvider extends Component {
	constructor() {
		super();
		this.state = {
			ws: null,
		};
	}

	componentDidMount() {
		if(!this.props.already_connected) {
			this.connect();
		}
	}

	componentWillUnmount() {
		if (this.state.ws !== null) {
			this.state.ws.close(4005);
		}
	}

	timeout = 250; // Initial timeout duration as a class variable

	/**
	 * @function connect
	 * This function establishes the connect with the websocket and also ensures constant reconnection if connection closes
	 */
	connect = () => {
		// var ws = new WebSocket('wss://alteri-api.commerz.se/ws');
		var ws = new WebSocket(this.props.config.websocket_protocol + `://` + this.props.config.websockethost);

		let that = this; // cache the this
		var connectInterval;

		// websocket onopen event listener
		ws.onopen = () => {
			var nowTimezone = new Date().toLocaleString('en-US', { timeZone: this.props.timezone });
			var connection_date = new Date(nowTimezone);
			that.setState({
				connection_date,
			});

			let first = { type: 'authentication', channel: this.props.channel, token: this.props.token };

			this.setState({ ws: ws });

			that.timeout = 250; // reset timer to 250 on open of websocket connection
			clearTimeout(connectInterval); // clear Interval on on open of websocket connection

			// send greeting
			ws.send(JSON.stringify(first));

			setTimeout(() => {
				ws.send(JSON.stringify({ type: 'command', command: 'list' }));

				let arr = that.props.messages;

				/**
				 * FIXME: Add api fetch instead to get last 10 or so
				 */
				
				if (this.props.customFirstMessage) {
					let msg = this.props.customFirstMessage();
					arr.push(JSON.parse(msg));
				}

				this.props.setChannelAction(this.props.channel_name, this.props.channel, arr, connection_date);
			}, 1000);
		};

		// websocket onclose event listener
		ws.onclose = (e) => {
			this.props.clearChannelAction(this.props.channel_name);

			if (e.code !== 4005) {
				that.timeout = that.timeout + that.timeout; //increment retry interval
				connectInterval = setTimeout(this.check, Math.min(10000, that.timeout)); //call check function after timeout
			}
		};

		// websocket onerror event listener
		ws.onerror = (err) => {
			ws.close();
		};

		ws.onmessage = (msg) => {
			var data = JSON.parse(msg.data);
			
			if (data.type === 'history') {
				if (this.props.handleFilterMessages && Array.isArray(data.message)) data.message = data.message.filter(this.props.handleFilterMessages);
				this.props.setChannelHistoryAction(this.props.channel_name, data.message);
			} else {
				if (this.props.handleFilterMessages) {
					if (this.props.handleFilterMessages(data)) {
						this.props.sendChannelMessageAction(this.props.channel_name, data);
					}
				} else {
					this.props.sendChannelMessageAction(this.props.channel_name, data);
				}
				if (this.props.handleSendMessage) this.props.handleSendMessage(data);
				
			}
		};
	};

	/**
	 * utilited by the @function connect to check if the connection is close, if so attempts to reconnect
	 */
	check = () => {
		const { ws } = this.state;
		if (!ws || ws.readyState === WebSocket.CLOSED) this.connect(); //check if websocket instance is closed, if so call `connect` function.
	};

	render() {
		return <SocketContext.Provider value={{ ws: this.state.ws }}>{this.props.children}</SocketContext.Provider>;
	}
}

const mapStateToProps = (state, ownProps) => ({
	messages: state.websockets[ownProps.channel_name].messages,
	config: state.config,
	token: state.login.token,
	timezone: state.users.whoami.timezone,
});

export default connect(mapStateToProps, {
	setChannelAction,
	setChannelHistoryAction,
	sendChannelMessageAction,
	clearChannelAction,
	fetchLinkTypeAction,
	displayModalAction,
	fetchTicketFollowupQuestionnaireAction,
	fetchTicketAction,
})(SocketProvider);

SocketProvider.propTypes = {
	channel_name: PropTypes.string.isRequired,
	channel: PropTypes.string.isRequired,
	handleSendMessage: PropTypes.func,
	customFirstMessage: PropTypes.func,
	handleFilterMessages: PropTypes.func
};