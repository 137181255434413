import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import PlaceholderIMG from '../../assets/images/placeholder.svg';

const useStyles = makeStyles({
	'fullsize-loading-wrapper': {
		backgroundColor: 'white',
		height: '100vh',
		justifyContent: 'center',
		textAlign: 'center',
		display: 'flex',
		zIndex: '9999999',
	},
	'fullsize-loading-app-logo': {
		position: 'absolute',
		top: '50%',
		transform: 'translateY(-50%)',
		height: '30%',
	},
	'fullsize-loading-spinner': {
		width: '50px',
		position: 'absolute',
		height: '50px',
		top: '70%',
	},
});

function FullsizeLoading(props) {
	const classes = useStyles(props);
	
	return (
		<div className={classes['fullsize-loading-wrapper']}>
			{props.logo === false ? null : (
				<img
					className={classes['fullsize-loading-app-logo']}
					src={
						typeof props.settings.site !== 'undefined' && props.settings.site.files.splash_screen_logo 
							? props.config.apihost + '/configuration/files/' + props.settings.site.files.splash_screen_logo.uuid
							: PlaceholderIMG
					}
					alt="Alteri's logo"
				/>
			)}
			<CircularProgress className={classes['fullsize-loading-spinner']} color="primary" />
		</div>
	);
}

const mapStateToProps = (state) => ({
	config: state.config,
	settings: state.settings,
});

export default connect(mapStateToProps)(FullsizeLoading);
