import axios from 'axios';
import { store } from '../store.js';
import { FETCH_SETTINGS, ADD_PARAMS_SETTINGS, TRANSLATIONS_LABEL_TAG } from '../actions/types';
import { handleSplashScreenAction } from '../components/Generic/actions/genericActions';
import { addTranslationForLanguage } from 'react-localize-redux';
import { setActiveLanguage } from 'react-localize-redux';
import { fetchTranslationsAction } from './translationsActions';

export const fetchSettingsAction = () => (dispatch) => {
	const state = store.getState();

	dispatch(handleSplashScreenAction(true));

	axios
		.get(state.config.apihost + `/configuration`)
		.then((res) => {

			dispatch({
				type: FETCH_SETTINGS,
				payload: res.data,
			});

			return res.data;
		})
		.catch((err) => {
			console.log(err);
			console.log('Error fetching data.');
		});
};

export const handleTranslationsAction = () => (dispatch) => {
	const state = store.getState();
	
	dispatch(handleSplashScreenAction(true));

	Object.keys(state.settings.translations).forEach((key) => {
		dispatch(addTranslationForLanguage(state.settings.translations[key], key));
	});
	var codes = state.settings.site.available_languages.reduce((array, object) => array.concat(object.code), []);

	var language =
		state.settings.params !== null && state.settings.params.language && codes.includes(state.settings.params.language)
			? state.settings.params.language
			: state.settings.site.default_language;

	dispatch(setActiveLanguage(language));
	document.getElementsByTagName('html')[0].setAttribute('lang', language.split('-')[0]);

	dispatch(handleSplashScreenAction(false));
}

export const addSettingsAction = (params) => (dispatch) => {
	dispatch({
		type: ADD_PARAMS_SETTINGS,
		payload: params,
	});
};

export const showTranslationsLabelAction = () => (dispatch) => {
	const state = store.getState();
	dispatch({
		type: TRANSLATIONS_LABEL_TAG,
		payload: !state.settings.show_label_tag,
	});
};

export const handleToggleTranslations = () => (dispatch) => {
	const state = store.getState();
	
	dispatch(showTranslationsLabelAction());
	
	if(state.settings.show_label_tag) {
		dispatch(handleTranslationsAction());
		dispatch(fetchTranslationsAction('raw'));
	}
	else {
		dispatch(handleTranslationsAction());
		dispatch(fetchTranslationsAction(state.users.whoami.language ? state.users.whoami.language : state.settings.site.default_language));
	}
}
