const adblockwarning = (theme) => {
	return {
		'.adblockwarning-hidden': {
			display: 'none !important',
		},
		'.adblockwarning': {
			opacity: '1',
			// background: 'rgba(33,41,52,.85)',
			bottom: '0',
			// display: 'flex',
			left: ' 0',
			// position: 'fixed',
			right: '0',
			top: '0',
			zIndex: '1300',
			transition: 'background .6s',
            padding: '0px',
			display: 'block !important',
		},
		'.adblockwarning-main': {
			position: 'fixed',
			bottom: '0',
			width: '100%',
			background: '#e0e0e0',
			// height: '10%',
            padding: '10px',
            [theme.breakpoints.down('sm')]: {
				textAlign: 'center'
			}
		},
	};
};

export default adblockwarning;
