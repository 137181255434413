import React, { Component } from 'react';
import { getTranslate, getLanguages, getActiveLanguage } from 'react-localize-redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ExitToApp as ExitToAppIcon, Send as SendIcon } from '@material-ui/icons';
import { TextField, MenuItem, Box, Button, Typography } from '@material-ui/core';
import { logoutAction, setAdminTokenAction, handleSelectClientAction } from './actions/loginActions';
import LoginTabs from './LoginTabs';
import MadeBy from './MadeBy';
import { setActiveLanguage } from 'react-localize-redux';
import { push } from 'connected-react-router';
import { setPromptDialogIsDirty } from '../Generic/actions/promptActions';
import EmailConfirm from './EmailConfirm';

class LoginPanel extends Component {
	getAvailableLanguages = () => {
		const languages = this.props.languages;
		return languages;
	};

	state = {
		form: true,
		message: '',
	};

	componentDidMount() {
		if (typeof this.props.user === 'object' && this.props.login) {
			this.handleRedirects();
		}
	}

	componentDidUpdate() {
		if (typeof this.props.user === 'object' && this.props.login) {
			this.handleRedirects();
		}
	}

	handleChangeLanguage = (value) => {
		this.props.setActiveLanguage(value);
	};

	handleRedirects = () => {
		if (this.props.login && this.props.token.length > 0) {
			if (this.props.user.status === '10') {
				this.props.push('/myprofile');
				this.props.setPromptDialogIsDirty({
					is_dirty: true,
					path_allowed: ['/myprofile'],
					prompt_dialog_title: 'prompt_user_incomplete_title',
					prompt_dialog_description: 'prompt_user_incomplete_description',
					allow_resume: false,
				});
			} else {
				if (this.props.settings.feature.dashboard && this.props.settings.params == null) {
					this.props.push('/dashboard');
				} else if (this.props.user.scopes.includes('lists.all_cases')) {
					this.props.push('/allcases');
				} else if (this.props.user.scopes.includes('practitioner')) {
					this.props.push('/mycases');
				} else if (this.props.user.scopes.includes('customer')) {
					if (this.props.settings.feature.dashboard && this.props.settings.params === null) {
						this.props.push('/dashboard');
					} else if (
						(this.props.settings.params !== null && typeof this.props.settings.params.conditions !== 'undefined') ||
						this.props.user.ticket_count === 0
					) {
						this.props.push('/conditions');
					} else {
						this.props.push('/mycases');
					}
				} else {
					this.props.push('/mycases');
				}
			}
		} else {
			this.props.logoutAction();
		}
	};

	output = () => {
		return <LoginTabs message={this.state.message} selectedLanguage={this.props.currentLanguage ? this.props.currentLanguage.code : ''} />;
	};

	render() {
		return (
			<div className={this.props.platform === 'backoffice' ? '' : 'login-content-right-main'}>
				{typeof this.props.client === 'undefined' ||
				this.props.client === null ||
				(this.props.client.id === 0 && this.props.platform === 'frontoffice') ? (
					<div className="login-content-right-content-buttons">
						<Typography component="h1" variant="h5">
							{this.props.translate('login_select_client_title')}
						</Typography>
						{typeof this.props.settings.clients !== 'undefined' &&
							this.props.settings.clients.map((client, key) =>
								client.public == '1' ? (
									<Box width="100%" mt={5} key={key}>
										<Button
											key={client.id}
											variant="outlined"
											color="primary"
											className="login-content-right-button-clients"
											onClick={() => this.props.handleSelectClientAction(client, 'frontoffice')}
										>
											{client.name}
											<SendIcon />
										</Button>
									</Box>
								) : (
									''
								)
							)}
					</div>
				) : (
					<div className={this.props.platform === 'backoffice' ? 'login-content-right-content-backoffice' : 'login-content-right-content'}>
						{this.props.client !== null && this.props.platform === 'frontoffice' ? (
							<Typography component="h2" variant="h2" gutterBottom>
								{this.props.client.name}
							</Typography>
						) : (
							this.props.platform === 'backoffice' && (
								<Typography component="h2" variant="h2" gutterBottom>
									{this.props.translate('login_choose_site_button_backoffice')}
								</Typography>
							)
						)}
						{this.props.confirmed ? this.output() : <EmailConfirm />}
					</div>
				)}
				<div className={this.props.platform === 'backoffice' ? '' : 'login-content-right-footer'}>
					<TextField
						FormHelperTextProps={{
							...(this.props.platform === 'backoffice' && { className: 'login-backoffice-language-selector' }),
						}}
						InputLabelProps={{
							...(this.props.platform === 'backoffice' && { className: 'login-backoffice-language-selector' }),
						}}
						SelectProps={{
							...(this.props.platform === 'backoffice' && { className: 'login-backoffice-language-selector' }),
						}}
						select
						label={this.props.translate('generic_select')}
						value={this.props.currentLanguage ? this.props.currentLanguage.code : ''}
						onChange={(value) => this.handleChangeLanguage(value.target.value)}
						helperText={this.props.translate('login_select_preferred_language')}
						margin="normal"
						variant="outlined"
					>
						{this.props.languages.map((option) => (
							<MenuItem key={option.code} value={option.code}>
								{this.props.translate('language_' + option.label)}
							</MenuItem>
						))}
					</TextField>
					<Box mt={this.props.platform === 'backoffice' ? 0 : 5}>
						<MadeBy />
					</Box>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	settings: state.settings,
	login: state.login.login,
	token: state.login.token,
	platform: state.login.platform,
	confirmed: state.login.confirmed,
	client: state.login.client,
	config: state.config,
	user: state.users.whoami,
	languages: getLanguages(state.localize),
	translate: getTranslate(state.localize),
	currentLanguage: getActiveLanguage(state.localize),
});

export default connect(mapStateToProps, {
	logoutAction,
	setAdminTokenAction,
	setActiveLanguage,
	handleSelectClientAction,
	push,
	setPromptDialogIsDirty,
})(withRouter(LoginPanel));
