const snackbar = (theme) => {
	return {
		'.snackbar-variant-info': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText
        },
	};
};

export default snackbar;
