import React, { Component } from 'react';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Typography, Collapse } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import PasswordValidate from './PasswordValidate';
import PasswordForm from './PasswordForm';

class Password extends Component {
	constructor(props) {
		super(props);

		this.state = {
			validate: false,
		};
	}

	handleValidateCode = () => {
		this.setState({
			validate: !this.state.validate,
		});
	};

	render() {
		const { translate } = this.props;

		return (
			<div>
				{!this.props.hiddeTitles ? (
					<React.Fragment>
						<Typography align="center" variant="h2" component="h2" gutterBottom>
							{translate('users_change_password_title')}
						</Typography>
						<Typography align="center" variant="subtitle2" component="p" gutterBottom>
							{translate('users_change_password_subtitle')}
						</Typography>
					</React.Fragment>
				) : (
					''
				)}
				<Collapse in={!this.state.validate}>
					<PasswordValidate handleValidateCode={this.handleValidateCode} />
				</Collapse>
				<Collapse in={this.state.validate}>
					<PasswordForm handleValidateCode={this.handleValidateCode} handleRecovery={this.props.handleRecovery} />
				</Collapse>
			</div>
		);
	}
}
const mapStateToProps = (state) => ({
	translate: getTranslate(state.localize),
});

export default connect(mapStateToProps)(withRouter(Password));
