export const HANDLE_SPLASH_SCREEN = 'HANDLE_SPLASH_SCREEN';
export const HANDLE_LOADING = 'HANDLE_LOADING';
export const HANDLE_TABLE_LOADING = 'HANDLE_TABLE_LOADING';
export const FETCH_FILES = 'FETCH_FILES';
export const MODAL_ACTION = 'MODAL_ACTION';
export const PROMPT_DIALOG_IS_DIRTY = 'PROMPT_DIALOG_IS_DIRTY';
export const LIGHTBOX_ACTION = 'LIGHTBOX_ACTION';
export const ZOOM_BUTTON_LIGHTBOX_ACTION = 'ZOOM_BUTTON_LIGHTBOX_ACTION';
export const DISABLE_FORM_BUTTON_ACTION = 'DISABLE_FORM_BUTTON_ACTION';
export const ADD_WRAPPER = 'ADD_WRAPPER';
export const FETCH_SETTINGS = 'FETCH_SETTINGS';
export const CHANGE_TABLE_PAGE = 'CHANGE_TABLE_PAGE';
export const SET_TABLE_COUNT = 'SET_TABLE_COUNT';
export const FETCH_ROLES = 'FETCH_ROLES';
export const DOCUMENT_PREVIEW = 'DOCUMENT_PREVIEW';
export const PROGRESS_ACTION = 'PROGRESS_ACTION';
export const SET_PROGRESS_ACTION = 'SET_PROGRESS_ACTION';
