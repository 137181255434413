import { CHANGE_TABLE_PAGE, SET_TABLE_COUNT } from '../actions/types';

export const changeTablePageAction = (page) => (dispatch) => {
	dispatch({
		type: CHANGE_TABLE_PAGE,
		payload: page,
	});
};

export const setTableCountAction = (count) => (dispatch) => {
	dispatch({
		type: SET_TABLE_COUNT,
		payload: count,
	});
};
