import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Hidden, AppBar, Tab, Tabs, Badge } from '@material-ui/core';

import { TabContainer, TabMenu } from './';

class TabBar extends Component {
	state = {
		value: this.props.tabValue ? this.props.tabValue : 0,
	};

	componentDidUpdate(prevProps) {
		if (this.props.tabActive !== this.state.value) {
			this.menuTabActive(this.props.tabActive);
		}
	}

	menuTabActive = (tabActive) => {
		this.handleChange(tabActive);
		this.handleChangeIndex(tabActive);
		this.a11yProps(tabActive);
	};

	handleChange = (event, newValue) => {
		this.setState({ value: newValue });
		this.props.changeActiveTab(newValue);
	};

	handleChangeIndex = (index) => {
		this.setState({ value: index });
		this.props.changeActiveTab(index);
	};

	a11yProps = (index) => {
		return {
			id: `full-width-tab-${index}`,
			'aria-controls': `full-width-tabpanel-${index}`,
		};
	};

	render() {
		const { theme } = this.props;

		return (
			<div>
				<AppBar position="static" color="default">
					<Hidden xsDown>
						<Tabs
							value={this.state.value}
							onChange={this.handleChange}
							indicatorColor="primary"
							textColor="primary"
							centered
							aria-label="full width tabs example"
						>
							{this.props.tabConfig.tab.tabs.map((tab, i) => {
								return (
									!tab.hidden && (
										<Tab
											id={i}
											key={i}
											ref={(ref) => this.props.setTabRef(ref, i)}
											label={
												tab.badge ? (
													<Badge
														variant={tab.badge.variant ? tab.badge.variant : 'standard'}
														badgeContent={tab.badge.content}
														invisible={tab.badge.invisible}
														color={tab.badge.color}
														classes={{ badge: tab.badge.customClass }}
													>
														{tab.label}
													</Badge>
												) : (
													tab.label
												)
											}
											{...this.a11yProps(i)}
										/>
									)
								);
							})}
							{this.props.tabConfig.menu && this.props.tabConfig.menu.active && (
								<TabMenu options={this.props.tabConfig.menu.actions} menuAction={(index) => this.props.menuAction(index)} />
							)}
						</Tabs>
					</Hidden>
					<Hidden smUp>
						<Tabs
							value={this.state.value}
							onChange={this.handleChange}
							indicatorColor="primary"
							textColor="primary"
							variant="fullWidth"
							aria-label="full width tabs example"
						>
							{this.props.tabConfig.tab.tabs.map((tab, i) => {
								return (
									!tab.hidden && (
										<Tab
											key={i}
											icon={
												tab.badge ? (
													<Badge
														variant={tab.badge.variant ? tab.badge.variant : 'standard'}
														badgeContent={tab.badge.content}
														invisible={tab.badge.invisible}
														color={tab.badge.color}
														classes={{ badge: tab.badge.customClass }}
													>
														{tab.icon}
													</Badge>
												) : (
													tab.icon
												)
											}
											ref={(ref) => this.props.setTabRef(ref, i)}
											{...this.a11yProps(i)}
										/>
									)
								);
							})}
							{this.props.tabConfig.menu && this.props.tabConfig.menu.active && (
								<TabMenu options={this.props.tabConfig.menu.actions} menuAction={(index) => this.props.menuAction(index)} />
							)}
						</Tabs>
					</Hidden>
				</AppBar>
				{this.props.tabConfig.tab.tabs.map((tab, i) => {
					return (
						<TabContainer key={i} value={this.state.value} index={i} dir={theme.direction}>
							{tab.component}
						</TabContainer>
					);
				})}
			</div>
		);
	}
}

export default withStyles(null, { withTheme: true })(TabBar);
