
import React from 'react';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';

import Loading from '../Loading';

function RenderInputAdornment({ input, meta: { invalid, touched, error, active }, ...custom }) {
    if (typeof custom.inputdata.onValidValue !== 'undefined' && !invalid && input.value !== '') {
		custom.inputdata.onValidValue();
	}

    return (
        <TextField
			{...input}
			id={custom.inputdata.id}
			name={custom.inputdata.name}
			label={custom.inputdata.label}
			placeholder={custom.inputdata.placeholder}
			fullWidth
			variant="outlined"
			disabled={custom.inputdata.readonly}
			error={custom.inputdata.validate ? touched && invalid : false}
			helperText={custom.inputdata.validate ? touched && error : false}
			onChange={(event) => {
				input.onChange(event);
				if (typeof custom.inputdata.onChange !== 'undefined') {
					custom.inputdata.onChange(event.target.value);
				}
			}}
			type={custom.inputdata.type}
			{...custom}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						{custom.inputdata.adornment.triggered ? (
							<Loading button={true} insideComponent={true} size={18} />
						) : (
								<IconButton
									color={invalid ? 'default' : 'primary'}
									disabled={!invalid || custom.inputdata.adornment.alwaysEnabled ? false : true}
									onClick={(e) => {
										custom.inputdata.adornment.action(e);
									}}
									aria-label="toggle password visibility"
									edge="end"
								>
									{custom.inputdata.adornment.icon ? custom.inputdata.adornment.icon : <SearchIcon />}
								</IconButton>
							)}
					</InputAdornment>
				),
			}}
		/>
    );
}

export default RenderInputAdornment;