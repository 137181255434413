import React from 'react';
import { Typography, Menu, MenuItem, Divider, FormControlLabel, Checkbox } from '@material-ui/core';

export function NavBarMenu({ translate, ...props }) {
	return (
		<Menu
			getContentAnchorEl={null}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
			id={props.menuId}
			anchorEl={props.anchorEl}
			open={props.isMenuOpen}
			onClose={props.handleMenuClose}
		>
			<MenuItem disabled={true}>
				<Typography>{props.username}</Typography>
			</MenuItem>
			<Divider />
			{/* {props.scopes.includes('practitioner') && (
				<MenuItem>
					<FormControlLabel
						control={
							<Checkbox
								onClick={props.activeAction.bind(this)}
								defaultChecked={props.active === '1' ? true : false}
								value="onduty"
								color="primary"
							/>
						}
						label={translate('navbar_checkbox_active')}
					/>
				</MenuItem>
			)} */}
			<MenuItem id="navbar_menu_logout" onClick={() => props.logout()}>
				<Typography>{translate('navbar_button_logout')}</Typography>
			</MenuItem>
		</Menu>
	);
}
