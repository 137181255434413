import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchSettingsAction } from './actions/settingsActions';
import Main from './Main';
import FullsizeLoading from './components/Generic/FullsizeLoading';

class Initializer extends Component {
	constructor(props) {
		super(props);

		this.props.fetchSettingsAction();
	}

	render() {
		// const Main = React.lazy(() => import('./Main'));
		return (
			<div>
				{typeof this.props.settings !== 'undefined' ? (
						<Main />
				) : (
					<FullsizeLoading logo={false} />
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	settings: state.settings.site,
});

export default connect(mapStateToProps, {
	fetchSettingsAction,
})(Initializer);
