import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { Container, Typography, Grid, Button } from '@material-ui/core';
import { handleAdblockWarning } from '../Login/actions/loginActions';

class AdblockWarning extends Component {
	state = {
		usingAdblock: false,
	};

	componentDidMount() {
		this.setState({ usingAdblock: this.fakeAdBanner.offsetHeight === 0 });
	}

	handleContinue = () => {
		this.props.handleAdblockWarning(false);
	};

	handleDisableAdblock = () => {
		window.location.reload();
	};
	render() {
		const { translate } = this.props;
		return (
			<Fragment>
				<div
					ref={(r) => (this.fakeAdBanner = r)}
					style={{ height: '0px', width: '1px', visiblity: 'none', pointerEvents: 'none' }}
					className="adBanner"
				/>
				<Container
					maxWidth={false}
					className={
						typeof this.fakeAdBanner !== 'undefined'
							? this.fakeAdBanner.offsetHeight === 0 && this.props.adblock_warning
								? 'adblockwarning'
								: 'adblockwarning-hidden'
							: 'adblockwarning'
					}
					style={{ display: 'none' }}
				>
					<Grid alignContent="center" alignItems="center" justify="space-between" container className={'adblockwarning-main'} spacing={1}>
						<Grid item xs={12} md={10}>
							<Typography variant="body2" component="div" color="textSecondary" align="center">
								{translate('adblockwarning_text')}
							</Typography>
						</Grid>
						<Grid item xs={12} md={2}>
							<Button variant="contained" color="primary" onClick={() => this.handleContinue()}>
								{translate('adblockwarning_button')}
							</Button>
						</Grid>
					</Grid>
				</Container>
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	adblock_warning: state.login.adblock_warning,
	translate: getTranslate(state.localize),
});

export default connect(mapStateToProps, { handleAdblockWarning })(AdblockWarning);
